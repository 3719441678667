import React, { useEffect, useState } from 'react';
import './../css/table.css';
import './../css/header.css';
import './../auth/auth.css';
import './../css/pagination.css';
import './../css/filter.css';
import { Link } from 'react-router-dom';
import { useDeleteOrderMutation } from '../../redux/order/orderApi';
import { useSelector } from 'react-redux';
import IndicatorSVG from '../custom/IndicatorSVG';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentStageFilter, setCurrentStageFilter] = useState('');
    const [showUrgentOnly, setShowUrgentOnly] = useState(false);
    const [whatsappFilter, setWhatsappFilter] = useState('');

    const ordersPerPage = 20;
    const [deleteOrder] = useDeleteOrderMutation();
    const { user } = useSelector((state) => state.auth);
    const hasManagerRole = user?.roles?.includes("ROLE_МЕНЕДЖЕР");

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch('https://api.ilim-ordo.kg/api/order?status=true');
                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }
                const data = await response.json();
                setOrders(data);
                setFilteredOrders(data); // Initially show all orders
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, []);

    useEffect(() => {
        let filtered = orders;

        if (startDate && endDate) {
            filtered = filtered.filter(order => {
                const orderDate = new Date(order.createdAt).toISOString().split("T")[0];
                return orderDate >= startDate && orderDate <= endDate;
            });
        }

        if (currentStageFilter) {
            filtered = filtered.filter(order => order.currentStage === currentStageFilter);
        }

        if (showUrgentOnly) {
            filtered = filtered.filter(order => order.info.priority === 'Срочный');
        }
        if (whatsappFilter) {
            filtered = filtered.filter(order => 
                order.info.whatsapp && order.info.whatsapp.startsWith(whatsappFilter)
            );
        }
        setFilteredOrders(filtered);
        setCurrentPage(1); // Reset pagination when filtering
    }, [startDate, endDate, currentStageFilter, showUrgentOnly, whatsappFilter, orders]);

    if (loading) return <IndicatorSVG style={{ width: 200, height: 128 }}/>;
    if (error) return <div className="auth-container">Ката: {error}</div>;

    const handleDelete = async (id) => {
        try {
            await deleteOrder(id).unwrap();
            setOrders(orders.filter(order => order._id !== id));
        } catch (error) {
            console.error('Failed to delete the order:', error);
        }
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
    
    const renderPageNumbers = () => {
        const pageLimit = 5; // Number of visible numbered buttons
        let pages = [];
    
        if (totalPages <= pageLimit) {
            pages = Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            if (currentPage <= 3) {
                pages = [1, 2, 3, 4, 5, 'dots-right', totalPages];
            } else if (currentPage >= totalPages - 2) {
                pages = [1, 'dots-left', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            } else {
                pages = [1, 'dots-left', currentPage - 1, currentPage, currentPage + 1, 'dots-right', totalPages];
            }
        }
    
        return pages.map((page) => 
            page === 'dots-left' || page === 'dots-right' ? (
                <span key={page} className="dots">...</span>
            ) : (
                <button
                    key={page}
                    className={currentPage === page ? 'activePage' : 'page'}
                    onClick={() => setCurrentPage(page)}
                >
                    {page}
                </button>
            )
        );
    };
    return (
        <div>
            <div className="header">
                <h2>Заказдар</h2>
                {hasManagerRole && (
                    <Link to={'/createOrder'} className="create-btn">Жаңы заказ</Link>
                )}
            </div>

            {/* Filter Inputs */}
            <div className="filters">
                <div className="filter-item">
                    <label>Баштоо күнү:</label>
                    <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>

                <div className="filter-item">
                    <label>Бүтүү күнү:</label>
                    <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>

                <div className="filter-item">
                    <label>Статус:</label>
                    <select value={currentStageFilter} onChange={(e) => setCurrentStageFilter(e.target.value)}>
                        <option value="">Бардыгы</option>
                        <option value="Дизайн">Дизайн</option>
                        <option value="Печать">Печать</option>
                        <option value="Постпечать">Постпечать</option>
                        <option value="Установка">Установка</option>
                        <option value="Финиш">Финиш</option>
                    </select>
                </div>

                <div className="filter-item">
                    <label>WhatsApp:</label>
                    <input 
                        type="text" 
                        placeholder="Телефон номер" 
                        value={whatsappFilter} 
                        onChange={(e) => setWhatsappFilter(e.target.value)} 
                    />
                </div>

                <div className="filter-item">
                    <label>
                        <input 
                            type="checkbox" 
                            checked={showUrgentOnly} 
                            onChange={(e) => setShowUrgentOnly(e.target.checked)} 
                        />
                        Срочный
                    </label>
                </div>

                <button onClick={() => {
                    setStartDate('');
                    setEndDate('');
                    setCurrentStageFilter('');
                    setShowUrgentOnly(false);
                    setWhatsappFilter('');
                    setFilteredOrders(orders); // Reset to full list
                    setCurrentPage(1); // Reset pagination
                }}>
                    Тазалоо
                </button>
            </div>


            {filteredOrders.length === 0 ? (
                <p>Заказдар табылган жок.</p>
            ) : (
                <>
                    <span>({filteredOrders.length}) - {currentPage} / {totalPages}</span>
                    <table className="auth-form">
                        <thead>
                            <tr>
                                <th>Номери</th>
                                <th></th>
                                <th>Клиент</th>
                                <th>Стадия</th>
                                <th>Датасы</th>
                                <th>Аракеттер</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentOrders.map((order) => (
                                <tr key={order._id}>
                                    <td>{order.orderNo}</td>
                                    <td style={{ color: order.info.priority === 'Срочный' ? 'red' : 'inherit',maxWidth:'300px' }}>
                                        Аталышы: {order.title}<br></br>
                                        Маалымат: {order.details}
                                    </td>
                                    <td>{order.customer}<br></br>
                                        <a
                                            href={`https://wa.me/${order.info.whatsapp}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="whatsapp-link"
                                        >
                                            {order.info.whatsapp}
                                        </a>
                                    </td>
                                    <td>{order.currentStage}</td>
                                    <td>{formatDate(order.createdAt)}</td>
                                    <td>
                                        <Link to={'/update/' + order._id} className="submit-button">Өзгөртүү</Link>
                                        <button onClick={() => handleDelete(order._id)}>Өчүрүү</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination */}
                    <div className="pagination">
                        <button className='page' onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
                            Биринчи
                        </button>
                        <button className='page' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Мурдагы
                        </button>
                        {renderPageNumbers()}
                        <button className='page' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Кийинки
                        </button>
                        <button className='page' onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                            Акыркы
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Orders;











// import React, { useEffect, useState } from 'react';
// import './../css/table.css';
// import './../css/header.css';
// import './../auth/auth.css';
// import './../css/pagination.css';
// import { Link } from 'react-router-dom';
// import { useDeleteOrderMutation } from '../../redux/order/orderApi';
// import { useSelector } from 'react-redux';
// import IndicatorSVG from '../custom/IndicatorSVG';

// const Orders = () => {
//     const [orders, setOrders] = useState([]);
//     const [filteredOrders, setFilteredOrders] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [currentPage, setCurrentPage] = useState(1);
//     const ordersPerPage = 20;
//     const [deleteOrder] = useDeleteOrderMutation();
//     const { user } = useSelector((state) => state.auth);
//     const hasManagerRole = user?.roles?.includes("ROLE_МЕНЕДЖЕР");
//     const [startDate, setStartDate] = useState("");
//     const [endDate, setEndDate] = useState("");

//     useEffect(() => {
//         const fetchOrders = async () => {
//             try {
//                 const response = await fetch('https://api.ilim-ordo.kg/api/order?status=true');
//                 if (!response.ok) {
//                     throw new Error('Failed to fetch orders');
//                 }
//                 const data = await response.json();
//                 setOrders(data);
//                 setFilteredOrders(data);
//             } catch (err) {
//                 setError(err.message);
//             } finally {
//                 setLoading(false);
//             }
//         };
//         fetchOrders();
//     }, []);

//     useEffect(() => {
//         if (startDate && endDate) {
//             const filtered = orders.filter(order => {
//                 const orderDate = new Date(order.createdAt);
//                 return orderDate >= new Date(startDate) && orderDate <= new Date(endDate);
//             });
//             setFilteredOrders(filtered);
//             setCurrentPage(1); // Reset to first page after filtering
//         } else {
//             setFilteredOrders(orders);
//         }
//     }, [startDate, endDate, orders]);

//     if (loading) return <IndicatorSVG style={{ width: 200, height: 128 }}/>;
//     if (error) return <div className="auth-container">Ката: {error}</div>;

//     const handleDelete = async (id) => {
//         try {
//             await deleteOrder(id).unwrap();
//             // setOrders(orders.filter(order => order._id !== id));
//             setFilteredOrders(filteredOrders.filter(order => order._id !== id));
//         } catch (error) {
//             console.error('Failed to delete the order:', error);
//         }
//     };

//     const indexOfLastOrder = currentPage * ordersPerPage;
//     const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
//     // const currentOrders = orders.slice(indexOfFirstOrder, indexOfLastOrder);
//     const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
//     const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
//     const renderPageNumbers = () => {
//         const pageLimit = 5; // Number of visible numbered buttons
//         let pages = [];
    
//         if (totalPages <= pageLimit) {
//             pages = Array.from({ length: totalPages }, (_, index) => index + 1);
//         } else {
//             if (currentPage <= 3) {
//                 pages = [1, 2, 3, 4, 5, 'dots-right', totalPages];
//             } else if (currentPage >= totalPages - 2) {
//                 pages = [1, 'dots-left', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
//             } else {
//                 pages = [1, 'dots-left', currentPage - 1, currentPage, currentPage + 1, 'dots-right', totalPages];
//             }
//         }
    
//         return pages.map((page) => 
//             page === 'dots-left' || page === 'dots-right' ? (
//                 <span key={page} className="dots">...</span>
//             ) : (
//                 <button
//                     key={page}
//                     className={currentPage === page ? 'activePage' : 'page'}
//                     onClick={() => setCurrentPage(page)}
//                 >
//                     {page}
//                 </button>
//             )
//         );
//     };
    
//     return (
//         <div>
//             <div className="header">
//                 <h2>Заказдар</h2>
//                 {hasManagerRole && (
//                     <Link to={'/createOrder'} className="create-btn">Жаңы заказ</Link>
//                 )}
//             </div>

//             <div className="date-filters">
//                 <label>Баштапкы күн:</label>
//                 <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
//                 <label>Акыркы күн:</label>
//                 <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
//             </div>

//             {orders.length === 0 ? (
//                 <p>Заказдар жүктөлө элек.</p>
//             ) : (
//                 <>
//                     <span> {currentPage} / {totalPages}</span>
//                     <table className="auth-form">
//                         <thead>
//                             <tr>
//                                 <th>Номери</th>
//                                 <th>Клиент</th>
//                                 <th>Стадия</th>
//                                 <th>Датасы</th>
//                                 <th>Аракеттер</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {currentOrders.map((order) => (
//                                 <tr key={order._id}>
//                                     <td>{order.orderNo}</td>
//                                     <td>{order.customer}</td>
//                                     <td>{order.currentStage}</td>
//                                     <td>{new Date(order.createdAt).toLocaleDateString()}</td>
//                                     <td>
//                                         <Link to={'/update/' + order._id} className="submit-button">Өзгөртүү</Link>
//                                         <button onClick={() => handleDelete(order._id)}>Өчүрүү</button>
//                                     </td>
//                                 </tr>
//                             ))}
//                         </tbody>
//                     </table>
//                     <div className="pagination">
//                     <button className='page' onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
//                         Биринчи
//                     </button>
//                         <button className='page' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
//                             Мурдагы
//                         </button>
//                         {renderPageNumbers()}
//                         <button className='page' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
//                             Кийинки
//                         </button>
//                         <button className='page' onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
//                             Акыркы
//                         </button>
//                     </div>
//                 </>
//             )}
//         </div>
//     );
// };

// export default Orders;
