// App.jsx
import { Routes, Route } from 'react-router-dom';

import StagedOrderList from './components/StagedOrderList';
import OrderList from './components/OrderList';
import Create from './components/Create';
import OrderDetails from './components/OrderDetails';
import MaterialList from './components/MaterialList';
import CustomerList from './components/CustomerList';
import CreateOrder from './components/CreateOrder';

import Designer from './components/users/Designer';
import Printer from './components/users/Printer';
import Manager from './components/users/Manager';
import Customer from './components/manager';
function App() {
//   const { user } = useSelector((state) => state.auth); // Get user from Redux state
  return (
    <div className="app-container">
      <Routes>
        {/* Protected Routes */}
        {/* <Route path="/projects/:projectId/tasks" element={<ProtectedRoute element={<TaskList />} />} /> */}
        
        {/* <Route path="/domains/:domainId/projects" element={<ProtectedRoute element={<ProjectList />} />} />
        <Route path="/domains" element={<ProtectedRoute element={<DomainList />} />} /> */}
        {/* Public Routes */}
        <Route path="/" element={<StagedOrderList />} />
        <Route path="/designer" element={<Designer />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/printer" element={<Printer />} />
        <Route path="/manager" element={<Manager />} />

        <Route path="/customers" element={<CustomerList />} />
        <Route path="/materials" element={<MaterialList />} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="/create" element={<Create />}/>
        <Route path="/createOrder" element={<CreateOrder />}/>
        <Route path="/order/:_id" element={<OrderDetails />}/>
        {/* <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/logout" element={<Logout />} /> */}
      </Routes>
        {/* {user && (
          <>
            <h2>Welcome, {user.username}</h2>
            <Logout />
          </>
        )} */}
    </div>
  );
}

export default App;