import React from 'react';
import './order.css';

function Manager() {
  return (
    <div className="order-summary">
      <div className="order-header">
        <div>Заказчик</div>
        <div>телефон клиента</div>
        <div>тип материала</div>
      </div>
      <div className="order-details">
        <div className="size-table">
          <div>размеры</div>
          <div>m2</div>
          <div>описания заказа</div>
        </div>
        <div className="order-info">
          <div>Ставка</div>
          <div>Способ оплаты: 1. наличными 2 если перечисление</div>
          <div>Сумма</div>
          <div className="highlighted-text">Дизайн</div>
        </div>
      </div>
      <div className="total">
        <span>Итого к оплате:</span> <span className="total-amount">586</span>
      </div>
      <div className="status-green">Кабыл алыншты</div>
    </div>
  );
}

export default Manager;
