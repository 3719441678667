import React, { useState, useEffect } from 'react';

const CreateOrderForm = () => {
  const [orderNumber, setOrderNumber] = useState('');
  const [orderDetails, setOrderDetails] = useState('');
  const [currentStage, setCurrentStage] = useState('Дизайн');
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [createdBy, setCreatedBy] = useState('');
  const [users, setUsers] = useState([]); // State to hold fetched users
  const [message, setMessage] = useState('');


  const [assignedMaterials, setAssignedMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  // const [payments, setPayments] = useState([]);
    // Payment input fields
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('cash');
  const [whatsapp, setWhatsapp] = useState([]);
  const [paymentForDesign, setPaymentForDesign] = useState([]);
  const [leuvers, setLeuvers] = useState([]);
  const [frames, setFrames] = useState([]);
  const [price, setPrice] = useState([]);


  // Fetch all users from /user/get when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.ilim-ordo.kg/api/user');
        if (response.ok) {
          const usersData = await response.json();
          setUsers(usersData);
        } else {
          console.error('Failed to fetch users');
        }
      } catch (err) {
        console.error('Error fetching users:', err);
      }
    };

    fetchUsers();
    const fetchMaterials = async () => {
      try {
        const response = await fetch('https://api.ilim-ordo.kg/api/materials');
        if (response.ok) {
          const materialsData = await response.json();
          setMaterials(materialsData);
        } else {
          console.error('Failed to fetch materials');
        }
      } catch (err) {
        console.error('Error fetching materials:', err);
      }
    };

    fetchMaterials();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const now = new Date().toISOString(); // Current time in ISO format
    const completedAt = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(); // 3 days from now

    const orderData = {
      orderNumber,
      orderDetails,
      currentStage,
      stages: [
        {
          stageName: currentStage,
          assignedUsers: assignedUsers, // Array of user IDs
          startedAt: now,
          completedAt: completedAt,
        }
      ],
      info:{
        materials: assignedMaterials,
        payments: { amount: paymentAmount, method: paymentMethod },
        whatsapp: whatsapp,
        paymentForDesign: paymentForDesign,
        leuvers: leuvers,
        frames: frames,
        price: price
      },
      createdBy,
    };

    console.log('orderData: ',orderData);
    try {
      const response = await fetch('https://api.ilim-ordo.kg/api/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData),
      });

      if (response.ok) {
        setMessage('Order created successfully!');
      } else {
        const errorData = await response.json();
        setMessage(`Error: ${errorData.error || 'Something went wrong'}`);
      }
    } catch (err) {
      setMessage(`Error: ${err.message}`);
    }
  };

  // Handle selection of multiple users
  const handleUserSelection = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedUserIds = selectedOptions.map(option => option.value);
    setAssignedUsers(selectedUserIds); // Update the state with selected user IDs
  };

    // Handle selection of multiple materials
    const handleMaterialSelection = (e) => {
      const selectedOptions = Array.from(e.target.selectedOptions);
      const selectedMaterialIds = selectedOptions.map(option => option.value);
      setAssignedMaterials(selectedMaterialIds); // Update the state with selected material IDs
    };

  return (
    <div>
      <h1>Create Order</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Order Number:</label>
          <input
            type="text"
            value={orderNumber}
            onChange={(e) => setOrderNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Order Details:</label>
          <input
            type="text"
            value={orderDetails}
            onChange={(e) => setOrderDetails(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Current Stage:</label>
          <select
            value={currentStage}
            onChange={(e) => setCurrentStage(e.target.value)}
            required
          >
            <option value="Дизайн">Дизайн</option>
            <option value="Печать">Печать</option>
            <option value="Подготовка">Подготовка</option>
            <option value="Установка">Установка</option>
          </select>
        </div>

        {/* Multi-User Selection */}
        <div>
          <label>Assigned Users:</label>
          <select
            multiple
            value={assignedUsers}
            onChange={handleUserSelection}
            required
          >
            {users.map((user) => (
              <option key={user._id} value={user._id}>
                {user.username}
              </option>
            ))}
          </select>
        </div>

                {/* Multi-Material Selection */}
        <div>
          <label>Материалдар:</label>
          <select
            className='select-height-200'
            multiple
            value={assignedMaterials}
            onChange={handleMaterialSelection}
            required
          >
            {materials.map((material) => (
              <option key={material._id} value={material._id}>
                {material.title}
              </option>
            ))}
          </select>
        </div>

        {/* Payments Input */}
        <div>
          <label>Payments:</label>
          <div>
            <input
              type="number"
              placeholder="Payment Amount"
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              required
            />
            <select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              required
            >
              <option value="cash">Cash</option>
              <option value="emoney">E-money</option>
            </select>
            {/* <button type="button" onClick={handleAddPayment}>Add Payment</button> */}
          </div>
          {/* <ul>
            {payments.map((payment, index) => (
              <li key={index}>
                Amount: {payment.amount}, Method: {payment.method}
                <button type="button" onClick={() => handleRemovePayment(index)}>Remove</button>
              </li>
            ))}
          </ul> */}
        </div>

        <div>
          <label>Whatsapp:</label>
          <input
            type="number"
            value={whatsapp}
            onChange={(e) => setWhatsapp(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Payment for Design:</label>
          <input
            type="number"
            value={paymentForDesign}
            onChange={(e) => setPaymentForDesign(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Leuvers:</label>
          <input
            type="number"
            value={leuvers}
            onChange={(e) => setLeuvers(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Frames:</label>
          <input
            type="number"
            value={frames}
            onChange={(e) => setFrames(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Price:</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Created By (User ID):</label>
          <input
            type="text"
            value={createdBy}
            onChange={(e) => setCreatedBy(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>

      {message && <p>{message}</p>}
    </div>
  );
};

export default CreateOrderForm;
