// src/redux/paper/paperApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const paperApi = createApi({
  reducerPath: 'paperApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.ilim-ordo.kg/' }), // Set your API base URL here
  endpoints: (builder) => ({
    getAllPapers: builder.query({
      query: () => 'api/papers',
    }),
    getPaperById: builder.query({
      query: (id) => `api/papers/${id}`,
    }),
    createPaper: builder.mutation({
      query: (title) => ({
        url: 'api/papers',
        method: 'POST',
        body: { title },
      }),
    }),
    updatePaper: builder.mutation({
      query: ({ id, title }) => ({
        url: `api/papers/${id}`,
        method: 'PUT',
        body: { title },
      }),
    }),
    deletePaper: builder.mutation({
      query: (id) => ({
        url: `api/papers/${id}`,
        method: 'DELETE',
      }),
    }),
    deletePaperFromOrder: builder.mutation({
      query: ({ orderId, paperId }) => ({
        url: `api/orders/${orderId}/papers/${paperId}`,
        method: 'DELETE',
      }),
    }),
    addPaperToOrder: builder.mutation({
      query: ({ orderId, paper }) => ({
        url: `api/orders/${orderId}/papers`,
        method: 'POST',
        body: paper,
      }),
    }),
  }),
});

export const {
  useGetAllPapersQuery,
  useGetPaperByIdQuery,
  useCreatePaperMutation,
  useUpdatePaperMutation,
  useDeletePaperMutation,   
  useDeletePaperFromOrderMutation,
  useAddPaperToOrderMutation,
} = paperApi;
