// src/components/list/PaperList.js
import React, { useState } from 'react';
import {
  useGetAllPapersQuery,
  useCreatePaperMutation,
  useUpdatePaperMutation,
  useDeletePaperMutation,
} from '../../redux/list/paper/paperApi';

const PaperList = () => {
  const { data: papers = [], refetch } = useGetAllPapersQuery();
  const [createPaper] = useCreatePaperMutation();
  const [updatePaper] = useUpdatePaperMutation();
  const [deletePaper] = useDeletePaperMutation();

  const [title, setTitle] = useState('');
  const [editId, setEditId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editId) {
      await updatePaper({ id: editId, title });
      setEditId(null);
    } else {
      await createPaper(title);
    }
    setTitle('');
    refetch(); // Refresh the list after any change
  };

  const handleEdit = (paper) => {
    setTitle(paper.title);
    setEditId(paper._id);
  };

  const handleDelete = async (id) => {
    await deletePaper(id);
    refetch(); // Refresh the list after deletion
  };

  return (
    <div className="auth-container">
      <h2>Кагаздар</h2>
      <ul>
        {papers.map((paper) => (
          <li key={paper._id} className="form-group">
          <div className="button-group">
            <span>{paper.title}</span>
            <button onClick={() => handleEdit(paper)}>Өзгөртүү</button>
            <button onClick={() => handleDelete(paper._id)}>Өчүрүү</button>
            </div>
          </li>
        ))}
      </ul>

      <h2>{editId ? 'Кагазды өзгөрт' : 'Кагазды кош'}</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="form-group">
        <label>Кагаз</label>
          <input
            className="form-input"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Кагаз"
            required
          />
        </div>
        <button type="submit" className="submit-button">{editId ? 'Өзгөртүү' : 'Кошуу'}</button>
      </form>
    </div>
  );
};

export default PaperList;
