// Update.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { 
    useGetOrderByIdQuery, 
    useAddMaterialToOrderMutation,
    useAddPaymentToOrderMutation, 
    useDeleteStageFromOrderMutation,
    useDeletePaymentFromOrderMutation,
    useDeleteMaterialFromOrderMutation,
    useUpdateOrderMutation
  } from '../../redux/order/orderApi';
  import { useGetAllMaterialsQuery } from '../../redux/list/material/materialApi';
  import { useGetAllPapersQuery, useDeletePaperFromOrderMutation, useAddPaperToOrderMutation } from '../../redux/list/paper/paperApi';
  import { useGetAllCustomersQuery } from '../../redux/list/customer/customerApi';
  import '../css/OrderList.css';
  import { useOrderHelpers } from '../../hooks/useOrderHelpers';

const Update = () => {
    const { _id } = useParams(); 
    const { data: order, error, isLoading } = useGetOrderByIdQuery(_id);
    const { data: materials = [] } = useGetAllMaterialsQuery();
    const { data: papers = [] } = useGetAllPapersQuery();
    const { data: customers = [] } = useGetAllCustomersQuery();
    const [newPayment, setNewPayment] = useState({ amount: '', method: 'акчалай' });
    const [addMaterialToOrder] = useAddMaterialToOrderMutation();
    const [addPaymentToOrder] = useAddPaymentToOrderMutation(); 
    const [deletePaymentFromOrder] = useDeletePaymentFromOrderMutation();
    const [deleteMaterialFromOrder] = useDeleteMaterialFromOrderMutation();
    const [deletePaperFromOrder] = useDeletePaperFromOrderMutation();
    const [addPaperToOrder] = useAddPaperToOrderMutation();
    const [deleteStageFromOrder] = useDeleteStageFromOrderMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedPaper, setSelectedPaper] = useState('');
    const [paperQuantity,setPaperQuantity] = useState("");
    const [paperPrice,setPaperPrice] = useState("");

    const [isLoadinggg, setIsLoadinggg] = useState(false);
    const [whatsapp, setWhatsapp] = useState(0);
    const [paymentForDesign,setPaymentForDesign] = useState(0);
    const [leuvers,setLeuvers] = useState(0);
    const [frames,setFrames] = useState(0);
    const [price,setPrice] = useState(0);

    const [selectedPapers, setSelectedPapers] = useState([]);
    const [selectedPaperPrices, setSelectedPaperPrices] = useState({});
    const [selectedPaperQuantities, setSelectedPaperQuantities] = useState({});

    const [paymentForInstallation, setPaymentForInstallation] = useState(0);
    const [paymentForPrinter, setPaymentForPrinter] = useState(0);
    const [orderDetails,setOrderDetails] = useState('');
    const [who,setWho] = useState('');
    const [priority, setPriority] = useState('');
    const [office, setOffice] = useState(true);
    const [changeCurrentStage, setChangeCurrentStage] = useState(order?.currentStage || '');
    
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [size,setSize] = useState(0);
    const [rate, setRate] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [customer, setCustomer] = useState('');
    const [users, setUsers] = useState([]);
    const [currentStage,setCurrentStage] = useState('');
    const { handleUpdateOrderCurrentStageProperty } = useOrderHelpers();
    const allStages = ['Дизайн', 'Печать', 'Постпечать', 'Установка', 'Финиш'];

    const calculatePrice = () => {
      let calculatedPrice = 0;
      if(order?.office){
          const sumOfTotals = selectedPapers.reduce((acc, paper) => {
            const quantity = Number(selectedPaperQuantities[paper.id] || 0);
            const price = Number(selectedPaperPrices[paper.id] || 0);
            return acc + (quantity * price);
          }, 0);
          calculatedPrice = sumOfTotals + Number(paymentForDesign || 0);
      }else{
          calculatedPrice =
          parseFloat(paymentForDesign || 0) +
          (parseFloat((((height * width) * 0.0001).toFixed(3) * rate).toFixed(3)) +  
          parseFloat(frames || 0) +
          parseFloat(leuvers || 0) +
          parseFloat(paymentForInstallation || 0)) *
          parseFloat(quantity || 1);
      }
      setPrice(calculatedPrice);
      console.log('calculatedPrice: ' + calculatedPrice + ' paymentForDesign: ' + paymentForDesign + ' size * rate: ' + (size * rate) + ' frames: ' + frames + ' leuvers: ' + leuvers + ' paymentForInstallation: ' + paymentForInstallation + ' quantity: ' + quantity);
      };

    useEffect(() => {
      calculatePrice();
    }, [paymentForDesign, size, height, width, rate, frames, leuvers, paymentForInstallation,quantity]); 
    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await fetch('https://api.ilim-ordo.kg/api/users');
          if (response.ok) {
            const usersData = await response.json();
            setUsers(usersData);
          } else {
            console.error('Failed to fetch users');
          }
        } catch (err) {
          console.error('Error fetching users:', err);
        }
      };
  
      fetchUsers();
      if(order){
        setPriority(order.info.priority);
        setHeight(order.info.dimensions.height);
        setWidth(order.info.dimensions.width);
        setSize(order.info.dimensions.size);
        setPaymentForDesign(order.info.paymentForDesign);
        setRate(order.info.rate);
        setFrames(order.info.frames);
        setLeuvers(order.info.leuvers);
        setPaymentForInstallation(order.info.paymentForInstallation);
        setQuantity(order.info.quantity);
        setPaymentForPrinter(order.info.paymentForPrinter);
        setChangeCurrentStage(order.currentStage);
        setPrice(order.info.price);
        setOffice(order.office);
        setWho(order.who);

                  //  // Load values from order
                  const initialSelectedPapers = order.info.papers.map(paper => ({
                    id: paper.paper._id,
                    title: paper.paper.title,
                }));
                setSelectedPapers(initialSelectedPapers);
    
                const initialSelectedPaperPrices = {};
                const initialSelectedPaperQuantities = {};
    
                order.info.papers.forEach(paper => {
                    initialSelectedPaperPrices[paper.paper._id] = paper.price;
                    initialSelectedPaperQuantities[paper.paper._id] = paper.quantity;
                });
    
               setSelectedPaperPrices(initialSelectedPaperPrices);
                setSelectedPaperQuantities(initialSelectedPaperQuantities);
      }
    }, [order]);
    useEffect(() => {
      if (order?.currentStage) {
          const roles = {
              "Старт": "Дизайн",
              "Дизайн": "Печать",
              "Печать": "Постпечать",
              "Постпечать": "Установка",
              "Установка": "Финиш",
              "Финиш": "Старт"
          };
          console.log('order?.currentStage order.currentStage: ', order.currentStage);
          console.log('order?.currentStage roles[order.currentStage]: ', roles[order.currentStage]);
          setCurrentStage(roles[order.currentStage]  || '');
          // setOrderCurrentStage(order?.currentStage);
      }
  }, [order?.currentStage]); 
    const [newStage, setNewStage] = useState({
      stageName: '',
      assignedUsers: [],
      startedAt: '',
      // completedAt: '',
    });
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;
    // Filter out existing stage names from `order.stages`
    const availableStages = allStages.filter(stageName =>
      !order.stages.some(stage => stage.stageName === stageName)
    );
    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setNewPayment({ ...newPayment, [name]: value });
    };
    const handleAddPayment = async (orderId) => {
      setIsLoadinggg(true);
      try {
          await addPaymentToOrder({ orderId, ...newPayment });
          setNewPayment({ amount: '', method: 'cash' }); 
          window.location.reload();
      } catch (error) {
          console.error("Error adding payment:", error);
      }
    };

    const handlePaymentDelete = async (orderId, paymentId) => {
      setIsLoadinggg(true);
        try {
            await deletePaymentFromOrder({ orderId, paymentId });
            window.location.reload();
        } catch (error) {
            console.error("Error deleting payment:", error);
        }
    };

    const handleMaterialChange = (e) => {
      setSelectedMaterial(e.target.value);
    };

    const handleAddMaterial = async (orderId) => {
        setIsLoadinggg(true);
        if (selectedMaterial) {
            await addMaterialToOrder({ orderId, materialId: selectedMaterial });
            setSelectedMaterial(''); // Reset the selection
            window.location.reload();
        }
    };

    const handleMaterialDelete = async (orderId, materialId) => {
      setIsLoadinggg(true);
      try {
          await deleteMaterialFromOrder({ orderId, materialId });
          window.location.reload();
      } catch (error) {
          console.error("Error deleting Material:", error);
      }
    };

    const handleAddCustomer =  (orderId) => {
        setIsLoadinggg(true);
        if (selectedCustomer) {
            handleUpdateOrderProperty(orderId,'customer',selectedCustomer)
            setSelectedCustomer(''); // Reset the selection
        }else{
          handleUpdateOrderProperty(orderId,'customer',customer)
          setCustomer('');
        }
    };

    // Handler to update a single property
    const handleUpdateOrderProperty = async (orderId, property, value) => {
      console.log('property: '+ property + ' value: ' + value)
      setIsLoadinggg(true);
      try {
        await updateOrder({ id: orderId, [property]: value });
      } catch (error) {
        console.error("Error deleting Material:", error);
      }
      window.location.reload();
    };
    const handleUpdateOrderChunkProperty = async (orderId, updatedProperties) => {
      console.log('Updating properties:', updatedProperties);
      setIsLoadinggg(true);
  
      try {
          await updateOrder({ id: orderId, ...updatedProperties });
      } catch (error) {
          console.error("Error updating order:", error);
      }
      window.location.reload();
    };
    const handleStageChange = (e) => {
      const { name, value } = e.target;
      setNewStage({ ...newStage, [name]: value });
    };
  
    const handleAssignedUsersChange = (e) => {
      const selectedOptions = Array.from(e.target.selectedOptions);
      const selectedUserIds = selectedOptions.map((option) => option.value);
      setNewStage({ ...newStage, assignedUsers: selectedUserIds });
    };
  
    const handleAddStage = async (orderId) => {
      setIsLoadinggg(true);
      console.log('newStage: ',newStage)
      try {
        await updateOrder({
          id: orderId,
          stages: [{ ...newStage }],
        });
        window.location.reload();
      } catch (error) {
        console.error('Error adding stage:', error);
      } finally {
        setIsLoadinggg(false);
      }
    };

  const handleStageDelete = async (orderId, stageId) => {
    setIsLoadinggg(true);
      try {
          await deleteStageFromOrder({ orderId, stageId });
          window.location.reload();
      } catch (error) {
          console.error("Error deleting stage:", error);
      }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleCompleted = async (order,userId)=>{
    // e.preventDefault();
    try{
        // console.log('user: ',user,'temp currentStage: ',currentStage,'order.currentStage: ',order.currentStage)
        await Promise.all([
            handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, userId, order),
            handleUpdateOrderProperty(order._id, 'currentStage', currentStage)
        ]);
    } catch (error) {
        console.error('Error completing stage:', error);
    }
  };
  const handlePaperChange = (e) => {
    setSelectedPaper(e.target.value);
  };

  // const handleAddPaper = async (orderId) => {
  //     setIsLoadinggg(true);
  //     if (selectedPaper) {
  //         await addPaperToOrder({ orderId, paperId: selectedPaper });
  //         setSelectedPaper(''); // Reset the selection
  //         window.location.reload();
  //     }
  // };
  const handleAddPaper = async (orderId) => {
    if (!selectedPaper || !paperQuantity || !paperPrice) {
      alert("Бардык талааларды толтуруңуз!");
      return;
    }
    try {
      await addPaperToOrder({
        orderId,
        paper: { paper: {
          paper: { _id : selectedPaper }, 
          quantity: Number(paperQuantity),
          price: Number(paperPrice),
          total: Number(paperQuantity) * Number(paperPrice),
        }},
      });
  
      console.log("Кагаз ийгиликтүү кошулду!");
      setSelectedPaper("");
      setPaperQuantity("");
      setPaperPrice("");
      window.location.reload();
    } catch (err) {
      console.error("Кагаз кошуу ишке ашкан жок:", err);
    }
  };
  

  const handlePaperDelete = async (orderId, paperId) => {
    setIsLoadinggg(true);
    console.log('orderId: ',orderId,' paperId: ',paperId)
    try {
        await deletePaperFromOrder({ orderId, paperId });
        window.location.reload();
    } catch (error) {
        console.error("Error deleting Paper:", error);
    }
  };
    return (
        <div>
              <div className="order-header">
                <strong>#</strong> {order.orderNo}
                <strong>Азыркы этап:</strong> {order.currentStage}
                <strong >Кабыл алган:</strong> {order.createdBy.username}
                <strong>Датасы:</strong> {formatDate(order.createdAt)}
                <strong>Баасы:</strong> {order.info.price} сом
                <strong>Төлөндү:</strong> {order.info.payments.reduce((sum, payment) => sum + payment.amount, 0)} сом
                <strong>Карызы:</strong> {order.info.price - (order.info.payments.reduce((sum, payment) => sum + payment.amount, 0))}  сом
              </div>
              <div className="order-details">
                <div className="section-container">
                  <h2>Клиент</h2> 
                  <div className="order-info">{order.customer}</div>
                  {/* Dropdown to Add Customer */}
                  <div className="vertical-end">
                      <h4>Клиент кошуу</h4>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <select 
                          value={selectedCustomer} 
                          onChange={(e)=>setSelectedCustomer(e.target.value)}                          
                        >
                            <option value="">Клиент тандаңыз</option>
                            {customers.map(customer => (
                                <option key={customer._id} value={customer.title}>
                                    {customer.title}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            name="customer"
                            placeholder="Заказчик"
                            value={customer}
                            onChange={(e)=>setCustomer(e.target.value)}
                            required                          
                        />
                        <button onClick={() => handleAddCustomer(order._id)} disabled={isLoadinggg}>Кош</button>
                      </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Төлөмдөр</h2> 
                  <ul className="order-info">
                      {order.info.payments.map(payment => (
                          <li key={payment._id} className="list-item">
                              <span>{`${payment.amount} сом`}</span>
                              <span>{`(${payment.method})`}</span>
                              <button                                    
                                  onClick={() => handlePaymentDelete(order._id, payment._id)}
                                  disabled={isLoadinggg}
                              >
                                  Өчүрүү
                              </button>
                          </li>
                      ))}
                  </ul>
                  {/* Form to Add New Payment */}
                  <div className="vertical-end">
                      <h4>Төлөм кошуу</h4>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <input
                            type="number"
                            name="amount"
                            placeholder="Төлөм суммасы"
                            value={newPayment.amount}
                            onChange={handlePaymentChange}
                            required                          
                        />
                        <select
                            name="method"
                            value={newPayment.method}
                            onChange={handlePaymentChange}
                            required                          
                        >
                            <option value="акчалай">акчалай</option>
                            <option value="мбанк">мбанк</option>
                            <option value="перечис.">перечис.</option>
                        </select>
                        <button onClick={() => handleAddPayment(order._id)} disabled={isLoadinggg}>Кош</button>
                      </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Whatsapp</h2> 
                  <div className="order-info">{order.info.whatsapp}</div>
                  <div className="vertical-end">
                      <h4>Номерди өзгөртүү</h4>
                      <input
                          type="number"
                          name="whatsapp"
                          placeholder="Whatsapp"
                          value={whatsapp}
                          onChange={(e)=>setWhatsapp(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.whatsapp',whatsapp)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Ким заказ берди</h2> 
                  <div className="order-info">{order.who}</div>
                  <div className="vertical-end">
                      <h4>Заказ бергендин атын өзгөртүү</h4>
                      <input
                          type="text"
                          name="who"
                          placeholder="Заказ берген адам"
                          value={who}
                          onChange={(e)=>setWho(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'who',who)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>



                {order.office ? (
                  <div className="section-container">
                    <h2>Кагаздар</h2> 
                    <ul>
                        {order.info.papers.map(paper => (
                            <li key={paper.paper._id} className="paper-price-list">
                                <span className='paper-price-title'>{paper.paper.title}</span>
                                <div className='paper-quantity-price-list'>
                                  <span>{paper.quantity}</span> 
                                  x
                                  <span> {paper.price} </span>
                                  сом   =
                                  <span>{(paper.quantity * paper.price)} </span>сом
                                </div>
                                <button 
                                    className="list-item"
                                    onClick={() => handlePaperDelete(order._id, paper.paper._id)}
                                    disabled={isLoadinggg}
                                >
                                    Өчүрүү
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="vertical-end">
                        <h4>Кагаз кошуу</h4>
                        <select 
                          value={selectedPaper} 
                          onChange={handlePaperChange}  
                          className="select-margin"
                        >
                            <option value="">Кагаз тандаңыз</option>
                            {papers.map(paper => (
                                <option key={paper._id} value={paper._id}>
                                    {paper.title}
                                </option>
                            ))}
                        </select>
                        <input 
                          type="number" 
                          placeholder="Саны" 
                          value={paperQuantity} 
                          onChange={(e) => setPaperQuantity(e.target.value)} 
                          min="1"
                          className="paper-inputs paper-inputs-first"
                        />
                        
                        <input 
                          type="number" 
                          placeholder="Баасы" 
                          value={paperPrice} 
                          onChange={(e) => setPaperPrice(e.target.value)} 
                          min="0"
                          className="paper-inputs"
                        />
                        <span className="paper-inputs-total-span"> = {paperQuantity * paperPrice} сом</span>
                        <button onClick={() => handleAddPaper(order._id)} disabled={isLoadinggg}>Кош</button>
                    </div>
                  </div>
                ) : (
                  <div className="section-container">
                    <h2>Материалдар</h2> 
                    <ul>
                        {order.info.materials.map(material => (
                            <li key={material._id} className="list-item">
                                <span>{material.title}</span>
                                <button 
                                    className="list-item"
                                    onClick={() => handleMaterialDelete(order._id, material._id)}
                                    disabled={isLoadinggg}
                                >
                                    Өчүрүү
                                </button>
                            </li>
                        ))}
                    </ul>
                    {/* Dropdown to Add Material */}
                    <div className="vertical-end">
                        <h4>Материал кошуу</h4>
                        <select 
                          value={selectedMaterial} 
                          onChange={handleMaterialChange}  
                          className="select-margin"
                        >
                            <option value="">Материал тандаңыз</option>
                            {materials.map(material => (
                                <option key={material._id} value={material._id}>
                                    {material.title}
                                </option>
                            ))}
                        </select>
                        <button onClick={() => handleAddMaterial(order._id)} disabled={isLoadinggg}>Кош</button>
                    </div>
                  </div>
                )}



                {order.office ? '' : (
                  <>
                    <div className="section-container">
                      <h2>Люверси</h2> 
                      <div className="order-info">{order.info.leuvers} сом</div>
                      <div className="vertical-end">
                          <h4>Люверсинин баасын өзгөртүү</h4>
                          <input
                              type="number"
                              name="leuvers"
                              placeholder="leuvers"
                              value={leuvers}
                              onChange={(e)=>setLeuvers(e.target.value)}
                              required                          
                          />
                          <button onClick={() => handleUpdateOrderChunkProperty(order._id,
                              { 
                                'info.leuvers': leuvers, 
                                'info.price': price
                              }
                          )} 
                            disabled={isLoadinggg}>Өзгөрт</button>
                      </div>
                    </div>
                    <div className="section-container">
                      <h2>Рейка</h2> 
                      <div className="order-info">{order.info.frames} сом</div>
                      <div className="vertical-end">
                          <h4>Рейканын баасын өзгөртүү</h4>
                          <input
                              type="number"
                              name="frames"
                              placeholder="frames"
                              value={frames}
                              onChange={(e)=>setFrames(e.target.value)}
                              required                          
                          />
                          <button onClick={() => handleUpdateOrderChunkProperty(order._id,
                          { 
                            'info.frames': frames, 
                            'info.price': price
                          })} 
                          disabled={isLoadinggg}>Өзгөрт</button>
                      </div>
                    </div>
                    <div className="section-container">
                      <h2>Установщиктин акысы</h2> 
                      <div className="order-info">{order.info.paymentForInstallation} сом</div>
                      <div className="vertical-end">
                          <h4>Установщиктин акысын өзгөртүү</h4> 
                          <input
                              type="number"
                              name="paymentForInstallation"
                              placeholder="paymentForInstallation"
                              value={paymentForInstallation}
                              onChange={(e)=>setPaymentForInstallation(e.target.value)}
                              required                          
                          />
                          <button onClick={() => handleUpdateOrderChunkProperty(order._id,
                              { 
                                'info.paymentForInstallation': paymentForInstallation, 
                                'info.price': price
                              })} disabled={isLoadinggg}>Өзгөрт</button>
                      </div>
                    </div>
                  </>
                )}

                <div className="section-container">
                  <h2>Дизайнерге</h2> 
                  <div className="order-info">{order.info.paymentForDesign} сом</div>
                  <div className="vertical-end">
                      <h4>Дизайнердин акысын өзгөртүү</h4>
                      <input
                          type="number"
                          name="paymentForDesign"
                          placeholder="Дизайнердин акысы"
                          value={paymentForDesign}
                          onChange={(e)=>setPaymentForDesign(e.target.value)}
                          required                          
                      />
                      <button 
                        onClick={() => handleUpdateOrderChunkProperty(order._id, 
                          { 
                            'info.paymentForDesign': paymentForDesign, 
                            'info.price': price,
                            // 'info.dimensions.size': ((height * width) * 0.0001).toFixed(3)
                           })}
                        disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>

                <div className="section-container">
                  <h2>Маалымат</h2> 
                  <div className="order-info">{order.orderDetails}</div>
                  <div className="vertical-end">
                      <h4>Заказ тууралуу маалыматты өзгөртүү</h4>
                      <input
                          type="text"
                          name="orderDetails"
                          placeholder="Заказ тууралуу маалымат"
                          value={orderDetails}
                          onChange={(e)=>setOrderDetails(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'orderDetails',orderDetails)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Приоритет</h2>
                  <div className="order-info">{order.info.priority}</div>
                  <div className="vertical-end">
                    <h4>Приоритетти өзгөртүү</h4>
                    <select
                      name="priority"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      required
                    >
                      <option value="Обычный">Обычный</option>
                      <option value="Срочный">Срочный</option>
                    </select>
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.priority', priority)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                {order.office ? '' : (
                  <>
                    <div className="section-container">
                      <h2>Өлчөмү</h2>
                      <div className="order-info">
                        {/* Бийиктиги: {(order.info.dimensions.height * 0.01).toFixed(2)} м, 
                        Туурасы: {(order.info.dimensions.width * 0.01).toFixed(2)} м,  */}
                        Бийиктиги: {(height * 0.01).toFixed(2)} м, 
                        Туурасы: {(width * 0.01).toFixed(2)} м, 
                        &nbsp;
                        {/* {((order.info.dimensions.height * order.info.dimensions.width) * 0.0001).toFixed(3) * order.info.quantity} м2 */}
                        {quantity} x {((height * width) * 0.0001).toFixed(3)} м2
                        =
                        {((height * width) * 0.0001).toFixed(3) * quantity} м2
                      </div>
                      <div className="vertical-end">
                        <h4>Өлчөмүн өзгөртүү</h4>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                          <div>
                            <label htmlFor="height">Бийиктиги (cм):</label>
                            <input
                              type="number"
                              id="height"
                              name="height"
                              placeholder="Бийиктиги (cм)"
                              value={height}
                              onChange={(e) => {
                                setHeight(e.target.value);
                                setSize(
                                  parseFloat(((e.target.value * width) * 0.0001).toFixed(3))
                                );
                              }}
                              step="1"
                              required
                            />
                          </div>
                          <div>
                            <label htmlFor="width">Туурасы (cм):</label>
                            <input
                              type="number"
                              id="width"
                              name="width"
                              placeholder="Туурасы (cм)"
                              value={width}
                              onChange={(e) => {
                                setWidth(e.target.value);
                                setSize(
                                  parseFloat(((e.target.value * height) * 0.0001).toFixed(3))
                                );
                              }}
                              step="1"
                              required
                            />
                          </div>
                          <button 
                            onClick={() => handleUpdateOrderChunkProperty(order._id, 
                              { 
                                'info.dimensions.height': height, 
                                'info.dimensions.width': width,
                                'info.dimensions.size': size * quantity,
                                'info.price': price
                              })}
                            disabled={isLoadinggg} 
                            style={{ alignSelf: 'flex-end' }}>
                            Өзгөрт
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="section-container">
                      <h2>Ставка</h2>
                      <div className="order-info">{order.info.rate} сом</div>
                      <div className="vertical-end">
                        <h4>Ставканы өзгөртүү</h4>
                        <input
                          type="number"
                          name="rate"
                          placeholder="Ставка"
                          value={rate}
                          onChange={(e) => setRate(e.target.value)}
                          required
                        />
                        <button
                          onClick={() => handleUpdateOrderChunkProperty(order._id, 
                            { 
                              'info.rate': rate, 
                              'info.price': price
                            }
                          )}
                          disabled={isLoadinggg}
                        >
                          Өзгөрт
                        </button>
                      </div>
                    </div>
                    <div className="section-container">
                      <h2>Саны</h2>
                      <div className="order-info">{order.info.quantity} даана</div>
                      <div className="vertical-end">
                        <h4>Заказдын санын өзгөртүү</h4>
                        <input
                          type="number"
                          name="quantity"
                          placeholder="Заказдын саны"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          required
                        />
                        <button
                          onClick={
                            () => handleUpdateOrderChunkProperty(order._id, 
                            { 
                              'info.dimensions.size': ((height * width) * 0.0001).toFixed(3) * quantity,
                              'info.quantity': quantity,
                              'info.price': price
                            })
                          }
                          // onClick={() => handleUpdateOrderChunkProperty(order._id, 
                          //   { 
                          //     'info.quantity': quantity, 
                          //     'info.price': price
                          //   }
                          // )}
                          disabled={isLoadinggg}
                        >
                          Өзгөрт
                        </button>
                      </div>
                    </div>
                    <div className="section-container">
                      <h2>Печатниктин акысы</h2> 
                      <div className="order-info">{order.info.paymentForPrinter} сом</div>
                      <div className="vertical-end">
                          <h4>Печатниктин акысын өзгөртүү</h4>
                          <input
                              type="number"
                              name="paymentForPrinter"
                              placeholder="Печатниктин акысы"
                              value={paymentForPrinter}
                              onChange={(e)=>setPaymentForPrinter(e.target.value)}
                              required                          
                          />
                          <button 
                            onClick={() => handleUpdateOrderChunkProperty(order._id, 
                              { 
                                'info.paymentForPrinter': paymentForPrinter, 
                                'info.price': price,
                                // 'info.dimensions.size': ((height * width) * 0.0001).toFixed(3)
                              })}
                            disabled={isLoadinggg}>Өзгөрт</button>
                      </div>
                    </div>
                  </>
                )}
                <div className="section-container">
                  <h2>Стадия</h2>
                  <div className="order-info">{order.currentStage}</div>
                  <div className="vertical-end">
                    <h4>Стадияны өзгөртүү</h4>
                    <select
                      name="changeCurrentStage"
                      value={changeCurrentStage}
                      onChange={(e) => setChangeCurrentStage(e.target.value)}
                      required
                    >
                      {allStages.map((stage) => (
                        <option key={stage} value={stage}>
                          {stage}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'currentStage', changeCurrentStage)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Заказдын түрү</h2>
                  {/* <div className="order-info">{order.office}</div> */}
                  <div className="order-info">Офистин заказыбы? {order.office ? "Ооба" : "Жок"}</div>
                  <div className="vertical-end">
                    <h4>Заказдын түрү өзгөртүү</h4>
                    <select
                      name="office"
                      value={office}
                      onChange={(e) => setOffice(e.target.value === "true")}
                      required
                      style={{ width: "120px" }}
                    >
                      <option value="true">Ооба</option>
                      <option value="false">Жок</option>
                    </select>
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'office', office)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
              </div>

              <h3>Этаптары</h3>
              {order.stages.map((stage, index) => (
                <div key={index} className="stages-container">
                  <div className="stage-detail stage-detail-width-90">
                    <strong>Этап:</strong><br></br> 
                    {stage.stageName}
                  </div>
                  <div className="stage-detail stage-detail-width">
                    <strong>Аткаруучулар:</strong><br></br>  
                    {stage.assignedUsers.map(user => user.username).join(', ')}
                  </div>
                  <div className="stage-detail stage-detail-width-90">
                    <strong>Башталды:</strong><br></br>  
                    {stage.startedAt ? formatDate(stage.startedAt) /*new Date(stage.startedAt).toLocaleDateString()*/ : 'Баштала элек'}
                  </div>
                  <div className="stage-detail stage-detail-width-90">
                    <strong>Аяктады:</strong><br></br>  
                    {stage.completedAt ? formatDate(stage.completedAt)/*new Date(stage.completedAt).toLocaleDateString()*/ : 'Бүтө элек'}
                  </div>
                  <button className='button-height' onClick={() => handleStageDelete(order._id,stage._id)} disabled={isLoadinggg}>Өчүр</button>
                  {!stage.completedAt && (
                  <button className="button-margin button-height" onClick={() => handleCompleted(order,stage.assignedUsers[0]._id)} disabled={isLoadinggg}>Аткарылды</button>
                  )}
                  
                </div>
              ))}
                          {/* Form to add a new stage */}





              <div className="stage-form">
                <h4>Жаңы этап кошуу</h4>
                <div className="form-row">
                  <div className="form-group">
                    <label>Этаптын аталышы:</label>
                    <select name="stageName" value={newStage.stageName} onChange={handleStageChange}>
                      <option value="">Этап тандаңыз</option>
                      {availableStages.map((stageName, index) => (
                          <option key={index} value={stageName}>
                              {stageName}
                          </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Аткаруучулар:</label>
                    <select
                      name="assignedUsers"
                      multiple
                      value={newStage.assignedUsers}
                      onChange={handleAssignedUsersChange}
                    >
                      {/* Replace this with a mapped list of users */}
                      {users.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Башталды:</label>
                    <input
                      type="date"
                      name="startedAt"
                      value={newStage.startedAt}
                      onChange={handleStageChange}
                    />
                  </div>

                  {/* <div className="form-group">
                    <label>Аяктады:</label>
                    <input
                      type="date"
                      name="completedAt"
                      value={newStage.completedAt}
                      onChange={handleStageChange}
                    />
                  </div> */}

                  <div className="form-group">
                    <button
                      onClick={() => handleAddStage(order._id)}
                      disabled={isLoadinggg || !newStage.stageName}
                      className="add-stage-button"
                    >
                      {isLoadinggg ? 'Кошуп жатат...' : 'Этап кошуу'}
                    </button>
                  </div>
                </div>
              </div>
        </div>
        );
}

export default Update;