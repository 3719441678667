// src/redux/material/materialApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const materialApi = createApi({
  reducerPath: 'materialApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.ilim-ordo.kg/' }), // Set your API base URL here
  endpoints: (builder) => ({
    getAllMaterials: builder.query({
      query: () => 'api/materials',
    }),
    getMaterialById: builder.query({
      query: (id) => `api/materials/${id}`,
    }),
    createMaterial: builder.mutation({
      query: (title) => ({
        url: 'api/materials',
        method: 'POST',
        body: { title },
      }),
    }),
    updateMaterial: builder.mutation({
      query: ({ id, title }) => ({
        url: `api/materials/${id}`,
        method: 'PUT',
        body: { title },
      }),
    }),
    deleteMaterial: builder.mutation({
      query: (id) => ({
        url: `api/materials/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteMaterialFromOrder: builder.mutation({
      query: ({ orderId, materialId }) => ({
        url: `api/orders/${orderId}/materials/${materialId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllMaterialsQuery,
  useGetMaterialByIdQuery,
  useCreateMaterialMutation,
  useUpdateMaterialMutation,
  useDeleteMaterialMutation,   
} = materialApi;
