import React, { useLayoutEffect, useRef } from "react";
import { useSpring, animated } from "@react-spring/web";
import './../css/indicator.css';

const AnimatedPath = animated.path;

const IndicatorSVG = (props) => {
  React.useEffect(()=>{console.log('indicator')},[]);
  // Create spring animation

  const pathsRef = useRef(new Map());
  
  useLayoutEffect(() => {
    // Trigger animation setup after DOM measurement
    pathsRef.current.forEach((node, id) => {
      if (node) {
        const length = node.getTotalLength();
        node.style.strokeDasharray = length;
        node.style.strokeDashoffset = length;
      }
    });
  }, []);
  const commonConfig = {
    mass: 1,
    tension: 120,
    friction: 26,
    duration: 1500
  };
  const mSpringProps = useSpring({
    from: { strokeDashoffset: 2157.30 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const dSpringProps = useSpring({
    from: { strokeDashoffset: 1816.80 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const secondOSpringProps = useSpring({
    from: { strokeDashoffset: 1485.74 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const firstOSpringProps = useSpring({
    from: { strokeDashoffset: 1484.97 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const RSpringProps = useSpring({
    from: { strokeDashoffset: 985.28 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const LSpringProps = useSpring({
    from: { strokeDashoffset: 1006.73 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const secondIFootSpringProps = useSpring({
    from: { strokeDashoffset: 729.95 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const firstIFootSpringProps = useSpring({
    from: { strokeDashoffset: 730.37 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const secondICircleSpringProps = useSpring({
    from: { strokeDashoffset: 173.12 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  const firstICircleSpringProps = useSpring({
    from: { strokeDashoffset: 173.02 },
    to: { strokeDashoffset: 0 },
    config: commonConfig,
    delay: 100
  });
  return (
    <div className="indicator">
        <svg version="1.0" viewBox="0 0 2048 578" width="2048px" height="578px" xmlns="http://www.w3.org/2000/svg"
        {...props}
        >
        <AnimatedPath ref={(node) => pathsRef.current.set('M', node)} 
            id="M" transform="translate(662,229)" d="m0 0 12 1 10 4 11 8 8 9 5 11v13l-4 14h2l2-4 26-26 14-11 13-9 16-8 9-2h7l13 4 11 7 8 8 6 10 1 3v14l-37 148 1 5 2 2h13l15-5 20-11h2l-2 4-12 13-8 7-10 8-14 9-13 6-14 3-8-1-6-2-6-4-4-6-1-3v-14l18-73 21-83 1-5-20 6-17 8-14 9-12 9-10 9-7 6-7 8-9 10-4 8-18 72-1 8 1 3 2 1h13l15-5 20-11 2 2-13 14-8 7-15 12-16 9-10 4-11 2-9-1-7-3-5-4-4-8-1-8 5-23 36-144-20 6-19 9-15 10-14 11-15 14-9 10-7 10-18 71-8 31-4 9-5 5-5 3-13 2-10-2-5-5-1-3v-7l18-73 24-95 1-10-1-4-10-1-15 4-21 11-5 2 2-4 7-8 9-9 11-9 13-9 16-8 12-3h9l9 3 7 6 3 6v15l-7 28 14-14 5-6 8-7 10-9 15-11 14-8 11-4z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={mSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('D', node)}
            id="D" transform="translate(1699,97)" d="m0 0 12 1 8 5 4 5 2 5v13l-7 30-49 196-17 67v9l3 2h12l15-5 17-9 5-2-2 5-16 16-14 11-14 9-13 6-14 3-8-1-6-2-6-4-4-8v-19l1-7-12 14-9 9-16 11-11 5-12 3h-20l-12-3-11-6-10-8-11-12-12-18-3-12-2-14-1-24 1-19 3-19 6-21 8-19 8-14 10-13 7-8 11-9 13-8 11-5 12-3 7-1h13l14 3 16 8 9 7 10 10 7 11 1 3-6-1-16-5-5-1h-22l-16 5-12 6-10 8-10 10-10 16-7 15-6 20-3 19v40l2 15 8 7 10 5 5 1h9l16-4 11-6 9-7 9-8 9-11 11-15 12-21 8-17 51-204-1-6-5-2-12 1-16 6-16 9-3-1 7-8 12-12 14-11 14-9 11-5 6-2z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={dSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('secondO', node)}
            id="secondO" transform="translate(1883,230)" d="m0 0h10l15 2 14 5 12 8 10 9 9 12 9 18 2 18v22l-3 26-6 23-9 21-10 16-9 11-6 7-8 7-12 9-16 9-15 6-16 4-25 3-16-1-18-3-15-6-11-6-13-11-10-12-8-14-4-14-2-15v-19l2-15 4-15 6-15 8-15 9-12 11-13 11-11 10-8 16-11 20-11 2 2-7 8-8 8-11 15-10 18-8 18-6 20-4 20-2 20v24l2 18 3 5 9 7 10 5 10 3 9 1h13l13-2 13-4 13-7 9-7 8-7 10-14 10-19 6-18 4-16 3-21 1-11v-26l-7-8-8-5-6-2h-15l-12 6-7 8-5 12-1 6v15l3 13 6 11 7 9 10 9v3l-17-3-16-6-10-6-10-9-7-9-4-8-3-12v-19l3-12 7-13 11-12 10-7 10-5 14-4z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={secondOSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('firstO', node)}
            id="firstO" transform="translate(1149,230)" d="m0 0h9l15 2 14 5 11 7 10 9 7 8 8 13 4 9 2 14v33l-4 27-6 20-11 23-8 12-11 13-9 9-15 11-21 11-19 6-19 3-16 1-20-2-15-4-12-5-11-7-12-11-8-9-8-14-4-12-2-11-1-18 2-21 5-19 7-16 9-16 9-11 11-13 12-11 19-14 22-12h3l-2 4-11 11-11 15-9 15-9 19-7 21-4 19-2 15v40l2 13 8 8 16 8 7 2 10 1h12l13-2 13-4 12-6 12-9 8-8 9-13 8-15 7-19 5-21 2-13 2-25v-16l-6-8-10-6-6-2h-14l-10 4-6 5-7 12-3 16 1 13 4 13 5 9 8 10 9 8-1 2-17-3-16-6-11-7-9-8-7-10-4-9-3-13v-13l4-16 7-13 10-11 10-7 16-7 9-2z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={firstOSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('R', node)}
            id="R" transform="translate(1419,229)" d="m0 0h11l12 3 11 6 7 7 5 10 2 8v11l-3 12-4 8-8 9-10 6-6 2h-17l-11-4-10-8-5-8v-3h15l8-4 2-3 1-8-3-6-3-2h-7l-10 4-11 9-7 8-10 13-12 18-13 22-9 17-17 66-7 26-3 6-5 5-5 3-12 2-11-2-5-5-1-2v-9l25-100 17-67 1-10-5-5-11 1-12 4-16 8-6 4h-2l2-5 16-16 14-11 10-7 16-8 12-3h9l9 3 7 6 3 6v15l-3 15 12-16 6-7 11-9 14-9 15-5z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={RSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('L', node)}
            id="L" transform="translate(320,97)" d="m0 0 11 1 8 4 6 8 1 4v10l-5 23-42 168-17 67-10 41 1 5 2 2h13l15-5 20-11h2l-2 4-11 12-8 7-15 12-16 9-10 4-4 1h-16l-7-3-5-4-4-8v-14l18-73 34-136 18-71 3-13v-9l-6-2-11 1-16 6-16 9h-3l2-4 18-18 17-13 14-8 13-5z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={LSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('secondIFoot', node)}
            id="secondIFoot" transform="translate(422,231)" d="m0 0h8l8 2 8 6 3 5 1 4v11l-6 26-34 136v7l3 2h13l12-4 24-12v3l-20 20-17 13-14 8-15 5-10 1-11-3-8-7-3-10 1-12 20-81 19-78-1-6-5-3-11 1-16 6-16 9-3 1 2-5 17-17 14-11 14-9 11-5z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={secondIFootSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('firstIFoot', node)}
            id="firstIFoot" transform="translate(149,231)" d="m0 0h9l9 3 6 5 3 5 1 4v12l-18 73-22 88v7l3 2h13l12-4 24-12v3l-20 20-17 13-16 9-13 4-8 1-11-2-6-4-5-6-2-12 3-16 20-81 17-70-1-6-4-3h-7l-11 3-16 7-11 7h-2l2-5 17-17 14-11 14-9 11-5z" 
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={firstIFootSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('secondICircle', node)}
            id="secondICircle" transform="translate(455,129)" d="m0 0h12l7 3 6 5 3 6v13l-3 8-9 10-12 6-3 1h-13l-10-5-5-7-1-4v-11l6-12 9-8 8-4z"            
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={secondICircleSpringProps}
        />
        <AnimatedPath ref={(node) => pathsRef.current.set('firstICircle', node)}
            id="firstICircle" transform="translate(182,129)" d="m0 0h12l7 3 6 5 3 7v12l-3 8-9 10-11 6-4 1h-13l-9-4-5-6-2-6v-10l4-10 7-8 10-6z"
            fill="none"
            stroke="blue"
            strokeWidth={10}
            style={firstICircleSpringProps}
        />
        </svg>
    </div>
  );
};

export default IndicatorSVG;