import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const roleApi = createApi({
  reducerPath: "roleApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://api.ilim-ordo.kg/" }), // Update base URL
  endpoints: (builder) => ({
    getAllRoles: builder.query({
      query: () => "api/roles",
    }),
    getRoleById: builder.query({
      query: (id) => `api/roles/${id}`,
    }),
    createRole: builder.mutation({
      query: (name) => ({
        url: "api/roles",
        method: "POST",
        body: { name },
      }),
    }),
    updateRole: builder.mutation({
      query: ({ id, name }) => ({
        url: `api/roles/${id}`,
        method: "PUT",
        body: { name },
      }),
    }),
    deleteRole: builder.mutation({
      query: (id) => ({
        url: `api/roles/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllRolesQuery,
  useGetRoleByIdQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = roleApi;
