// /src/components/order/Orders.jsx
import React, { useEffect, useState } from 'react';
import './../css/table.css';
import './../css/header.css';
import './../auth/auth.css';
import { Link } from 'react-router-dom';
import { useDeleteOrderMutation } from '../../redux/order/orderApi';

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteOrder] = useDeleteOrderMutation();
    // Fetch orders from the API
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch('https://api.ilim-ordo.kg/api/order?status=true'); // Replace with your API endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }
                const data = await response.json();
                console.log('data: ',data)
                setOrders(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        console.log('useEffect')
        fetchOrders();
    }, []);

    if (loading) {
        return <div className="auth-container">Заказдар жүктөлүп жатат...</div>;
    }

    if (error) {
        return <div className="auth-container">Ката: {error}</div>;
    }
    const handleDelete = async (id) => {
        try {
            await deleteOrder(id).unwrap();
            //   console.log(`Order with id ${id} deleted successfully.`);
            window.location.reload();
        } catch (error) {
          console.error('Failed to delete the order:', error);
        }
      };
      const isLastStageCompleted = (order) => {
        if (order.stages && order.stages.length > 0) {
          return (
            order.stages[order.stages.length - 1].stageName === order.currentStage &&
            order.stages[order.stages.length - 1].completedAt
          );
        }
        return false; // Return false if stages array is empty or not present
      };
    //   const isLastStageHasStarted = (order) => {
    //     if (order.stages && order.stages.length > 0) {
    //       return (
    //         order.stages[order.stages.length - 1].stageName === order.currentStage &&
    //         order.stages[order.stages.length - 1].startedAt
    //       );
    //     }
    //     return false; // Return false if stages array is empty or not present
    //   };
    //   orders.forEach((order) => {
    //     console.log(`Order No: ${order.orderNo}`);
                // order.stages.forEach((stage) => {
                // console.log(`  Stage: ${stage.stageName}`);
                // stage.assignedUsers.forEach((user) => {
                //     console.log(`    Assigned User: ${user.username}`);
                // });
                // });
    //   });
      
    return (
        <div>
            <div className="header">
                <h2>Заказдар</h2>
                <Link to={'/createOrder'} className="create-btn">
                    Жаңы заказ 
                </Link>
            </div>
            {orders.length === 0 ? (
                <p>Заказдар жүктөлө элек.</p>
            ) : (
                <table className="auth-form">
                    <thead>
                        <tr>
                            <th>Номери</th>
                            <th></th>
                            <th>Төлөмдөр</th>
                            <th>Клиент</th>
                            <th>Стадия</th>
                            <th>Материалдар</th>
                            <th>Размер</th>
                            <th></th>
                            <th></th>
                            <th>Ким бекитти</th>
                            <th>Датасы</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order,index) => (
                            <tr key={order._id || index}>
                                <td>
                                    <Link to={'/designer/' + order._id} className="submit-button">                                    
                                        {order.orderNo}
                                    </Link>
                                </td>
                                <td>
                                    Аталышы: {order.title}<br></br>
                                    ({order.info.priority})<br></br>
                                    Маалымат: {order.details}
                                </td>
                                <td>
                                    {order.info.payments
                                        ? order.info.payments.map((payment, index) => (
                                              <div key={payment.id || index}>
                                                  {payment.amount} сом ({payment.method})
                                              </div>
                                          ))
                                        : 'Төлөм жок'}
                                </td>
                                <td>{order.customer}<br></br>
                                    <a
                                        href={`https://wa.me/${order.info.whatsapp}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="whatsapp-link"
                                    >
                                        {order.info.whatsapp}
                                    </a>
                                </td>
                                <td >
                                    {order.currentStage}<br></br>
                                    <div style={{marginLeft:'20px',fontStyle:'italic',fontSize:'12px'}}>{isLastStageCompleted(order) ? 'Аткарылды' : !isLastStageCompleted(order) ? 'Бүтө элек' : 'Баштала элек'}</div>
                                    <ul>
                                        {order.stages.slice().reverse().map((stage) => (
                                        <li key={stage._id}>
                                            {stage.stageName} &nbsp;
                                            (&nbsp;
                                                {stage.assignedUsers.slice().reverse().map(user => user.username).join(', ')}
                                            &nbsp;)
                                        </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    {order.info.materials.map((material,index) => (                                        
                                            <div key={material.id || index}>
                                                {material.title} 
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>{order.info.dimensions.height} см x {order.info.dimensions.width} см = {order.info.dimensions.size} м2</td>
                                <td>Ставка: {order.info.rate} сом<br></br>
                                    Баасы: {order.info.price} сом
                                </td>
                                <td>Диз. акысы: {order.info.paymentForDesign} сом<br></br>
                                    Рейка: {order.info.frames} сом <br></br>
                                    Люверси: {order.info.leuvers} сом <br></br>
                                    Установка: {order.info.installation} сом 
                                </td>
                                <td>{order.createdBy?.username || 'Белгисиз'}</td>
                                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                <td >
                                    <Link to={'/update/' + order._id} className="submit-button">                                    
                                        Өзгөртүү
                                    </Link>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => handleDelete(order._id)}>Өчүрүү</button>
                                    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Orders;
