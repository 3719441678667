// src/redux/customer/customerApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.ilim-ordo.kg/' }), // Set your API base URL here
  endpoints: (builder) => ({
    getAllCustomers: builder.query({
      query: () => 'api/customers',
    }),
    getCustomerById: builder.query({
      query: (id) => `api/customers/${id}`,
    }),
    createCustomer: builder.mutation({
      query: (title) => ({
        url: 'api/customers',
        method: 'POST',
        body: { title },
      }),
    }),
    updateCustomer: builder.mutation({
      query: ({ id, title }) => ({
        url: `api/customers/${id}`,
        method: 'PUT',
        body: { title },
      }),
    }),
    deleteCustomer: builder.mutation({
      query: (id) => ({
        url: `api/customers/${id}`,
        method: 'DELETE',
      }),
    }),
    deleteCustomerFromOrder: builder.mutation({
      query: ({ orderId, customerId }) => ({
        url: `api/orders/${orderId}/customers/${customerId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllCustomersQuery,
  useGetCustomerByIdQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,   
} = customerApi;
