// src/components/list/RoleList.jsx
import React, { useState } from "react";
import {
  useGetAllRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} from "../../redux/list/role/roleApi";

const RoleList = () => {
  const { data: roles = [], refetch } = useGetAllRolesQuery();
  const [createRole] = useCreateRoleMutation();
  const [updateRole] = useUpdateRoleMutation();
  const [deleteRole] = useDeleteRoleMutation();

  const [name, setName] = useState("");
  const [editId, setEditId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editId) {
      await updateRole({ id: editId, name });
      setEditId(null);
    } else {
      console.log('name :',name)
      await createRole(name);
    }
    setName("");
    refetch(); // Refresh the list after any change
  };

  const handleEdit = (role) => {
    setName(role.name);
    setEditId(role._id);
  };

  const handleDelete = async (id) => {
    await deleteRole(id);
    refetch(); // Refresh the list after deletion
  };

  return (
    <div className="auth-container">
      <h2>Ролдор</h2>
      <ul>
        {roles.map((role) => (
          <li key={role._id} className="form-group">
            <div className="button-group">
              <span> {role.name} &nbsp; </span>
              <button onClick={() => handleEdit(role)}>Өзгөртүү</button>
              <button onClick={() => handleDelete(role._id)}>Өчүрүү</button>
            </div>
          </li>
        ))}
      </ul>

      <h2>{editId ? "Өзгөртүү" : "Кошуу"}</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Роль:</label>
          <input
            className="form-input"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Роль"
            required
          />
        </div>
        <button type="submit" className="submit-button">
          {editId ? "Өзгөртүү" : "Кошуу"}
        </button>
      </form>
    </div>
  );
};

export default RoleList;
