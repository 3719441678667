import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDeleteOrderMutation } from '../../redux/order/orderApi';

const OrderStage = () => {
    const { stageName } = useParams(); 
    const { navigate } = useNavigate();
    const [orders, setOrders] = useState([]);
    const [deleteOrder] = useDeleteOrderMutation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { user } = useSelector((state) => state.auth);
    const hasManagerRole = user?.roles?.includes("ROLE_МЕНЕДЖЕР");

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                let response;
                if (stageName === 'Все') {
                    console.log('Все');
                    response = await fetch(`https://api.ilim-ordo.kg/api/order?status=true`);
                } else {
                    console.log('stageName: ', stageName);
                    response = await fetch(`https://api.ilim-ordo.kg/api/order?status=true&stageName=${encodeURIComponent(stageName)}`);
                }
                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }
                const data = await response.json();
                let newOrders = [];
                let myOrders = [];

                const stageLevelMap = {
                    'Дизайн': 0,
                    'Печать': 1,
                    'Постпечать': 2,
                    'Установка': 3,
                    'Финиш': 4
                };

                data.forEach(order => {
                    console.log('order.title: ',order.orderNo,' ',order.title)
                    const oSL = stageLevelMap[order.currentStage] ?? -1;
                    if (order.stages.length === oSL) {
                        order.displayType = 'new';
                        newOrders.push(order); 
                        console.log('order.stages: ',order.orderNo,' ',order.stages)
                    }
                    const matchingStages = order.stages.filter(stage => 
                        stage.stageName === order.currentStage &&
                        stage.assignedUsers.some(assignedUser => assignedUser._id === user?.id));

                    if (matchingStages.length > 0) {
                        order.displayType = 'my';
                        console.log('order.title in my: ',order.orderNo,' ',order.title)
                        myOrders.push(order);
                    }
                });
                myOrders = [...myOrders, ...newOrders];
                setOrders(myOrders);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, [stageName, user]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
    const isLastStageCompleted = (order) => {
        if (order.stages && order.stages.length > 0) {
          return (
            order.stages[order.stages.length - 1].stageName === order.currentStage &&
            order.stages[order.stages.length - 1].completedAt
          );
        }
        return false; // Return false if stages array is empty or not present
      };
      const handleDelete = async (id) => {
        try {
            await deleteOrder(id).unwrap();
            //   console.log(`Order with id ${id} deleted successfully.`);
            window.location.reload();
        } catch (error) {
          console.error('Failed to delete the order:', error);
        }
      };
    return (
        <div >
            <div className="header">
            <h2>{ stageName === 'Дизайн' ? 'Дизайнерлер' : (stageName === 'Печать') ? 'Печатниктер' : (stageName === 'Постпечать') ? 'Постпечатниктер' : 'Установщиктер'}
                &nbsp;үчүн заказдар</h2>
                {stageName === 'Дизайн' && (
                    // <button onClick={()=>navigate('/specificOrders')}>Менин заказдарым</button>
                    <Link to={'/specificOrders'} className="create-btn blue-color">
                        Менин заказдарым
                    </Link>
                )}
            </div>
            {orders.length === 0 ? (
                <p>Заказдар жүктөлө элек.</p>
            ) : (
                <table className="auth-form">
                    <thead>
                        <tr>
                            <th>Номери</th>
                            <th></th>
                            <th>Төлөмдөр</th>
                            <th>Клиент</th>
                            <th>Стадия</th>
                            <th>Материалдар</th>
                            <th>Размер</th>
                            {hasManagerRole ? (
                                <>
                                    <th></th>
                                    <th></th>
                                </>
                            ) : (
                                <></>
                            )}
                            <th>Ким бекитти</th>
                            <th>Датасы</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order,index) => (
                            <tr key={order._id || index}>
                                <td>
                                    <Link to={'/designer/' + order._id} className="submit-button">                                    
                                        {order.orderNo}
                                    </Link>
                                </td>
                                <td style={{ color: order.info.priority === 'Срочный' ? 'red' : 'inherit' }}>
                                    Аталышы: {order.title}<br></br>
                                    Маалымат: {order.details}<br></br>
                                    {order.displayType === 'my' ? 'меники': 'жаңы'}
                                </td>
                                <td>
                                    {order.info.payments
                                        ? order.info.payments.map((payment, index) => (
                                              <div key={payment.id || index}>
                                                  {payment.amount} сом ({payment.method})
                                              </div>
                                          ))
                                        : 'Төлөм жок'}
                                </td>
                                <td>{order.customer}<br></br>
                                    <a
                                        href={`https://wa.me/${order.info.whatsapp}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="whatsapp-link"
                                    >
                                        {order.info.whatsapp}
                                    </a>
                                </td>
                                <td >
                                    {order.currentStage}<br></br>
                                    <div style={{marginLeft:'20px',fontStyle:'italic',fontSize:'12px'}}>{isLastStageCompleted(order) ? 'Аткарылды' : !isLastStageCompleted(order) ? 'Бүтө элек' : 'Баштала элек'}</div>
                                    <ul>
                                        {order.stages.slice().reverse().map((stage) => (
                                        <li key={stage._id}>
                                            {stage.stageName} &nbsp;
                                            (&nbsp;
                                                {stage.assignedUsers.slice().reverse().map(user => user.username).join(', ')}
                                            &nbsp;)
                                        </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    {order.info.materials.map((material,index) => (                                        
                                            <div key={material.id || index}>
                                                {material.title} 
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>{order.info.dimensions.height} см x {order.info.dimensions.width} см = {order.info.dimensions.size} м2</td>
                                {hasManagerRole ? (
                                    <>
                                    <td>Ставка: {order.info.rate} сом<br></br>
                                        Баасы: {order.info.price} сом
                                    </td>
                                    <td>Постпечатник: {order.info.paymentForPostprinter} сом<br></br>
                                        Печатник: {order.info.paymentForPrinter} сом<br></br>
                                        Диз. акысы: {order.info.paymentForDesign} сом<br></br>
                                        Рейка: {order.info.frames} сом <br></br>
                                        Люверси: {order.info.leuvers} сом <br></br>
                                        Установка: {order.info.paymentForInstallation} сом 
                                    </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <td>{order.createdBy?.username || 'Белгисиз'}</td>
                                <td>{new Date(order.createdAt).toLocaleDateString()}
                                </td>
                                {hasManagerRole ? (
                                <td >
                                    <Link to={'/update/' + order._id} className="submit-button">                                    
                                        Өзгөртүү
                                    </Link>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => handleDelete(order._id)}>Өчүрүү</button>
                                </td>
                                ) : (
                                    <></>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default OrderStage;
