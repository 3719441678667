// App.jsx
import { Routes, Route } from 'react-router-dom';

import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import Logout from './components/auth/LogOut';

import ProtectedRoute from './components/ProtectedRoute';


import CreateOrder from './components/order/CreateOrder';
import Orders from './components/order/Orders';
import Update from './components/order/Update';

import OrderList from './components/order/OrderList';
import MaterialList from './components/list/MaterialList';
import CustomerList from './components/list/CustomerList';
import RoleList from './components/list/RoleList';
import UserList from './components/list/UserList';
import Designer from './components/users/designer/Designer';

// import InitiateResetPassword from './components/auth/InitiateResetPassword';
// import ResetPassword from './components/auth/ResetPassword';

// import StagedOrderList from './components/StagedOrderList';

// import Update from './components/Update';
// import Create from './components/Create';
// import OrderDetails from './components/OrderDetails';
// import Designer from './components/users/Designer';
// import Printer from './components/Printer';
// import Manager from './components/users/Manager';
// import Customer from './components/manager';

function App() {
//   const { user } = useSelector((state) => state.auth); // Get user from Redux state
  return (
    <div className="app-container">
      <Routes>
        {/* Public Routes */}
        <Route path="/designer/:_id" element={<ProtectedRoute element={<Designer />} />} />
        <Route path="/update/:_id" element={<ProtectedRoute element={<Update />} />} />

        <Route path="/createOrder" element={<ProtectedRoute element={<CreateOrder />} />} />
        <Route path="/orders" element={<ProtectedRoute element={<Orders />} />}/>
        <Route path="/orderList" element={<ProtectedRoute element={<OrderList />} />}/>

        <Route path="/user" element={<ProtectedRoute element={<UserList />} />}/>
        <Route path="/role" element={<ProtectedRoute element={<RoleList />} />}/>
        <Route path="/material" element={<ProtectedRoute element={<MaterialList />} />}/>
        <Route path="/customer" element={<ProtectedRoute element={<CustomerList />} />}/>


        {/* <Route path="/customer" element={<Customer />} /> */}
        {/* <Route path="/printer" element={<Printer />} />
        <Route path="/manager" element={<Manager />} /> */}

        {/* <Route path="/update/:_id" element={<Update />} />
        <Route path="/orderList" element={<OrderList />} />
        <Route path="/create" element={<Create />}/>
        <Route path="/order/:_id" element={<OrderDetails />}/> */}
        
        {/* <Route path="/initiate" element={<InitiateResetPassword />} />
        <Route path="/reset" element={<ResetPassword />} /> */}

        {/* <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/logout" element={<Logout />} /> */}

        {/* Public Routes */}
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
        {/* {user && (
          <>
            <h2>Welcome, {user.username}</h2>
            <Logout />
          </>
        )} */}
    </div>
  );
}

export default App;