// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 2px;
    width: 600px;
    border: 1px solid black;
}
.table div {
    border: 1px solid black;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
}
.header {
    grid-column: span 5;
    text-align: center;
    font-weight: bold;
}
.span-2 {
    grid-column: span 2;
}
.span-3 {
    grid-column: span 3;
}
.label {
    text-align: left;
    padding-left: 5px;
}
.green-box {
    background-color: #009933;
    color: white;
    font-weight: bold;
}
.red-text {
    color: red;
    font-weight: bold;
}
.yellow-box {
    background-color: #f2e5ac;
    color: black;
}
.center {
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/css/manager.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,QAAQ;IACR,YAAY;IACZ,uBAAuB;AAC3B;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;AACpB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,UAAU;IACV,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".table {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    gap: 2px;\n    width: 600px;\n    border: 1px solid black;\n}\n.table div {\n    border: 1px solid black;\n    padding: 8px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    min-height: 30px;\n}\n.header {\n    grid-column: span 5;\n    text-align: center;\n    font-weight: bold;\n}\n.span-2 {\n    grid-column: span 2;\n}\n.span-3 {\n    grid-column: span 3;\n}\n.label {\n    text-align: left;\n    padding-left: 5px;\n}\n.green-box {\n    background-color: #009933;\n    color: white;\n    font-weight: bold;\n}\n.red-text {\n    color: red;\n    font-weight: bold;\n}\n.yellow-box {\n    background-color: #f2e5ac;\n    color: black;\n}\n.center {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
