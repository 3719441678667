// src/components/list/MaterialList.js
import React, { useState } from 'react';
import {
  useGetAllMaterialsQuery,
  useCreateMaterialMutation,
  useUpdateMaterialMutation,
  useDeleteMaterialMutation,
} from '../../redux/list/material/materialApi';

const MaterialList = () => {
  const { data: materials = [], refetch } = useGetAllMaterialsQuery();
  const [createMaterial] = useCreateMaterialMutation();
  const [updateMaterial] = useUpdateMaterialMutation();
  const [deleteMaterial] = useDeleteMaterialMutation();

  const [title, setTitle] = useState('');
  const [editId, setEditId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editId) {
      await updateMaterial({ id: editId, title });
      setEditId(null);
    } else {
      await createMaterial(title);
    }
    setTitle('');
    refetch(); // Refresh the list after any change
  };

  const handleEdit = (material) => {
    setTitle(material.title);
    setEditId(material._id);
  };

  const handleDelete = async (id) => {
    await deleteMaterial(id);
    refetch(); // Refresh the list after deletion
  };

  return (
    <div className="auth-container">
      <h2>Материалдар</h2>
      <ul>
        {materials.map((material) => (
          <li key={material._id} className="form-group">
          <div className="button-group">
            <span>{material.title}</span>
            <button onClick={() => handleEdit(material)}>Өзгөртүү</button>
            <button onClick={() => handleDelete(material._id)}>Өчүрүү</button>
            </div>
          </li>
        ))}
      </ul>

      <h2>{editId ? 'Материалды өзгөрт' : 'Материалды кош'}</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="form-group">
        <label>Материал</label>
          <input
            className="form-input"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Материал"
            required
          />
        </div>
        <button type="submit" className="submit-button">{editId ? 'Өзгөртүү' : 'Кошуу'}</button>
      </form>
    </div>
  );
};

export default MaterialList;
