// /src/components/order/PerechisPayments.jsx
import React, { useState, useEffect } from 'react';
import './../css/filter.css';

const PerechisPayments = () => {
  const [paymentsData, setPaymentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
  });
  const [endDate, setEndDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth() + 1, 0); // Last day of the current month
  });

  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const [filteredOrdersCount, setFilteredOrdersCount] = useState(0);
  const [filteredStartDate, setFilteredStartDate] = useState(null);
    const [filteredEndDate, setFilteredEndDate] = useState(null);
    const [totalAmount, setTotalAmount] = useState(0);  // New state variable

    const [customerQuery, setCustomerQuery] = useState('');
    const [suggestedCustomers, setSuggestedCustomers] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);
  useEffect(() => {
    const fetchPaymentsData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch('https://api.ilim-ordo.kg/api/order');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const orders = await response.json();
        setAllOrdersCount(orders.length); // Set total orders count
        const uniqueCustomers = [...new Set(
            orders
              .map(order => order.customer)
              .filter(customer => customer)  // Filter out null/undefined values
          )];

        setAllCustomers(uniqueCustomers);

        // Filter out orders with 'перечис.' paymentMethod
        const filteredOrders = orders.filter(order =>
          order.info.payments.some(payment => payment.method === 'перечис.')
        );

        // Extract payment details and customer info
        const payments = filteredOrders.map(order => ({
          orderId: order._id,
          customer: order.customer,
          title: order.title,
          orderNo: order.orderNo,
          who: order.who,
          payments: order.info.payments.filter(payment => payment.method === 'перечис.'),
          // materials: order.info.materials?.map(m => m.title) || [], // Get material titles
          materials: order.info.materials?.length > 0
              ? order.info.materials.map(m => m.title)
              : order.info.papers?.length > 0
                  ? order.info.papers.map(p => p.paper.title)
                  : [],
          size: order.info.dimensions?.size || 'Нет данных',
          createdBy: order.createdBy,
          createdAt: order.createdAt
        }));

        setPaymentsData(payments);
      } catch (err) {
        setError(err);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPaymentsData();
  }, []);
  const filteredPayments = paymentsData.filter(payment => {
    const dateInRange = () => {
      if (!startDate || !endDate) return true;
      const createdAt = new Date(payment.createdAt);
      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setDate(end.getDate() + 1);
      return createdAt >= start && createdAt <= end;
    };
    const customerMatches = () => {
        if (!customerQuery) return true;
        return payment.customer &&  // Add null check here
               payment.customer.toLowerCase().includes(customerQuery.toLowerCase());
      };
    return dateInRange() && customerMatches();
  });
  useEffect(() => {
    setFilteredOrdersCount(filteredPayments.length); // set filtered orders count whenever filter changes
    const newTotalAmount = filteredPayments.reduce((sum, paymentData) => {
        const orderTotal = paymentData.payments.reduce((orderSum, payment) => orderSum + payment.amount, 0);
        return sum + orderTotal;
      }, 0);
      setTotalAmount(newTotalAmount);  
    }, [filteredPayments]);
  const formatDate = (date) => { //Date is passed directly
    if(!date) return ''; //Safety check if date is undefined

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
  useEffect(() => {
    // Set default formatted dates after initial state is set
    if (startDate) {
      setFilteredStartDate(formatDate(startDate));
    }
    if (endDate) {
      setFilteredEndDate(formatDate(endDate));
    }
  }, [startDate, endDate]); //Run whenever start or end dates changes

    const handleStartDateChange = (e) => {
        const newDate = e.target.value ? new Date(e.target.value) : null;
        setStartDate(newDate);
        setFilteredStartDate(newDate ? formatDate(newDate) : null); // also store it formatted
    };

    const handleEndDateChange = (e) => {
        const newDate = e.target.value ? new Date(e.target.value) : null;
        setEndDate(newDate);
        setFilteredEndDate(newDate ? formatDate(newDate) : null); //also store it formatted
    };

  if (loading) {
    return <p>Loading payment data...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
  // Add autocomplete handlers
  const handleCustomerInput = (e) => {
    const query = e.target.value;
    setCustomerQuery(query);
    
    const suggestions = allCustomers
    .filter(customer => 
      customer &&  // Add null check here
      customer.toLowerCase().includes(query.toLowerCase())
    )
    .slice(0, 5);
  
  setSuggestedCustomers(suggestions);
  };

  const selectCustomer = (customer) => {
    setCustomerQuery(customer);
    setSuggestedCustomers([]);
  };
const clearCustomerFilter = () => {
    setCustomerQuery('');
    setSuggestedCustomers([]);
  };
  return (
    <div>
      <h2>Перечисления</h2>

        <div className="filters" style={{justifyContent:'center'}}>

          <div className="filter-item" style={{ position: 'relative' }}>
            <label>Клиент:</label>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                value={customerQuery}
                onChange={handleCustomerInput}
                placeholder="Клиенттин аты..."
              />
              {customerQuery && (
                <button 
                  onClick={clearCustomerFilter}
                  style={{
                    position: 'absolute',
                    right: '8px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  ×
                </button>
              )}
              {suggestedCustomers.length > 0 && (
                <ul style={{
                  position: 'absolute',
                  zIndex: 1000,
                  background: 'white',
                  border: '1px solid #ccc',
                  width: '100%',
                  maxHeight: '200px',
                  overflowY: 'auto'
                }}>
                  {suggestedCustomers.map((customer, index) => (
                    <li
                      key={index}
                      onClick={() => selectCustomer(customer)}
                      style={{
                        padding: '8px',
                        cursor: 'pointer',
                        listStyle: 'none',
                        '&:hover': { backgroundColor: '#f0f0f0' }
                      }}
                    >
                      {customer}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

            <div className="filter-item" style={{flexDirection:'row'}}>
                <label>Баштоо күнү:</label>
                <input
                    type="date"
                    value={startDate ? formatDate(startDate) : ''} //format date here
                    onChange={handleStartDateChange}
                />
            </div>

            <div className="filter-item" style={{flexDirection:'row'}}>
                <label>Бүтүү күнү:</label>
                <input
                    type="date"
                    value={endDate ? formatDate(endDate) : ''}
                    onChange={handleEndDateChange}
                />
            </div>
        </div>
        <div style={{textAlign:'center',marginTop:'10px'}}>
            {filteredStartDate} - {filteredEndDate}  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;     Заказдар: {filteredOrdersCount} / {allOrdersCount}       &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  Жалпы: {totalAmount} сом
        </div>

      {filteredPayments.length > 0 ? (
        <table>
          <thead>
            <tr>
                <th></th>
              <th>#</th>
              <th>Аталышы</th>
              <th>Клиент</th>
              <th>Ким заказ берди</th>
              <th>Материалдар</th>
              <th>Размер</th>
              <th>Перечисление</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredPayments.map((paymentData, index) => (
              paymentData.payments.map((payment, paymentIndex) => (
                <tr key={`${paymentData.orderId}-${paymentIndex}`}>
                    <td>{index + 1}</td>
                    <td>{paymentData.orderNo}</td>
                    <td>{paymentData.title}</td>
                    <td>{paymentData.customer}</td>
                    <td>{paymentData.who}</td>
                    <td>
                        {paymentData.materials.join(', ') || 'Материал жок'}
                    </td>
                    <td>{paymentData.size} m2</td>
                    <td>{Number(payment.amount).toFixed(2)} сом</td>
                    <td>{formatDateString(paymentData.createdAt)}</td>
                </tr>
              ))
            ))}
          </tbody>
        </table>
      ) : (
        <p>Перечисление жок</p>
      )}
    </div>
  );
};

export default PerechisPayments;