import React from 'react';
import { useGetOrderByIdQuery } from '../redux/order/orderApi'; // Import the generated hook
import { useParams } from 'react-router-dom';
const stageNames = ['Дизайн', 'Печать', 'Подготовка', 'Установка']; // List of stages

const formatDate = (date) => {
    if (!date) return 'Бүтө элек';
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = d.getFullYear();
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
  
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

const OrderDetails = () => {
    const { _id } = useParams(); 
    const { data: order, error, isLoading } = useGetOrderByIdQuery(_id);
    const stageColors = {
        'Дизайн': '#ffcccc',       // Light red for Design
        'Печать': '#ccffcc',       // Light green for Print
        'Подготовка': '#ccccff',   // Light blue for PostProcessing
        'Установка': '#ffcc99',    // Light orange for Installation
      };
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;

  return (
    <div>
      <h1>Буюртма тууралуу маалымат</h1>
      <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
        <p><strong>Номери:</strong> {order.orderNumber}</p>
        <p><strong>Буюртма:</strong> {order.orderDetails}</p>
        <p><strong>Азыркы этап:</strong> {order.currentStage}</p>
      </div>

      <h2>Этап</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px'}}>
        {stageNames.map(stageName => {
          const stage = order.stages.find(stage => stage.stageName === stageName);

          return (
            <div key={stageName} style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: stageColors[stageName] || '#ffffff'  }}>
              <h3>{stageName}</h3>
              {stage ? (
                <div>
                  <p>Башталышы: {formatDate(stage.startedAt)}</p>
                  <p>Аягы: {formatDate(stage.completedAt)}</p>
                  <p><strong>Жооптуулар:</strong> {stage.assignedUsers.map(user => user.username).join(', ')}</p>
                </div>
              ) : (
                <p>Бул этапта маалымат жок.</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderDetails;

