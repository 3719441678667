import {useState,useEffect} from 'react';
import './../../css/manager.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetOrderByIdQuery, useUpdateOrderMutation } from '../../../redux/order/orderApi';
import { useOrderHelpers } from '../../../hooks/useOrderHelpers';
import { useSelector } from 'react-redux';

const Designer = () => {
    const { _id } = useParams(); 
    const { user } = useSelector((state) => state.auth);
    
    const navigate = useNavigate();
    const { data: order, error, isLoading } = useGetOrderByIdQuery(_id);
    const [updateOrder] = useUpdateOrderMutation();
    const [paymentForDesign,setPaymentForDesign] = useState(0);
    const [paymentForPrinter,setPaymentForPrinter] = useState(0);
    const [paymentForInstallation,setPaymentForInstallation] = useState(0);
    const [nextCurrentStage,setNextCurrentStage] = useState('');
    const [currentStage,setCurrentStage] = useState('');
    const [frames, setFrames] = useState(0);
    const [leuvers, setLeuvers] = useState(0);
    const [price,setPrice] = useState(0);
    const [size,setSize] = useState(0);
    const [height,setHeight] = useState(0);
    const [width,setWidth] = useState(0);
    const [rate, setRate] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [papers, setPapers] = useState([]);
    const [office, setOffice] = useState([]);

    const [isProcessingStarted, setIsProcessingStarted] = useState(false);
    const [isProcessingCompleted, setIsProcessingCompleted] = useState(false);
    const [isProcessingFinish, setIsProcessingFinish] = useState(false);
    // useEffect(() => {
    //     if (order?.info?.paymentForDesign) {
    //         setPaymentForDesign(order.info.paymentForDesign);
    //         setSize(order.info.dimensions.size);
    //         setRate(order.info.rate);
    //         setQuantity(order.info.quantity);
    //     }
    // }, [order?.info?.paymentForDesign]);
    // useEffect(() => {
    //     if (order?.info?.paymentForPrinter) {
    //         setPaymentForPrinter(order.info.paymentForPrinter);
    //         setSize(order.info.dimensions.size);
    //         setRate(order.info.rate);
    //         setQuantity(order.info.quantity);
    //     }
    // }, [order?.info?.paymentForPrinter]);
    // useEffect(() => {
    //     if (order?.info?.frames) {
    //         setFrames(order.info.frames);
    //     }
    //     if (order?.info?.leuvers) {
    //         setLeuvers(order.info.leuvers);
    //         setSize(order.info.dimensions.size);
    //         setRate(order.info.rate);
    //         setQuantity(order.info.quantity);
    //     }
    // }, [order?.info?.frames,order?.info?.leuvers]);
    // useEffect(() => {
    //     if (order?.info?.paymentForInstallation) {
    //         setPaymentForInstallation(order.info.paymentForInstallation);
    //         setSize(order.info.dimensions.size);
    //         setRate(order.info.rate);
    //         setQuantity(order.info.quantity);
    //     }
    // }, [order?.info?.paymentForInstallation]);
    useEffect(() => {
        if (!order?.info) return;
    
        const { 
            paymentForDesign, 
            paymentForPrinter, 
            paymentForInstallation, 
            frames, 
            leuvers, 
            dimensions, 
            rate, 
            quantity,
            papers 
        } = order.info;
    
        if (paymentForDesign) setPaymentForDesign(paymentForDesign);
        if (paymentForPrinter) setPaymentForPrinter(paymentForPrinter);
        if (paymentForInstallation) setPaymentForInstallation(paymentForInstallation);
    
        if (frames) setFrames(frames);
        if (leuvers) setLeuvers(leuvers);
    
        if (dimensions?.size){
            setSize(dimensions.size);
            setHeight(dimensions.height);
            setWidth(dimensions.width);
        }
        if (rate) setRate(rate);
        if (quantity) setQuantity(quantity);

        if (order?.office) setOffice(order?.office);
        if (papers) setPapers(papers);
        console.log('order?.info: ',order?.info)
    }, [order?.info,order?.office]);
    
    useEffect(() => {
        if (order?.currentStage) {
            const roles = {
                "Старт": "Дизайн",
                "Дизайн": "Печать",
                "Печать": "Постпечать",
                "Постпечать": "Установка",
                "Установка": "Финиш",
                "Финиш": "Старт"
            };
            console.log('order?.currentStage order.currentStage: ', order.currentStage);
            console.log('order?.currentStage roles[order.currentStage]: ', roles[order.currentStage]);
            setNextCurrentStage(roles[order.currentStage]  || '');
            setCurrentStage(order?.currentStage);
        }
    }, [order?.currentStage]); 

    // const calculatePrice = () => {
    //     const calculatedPrice =
    //         parseFloat(paymentForDesign || 0) +
    //         (parseFloat((size * rate).toFixed(3)) +  
    //         parseFloat(frames || 0) +
    //         parseFloat(leuvers || 0) +
    //         parseFloat(paymentForInstallation || 0)) *
    //         parseFloat(quantity || 1);
    //     setPrice(calculatedPrice);
    //     console.log('calculatedPrice: ' + calculatedPrice + ' paymentForDesign: ' + paymentForDesign + ' size * rate: ' + (size * rate) + ' frames: ' + frames + ' leuvers: ' + leuvers + ' paymentForInstallation: ' + paymentForInstallation);
    // };
    const calculatePrice = () => {
        let calculatedPrice = 0;
    
        if (order?.office) {
            const paperTotal = papers.reduce((sum, paper) => sum + (paper.total || 0), 0);
            calculatedPrice = paperTotal + parseFloat(paymentForDesign || 0);
            console.log('office: ',order?.office);
        }else{
            calculatedPrice = 
            parseFloat(paymentForDesign || 0) +
            (parseFloat(( ((height * width) * 0.0001).toFixed(3) * rate).toFixed(3)) +  
            parseFloat(frames || 0) +
            parseFloat(leuvers || 0) +
            parseFloat(paymentForInstallation || 0)) *
            parseFloat(quantity || 1);
            console.log('office: ',order?.office);
        }
    
        setPrice(calculatedPrice);
        console.log('calculatedPrice: ' + calculatedPrice); 
        console.log(' paymentForDesign: ' + paymentForDesign); 
        console.log(' size: ' + size); 
        console.log(' height: ' + height); 
        console.log(' width: ' + width); 
        console.log(' rate: ' + rate);
        console.log(' size * rate: ' + (((height * width) * 0.0001).toFixed(3) * rate)); 
        console.log(' quantity: ' + quantity);
        console.log(' frames: ' + frames);
        console.log(' leuvers: ' + leuvers); 
        console.log(' paymentForInstallation: ' + paymentForInstallation); 
        console.log(' paperTotal: ' + (office ? papers.reduce((sum, paper) => sum + (paper.total || 0), 0) : 0));

    };
    
    useEffect(() => {
        calculatePrice();
    }, [paymentForDesign, size, height, width, rate, frames, leuvers, paymentForInstallation, quantity]);

    const { handleUpdateOrderProperty, handleUpdateOrderProperties, handleUpdateOrderCurrentStageProperty } = useOrderHelpers();

    const isButtonEnabled = () => {
        const stageExists = !!order.stages.find((stage) => stage.stageName === order.currentStage);
        console.log('isButtonEnabled stageExists:', stageExists);
        return stageExists;
    };
    const handleUpdateOrderChunkProperty = async (orderId, updatedProperties) => {
        console.log('Updating properties:', updatedProperties);
        try {
            await updateOrder({ id: orderId, ...updatedProperties });
        } catch (error) {
            console.error("Error updating order:", error);
        }
        // window.location.reload();
      };
    const handleStarted = async (e) => {
        e.preventDefault();
        setIsProcessingStarted(true);
        try {
            if(order.currentStage === 'Дизайн'){
                // await handleUpdateOrderProperty(_id, 'info.paymentForDesign', parseFloat(paymentForDesign))
                
                await handleUpdateOrderChunkProperty(order._id, 
                    { 
                      'info.paymentForDesign': parseFloat(paymentForDesign), 
                      'info.price': price,
                     });
                     console.log('Дизайн ','paymentForDesign: ',paymentForDesign,'price: ',price)
            }else if(order.currentStage === 'Печать'){
                // await handleUpdateOrderProperty(_id, 'info.paymentForPrinter', parseFloat(paymentForPrinter))
                await handleUpdateOrderChunkProperty(order._id, 
                    { 
                      'info.paymentForPrinter': parseFloat(paymentForPrinter), 
                      'info.price': price,
                     });
            }else if(order.currentStage === 'Постпечать'){
                // await handleUpdateOrderProperty(_id, 'info.paymentForPostprinter', parseFloat(paymentForPostprinter))
                // await handleUpdateOrderProperties(_id,{'info.frames':frames,'info.leuvers':leuvers});
                await handleUpdateOrderChunkProperty(order._id, 
                    {
                        'info.frames':frames,
                        'info.leuvers':leuvers, 
                        'info.price': price,
                     });
            }else if (order.currentStage === 'Установка') {
                // await handleUpdateOrderProperty(_id, 'info.paymentForInstallation', parseFloat(paymentForInstallation));
                await handleUpdateOrderChunkProperty(order._id, 
                    { 
                      'info.paymentForInstallation': parseFloat(paymentForInstallation), 
                      'info.price': price,
                     });
            }

            await handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, user.id, order)
            window.location.reload();
        } catch (error) {
            console.error('Error updating order:', error);
        } finally {
            setIsProcessingStarted(false); // Reset loading state
        } 
    };
    const handleCompleted = async (e)=>{
        e.preventDefault();
        setIsProcessingCompleted(true);
        try{
            await Promise.all([
                handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, user.id, order),
                handleUpdateOrderProperty(_id, 'currentStage', nextCurrentStage)
            ]);

            navigate(`/orderStage/${currentStage}`, { replace: true });
        } catch (error) {
            console.error('Error completing stage:', error);
        } finally {
            setIsProcessingCompleted(false); // Reset loading state
        }
    };
    const handleFinished = async (e)=>{
        e.preventDefault();
        setIsProcessingFinish(true);
        try{
            await Promise.all([
                handleUpdateOrderCurrentStageProperty(order._id, 'currentStage', order.currentStage, user.id, order),
                handleUpdateOrderProperty(_id, 'currentStage', 'Финиш')
            ]);

            navigate(`/orderStage/${currentStage}`, { replace: true });
        } catch (error) {
            console.error('Error completing stage:', error);
        } finally {
            setIsProcessingFinish(false); // Reset loading state
        }
    };
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;
    return (
        <div className="table">        
        <>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1 label">клиент</div>
            <div className="span-1 label value">{order.customer}</div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1 label">аталышы</div>
            <div className="span-1 label value">{order.title}</div>
            <div className="span-3"></div>
        
            <div className="span-1"></div>
            <div className="span-1 label">телефон</div>
            <div className="span-1 label value">
                <a
                    href={`https://wa.me/${order.info.whatsapp}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-link"
                >
                    {order.info.whatsapp}
                </a>
            </div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1 label">материал</div>
            <div className="span-1 label value">
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    {order.info.materials.map((material) => (
                        <span key={material._id}>
                            {material.title}
                        </span>
                    ))}
                </span>
            </div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1 label">размер</div>
            <div className="span-1"></div>
            <div className="span-1 label">м2</div>
            <div className="span-2 label">Маалымат</div>

            <div className="span-1"></div>
            <div className="span-1 label value">{order.info.dimensions.height} cm</div>
            <div className="span-1 label value">{order.info.dimensions.width} cm</div>
            <div className="span-1 label value">{order.info.dimensions.size}</div>
            <div className="span-2 label value">{order.details}</div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1 label">Стадия</div>
            <div className="span-1 label value">{order.currentStage}</div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            </>

            
                    
                    {order.currentStage === 'Постпечать' ? (
                        <div className="span-1 label">Рейка</div>
                    ) : (
                        <div className="span-1 label">{order.currentStage}</div>
                    )}
                    <div className="span-1 label green-value">
                        <div className="payment-container">
                        { order.currentStage === 'Дизайн' && (
                            <>
                                <input name="paymentForDesign" 
                                value={paymentForDesign} 
                                onChange={(e)=>setPaymentForDesign(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        )} 
                        {order.currentStage === 'Печать' && (
                            <>
                                <input name="paymentForPrinter" 
                                value={paymentForPrinter} 
                                onChange={(e)=>setPaymentForPrinter(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        ) }
                        {order.currentStage === 'Постпечать' && (
                            <>
                                <input name="frames" 
                                value={frames} 
                                onChange={(e)=>setFrames(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        )}
                        {order.currentStage === 'Установка' && (
                            <>
                                <input name="paymentForInstallation" 
                                value={paymentForInstallation} 
                                onChange={(e)=>setPaymentForInstallation(e.target.value)} 
                                placeholder="500"></input>
                                <div>сом</div>
                            </>
                        )}
                        </div>
                    </div>
                    <div className="span-1"></div>
                    <div className="span-2 green-box">
                        <button 
                            onClick={handleStarted} 
                            className={`whatsapp-link `}
                            disabled={isButtonEnabled() || isProcessingStarted}
                            type="button"
                        >
                            {isProcessingStarted ? "Күтө туруңуз..." :  "Кабыл алдым"}
                        </button>
                    </div>
                    <div className="span-1"></div>



                    
                    {order.currentStage === 'Постпечать' ? (
                        <>
                            <div className="span-1 label">Люверси</div>
                            <div className="span-1 label green-value">
                                <div className="payment-container">
                                    <input
                                        name="leuvers"
                                        value={leuvers}
                                        onChange={(e) => setLeuvers(e.target.value)}
                                        placeholder="500"
                                    />
                                    <div>сом</div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                        <div className="span-1"></div>
                        <div className="span-1"></div>
                        </>
                    )}
                    <div className="span-1"></div>
                    <div className="span-2 green-box">
                        <button onClick={handleCompleted} 
                            className={`whatsapp-link `}
                            disabled={!isButtonEnabled() || isProcessingCompleted}
                            type="button"
                        >
                            {isProcessingCompleted ? "Күтө туруңуз..." :  "Аткарылды"}
                        </button>
                    </div>
                    <div className="span-1"></div>


                   
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>


                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-1"></div>
                    <div className="span-2 blue-box">
                        <button 
                            onClick={handleFinished}
                            className={`whatsapp-link `}
                            disabled={!isButtonEnabled() || isProcessingFinish}
                            type="button"
                        >
                            {isProcessingFinish ? "Күтө туруңуз..." :  "Финиш"}
                        </button>
                    </div>
                    <div className="span-1"></div>
                


            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
        </div>);
};

export default Designer;