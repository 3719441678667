import React, { useState, useEffect } from 'react';
import './css/createOrder.css'

const CreateOrder = () => {
    const [height, setHeight] = useState('');
    const [width, setWidth] = useState('');
    const [size, setSize] = useState(0);
  
    const calculateSize = (h, w) => {
      const heightInMeters = h / 100;
      const widthInMeters = w / 100;
      return (heightInMeters * widthInMeters).toFixed(2);
    };
  
    const handleHeightChange = (e) => {
      const newHeight = e.target.value;
      setHeight(newHeight);
      if (newHeight && width) {
        setSize(calculateSize(newHeight, width));
      } else {
        setSize(0);
      }
    };
  
    const handleWidthChange = (e) => {
      const newWidth = e.target.value;
      setWidth(newWidth);
      if (height && newWidth) {
        setSize(calculateSize(height, newWidth));
      } else {
        setSize(0);
      }
    };
    //м2*ставка+Дизайн + Печаттан кийин сумма(1. Рейка 2. Люверси 3. Установка)   

    return (
        <div className="container">
            <div className="form-container">
                <h2 className="mb30">Заказ киргизүү формасы</h2>
                <form onsubmit="preparePrint(); return false;">
                    <div className="form-grid">
                        <div className="form-group mb30">
                            <label for="title">Аталышы</label>
                            <input type="text" id="title" name="title" required></input>
                        </div>
                        <div className="form-group mb30">
                            <label for="material">Материал</label>
                            <select id="material" name="material" required>
                                <option value="Клиент 1">Материал 1</option>
                                <option value="Клиент 2">Материал 2</option>
                            </select>
                        </div>
                        <div className="form-group mb30">
                            <label for="whatsapp">Whatsapp номери</label>
                            <input type="number" id="whatsapp" name="whatsapp" required></input>
                        </div>

                        <div className="row-wrapper mb30">
                            <div className="form-group">
                                <label htmlFor="height">Узуну</label>
                                <div className="row-container">
                                <input
                                    type="number"
                                    id="height"
                                    name="height"
                                    required
                                    value={height}
                                    onChange={handleHeightChange}
                                />
                                <span>см</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="width">Туурасы</label>
                                <div className="row-container">
                                <input
                                    type="number"
                                    id="width"
                                    name="width"
                                    required
                                    value={width}
                                    onChange={handleWidthChange}
                                />
                                <span>см</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="size">Размер</label>
                                <div className="row-container">
                                <input
                                    type="number"
                                    id="size"
                                    disabled
                                    value={size}
                                />
                                <div className="equation">
                                    <span>
                                    м<span className="superscript">2</span>
                                    </span>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb30">
                            <label for="quantity">Саны</label>
                            <input type="number" id="quantity" name="quantity" value="1" required></input>
                        </div>

                        <div className="form-group mb30">
                            <label for="orderDetails">Маалымат:</label>
                            <textarea id="orderDetails" name="orderDetails" rows="2"></textarea>
                        </div>

                        <div className="form-group mb30">
                            <label for="rate">Ставка</label>
                            <div className="row-container">    
                                <input type="number" id="rate" name="rate" required></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="paymentForDesign">Дизайнердин акысы</label>
                            <div className="row-container"> 
                                <input type="number" id="paymentForDesign" name="paymentForDesign" required></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="priority">Приоритет</label>
                            <select id="priority" name="priority" required>
                                <option value="Обычный">Обычный</option>
                                <option value="Высокий">Высокий</option>
                            </select>
                        </div>


                        <div className="row-wrapper mb30">
                            <div className="form-group">
                                <label for="paymentAmount">Төлөм</label>
                                <div className="row-container"> 
                                    <input type="number" id="paymentAmount" name="paymentAmount" required></input>
                                    <span>сом</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label for="paymentMethod">Төлөм жолу</label>
                                <select id="paymentMethod" name="paymentMethod" required>
                                    <option value="акчалай">акчалай</option>
                                    <option value="мбанк">мбанк</option>
                                    <option value="перечисление">перечисление</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="customer">Клиент</label>
                            <select id="customer" name="customer" required>
                                <option value="Клиент 1">Клиент 1</option>
                                <option value="Клиент 2">Клиент 2</option>
                            </select>
                        </div>

                        <div className="form-group mb30">
                            <label for="customer">Жаңы клиент болсо</label>
                            <input type="text" id="customer" name="customer" required></input>
                        </div>


                        <div className="form-group mb30">
                            <label for="frames">Рейка</label>
                            <div className="row-container">    
                                <input type="number" id="frames" name="frames" required></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="leuvers">Люверси</label>
                            <div className="row-container"> 
                                <input type="number" id="leuvers" name="leuvers" required></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="installation">Установка</label>
                            <div className="row-container">    
                                <input type="number" id="installation" name="installation" required></input>
                                <span>сом</span>
                            </div>
                        </div>
                                                
                    </div>
                    <div className="form-grid-four">
                        <div className="form-group mb30">
                            <label for="afterPrint">Печаттан кийин</label>
                            <div className="row-container">    
                                <input type="text" id="afterPrint" disabled></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="prices">Жалпы баасы</label>
                            <div className="row-container"> 
                                <input type="text" id="prices" disabled></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="paid">Төлөндү</label>
                            <div className="row-container">    
                                <input type="text" id="paid" disabled></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label for="debt">Калдыгы</label>
                            <div className="row-container">    
                                <input type="text" id="debt" disabled></input>
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="button-container">
                            <button type="submit" className="button">Заказды сактоо</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateOrder;