// CreateOfisOrder.jsx
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetAllPapersQuery } from '../../redux/list/paper/paperApi';
import { useGetAllCustomersQuery } from '../../redux/list/customer/customerApi';
import './../css/createOrder.css';
import { Link } from 'react-router-dom';

const CreateOfisOrder = () => {
    const { data: papers = [] } = useGetAllPapersQuery();
    const { data: customers = [] } = useGetAllCustomersQuery();
    const { user } = useSelector((state) => state.auth);
    console.log('user.id: ',user.id)
    const navigate = useNavigate();
    
    const [title, setTitle] = useState('');
    const [details, setDetails] = useState('');
    const [whatsapp, setWhatsapp] = useState(0);
    const [who, setWho] = useState('');
    const [priority, setPriority] = useState("Обычный");
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [newCustomer, setNewCustomer] = useState('');
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('акчалай');

    const [selectedPapers, setSelectedPapers] = useState([]);
    const [selectedPaperPrices, setSelectedPaperPrices] = useState({});
    const [selectedPaperQuantities, setSelectedPaperQuantities] = useState({});
    const [paymentForDesign, setPaymentForDesign] = useState(0);
    
    
    const [price, setPrice] = useState(0);
    const [paid, setPaid] = useState(0);
    const [debt, setDebt] = useState(0);

    const [message, setMessage] = useState('');

    // Function to calculate the price based on selected papers and design payment
    const calculatePrice = () => {
        const sumOfTotals = selectedPapers.reduce((acc, paper) => {
            const quantity = Number(selectedPaperQuantities[paper.id] || 0);
            const price = Number(selectedPaperPrices[paper.id] || 0);
            return acc + (quantity * price);
        }, 0);
        const calculatedPrice = sumOfTotals + Number(paymentForDesign || 0);
        setPrice(calculatedPrice.toFixed(3));
    };

    // Update price when relevant fields change
    useEffect(() => {
        calculatePrice();
    }, [paymentForDesign, selectedPapers, selectedPaperPrices, selectedPaperQuantities]);

    // Function to calculate paid and debt
    const calculatePayment = () => {
        const calculatedPaid = parseFloat(paymentAmount || 0);
        const calculatedDebt = parseFloat(price || 0) - calculatedPaid;
        setPaid(calculatedPaid.toFixed(3));
        setDebt(calculatedDebt.toFixed(3));
    };

    // Update debt and paid when payment or price changes
    useEffect(() => {
        calculatePayment();
    }, [paymentAmount, price]);

    const handlePaperChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, (option) => {
            const paper = papers.find((ppr) => ppr._id === option.value);
            return paper ? { id: paper._id, title: paper.title } : null;
        }).filter((item) => item !== null); // Remove null values
        setSelectedPapers(selectedOptions);

        // Initialize prices for newly selected papers if not already set
        const newPrices = { ...selectedPaperPrices };
        selectedOptions.forEach((paper) => {
            if (!newPrices[paper.id]) {
                newPrices[paper.id] = ""; // Default empty price
            }
        });
        setSelectedPaperPrices(newPrices);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('selectedPapers: ',selectedPapers);
        console.log('selectedPaperPrices: ',selectedPaperPrices);
        console.log('selectedPaperQuantities: ',selectedPaperQuantities);

        const papers = selectedPapers.map(paper => {
            const quantity = Number(selectedPaperQuantities[paper.id]) || 0;
            const price = Number(selectedPaperPrices[paper.id]) || 0;
            return {
                paper: paper.id,
                price,
                quantity,
                total: quantity * price
            };
        });
    
        console.log('papers: ', papers);
        
    const sumOfTotals = papers.reduce((acc, paper) => acc + paper.total, 0);
    const designFee = Number(paymentForDesign) || 0;
    const totalAmount = sumOfTotals + designFee;

    console.log('sumOfTotals: ', sumOfTotals);
    console.log('paymentForDesign: ', designFee);
    console.log('price is totalAmount: ', totalAmount);
        const selectedPriority = priority || "Обычный";
        const now = new Date().toISOString(); // Current time in ISO format
        const completedAt = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString(); // 3 days from now
        const currentStage = 'Дизайн';
        const orderData = {
          details,
          title,
          customer: selectedCustomer ? selectedCustomer : newCustomer,
          status: true,
          office: true,
          currentStage,
          who,
          stages: [
          ],
          info:{
            papers: papers,
            payments: { amount: paymentAmount, method: paymentMethod },
            priority:selectedPriority,
            whatsapp,
            paymentForDesign,
            price: totalAmount,
            dimensions:{height:0,width:0,size:0},
          },
          createdBy: user.id
        };
    
        console.log('orderData: ',orderData);

        try {
          const response = await fetch('https://api.ilim-ordo.kg/api/order', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(orderData),
          });
    
          if (response.ok) {
            setMessage('Order created successfully!');
            navigate('/orders', { replace: true }); // Redirect when user is logged in, with replace option
          } else {
            const errorData = await response.json();
            setMessage(`Error: ${errorData.error || 'Something went wrong'}`);
          }
        } catch (err) {
          setMessage(`Error: ${err.message}`);
        }
    };
    const handlePriceChange = (id, value) => {
        setSelectedPaperPrices((prevPrices) => ({
            ...prevPrices,
            [id]: value,
        }));
    };
    const handleQuantityChange = (id, value) => {
        setSelectedPaperQuantities((prevPrices) => ({
            ...prevPrices,
            [id]: value,
        }));
    };
    return (
        <div className="container">
            <div className="form-container">
                <h2 className="mb30">Заказ киргизүү формасы</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-grid" style={{marginBottom:'30px'}}>

                        


                        <div className="form-group mb30">
                            <label htmlFor="title">Аталышы</label>
                            <input
                                type="text"
                                id="title"
                                name="title"                            
                                value={title} // Bind the state to the input value
                                onChange={(e)=>{setTitle(e.target.value);}} // Handle changes
                            />
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="details">Маалымат</label>
                            <input type="text" id="details" name="details" 
                                value={details} // Bind the state to the input value
                                onChange={(e)=>{setDetails(e.target.value);}} // Handle changes
                                ></input>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="priority">Приоритет</label>
                            <select id="priority" name="priority" 
                                    value={priority} 
                                    onChange={(e)=>setPriority(e.target.value || "Обычный")}>
                                <option value="Обычный">Обычный</option>
                                <option value="Срочный">Срочный</option>
                            </select>
                        </div>


                        

                        <div className="form-group mb30">
                            <label htmlFor="customer">Клиент</label>
                            <select 
                            id="customer"
                            value={selectedCustomer} 
                            onChange={(e)=>setSelectedCustomer(e.target.value)}                          
                            >
                                <option key="0" value="">Тандаңыз</option>
                                {customers.map(customer => (
                                    <option key={customer._id} value={customer.title}>
                                        {customer.title}
                                    </option>
                                ))}
                            </select>
                            <div style={{ marginTop: '20px' }} >
                                <label htmlFor="newCustomer">Жаңы клиент болсо</label>
                                <input type="text" id="newCustomer" name="newCustomer" 
                                    value={newCustomer} // Bind the state to the input value
                                    onChange={(e)=>{setNewCustomer(e.target.value);}} // Handle changes
                                ></input>
                            </div>
                        </div>

                        <div className="form-group  mb30">
                            <label htmlFor="paymentAmount">Төлөм</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="paymentAmount"
                                    name="paymentAmount"                                    
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                            <div style={{ marginTop: '20px' }} >
                                <label htmlFor="paymentMethod">Төлөө жолу</label>
                                <select
                                    id="paymentMethod" 
                                    name="paymentMethod"
                                    value={paymentMethod} 
                                    onChange={(e)=>setPaymentMethod(e.target.value)}     
                                >
                                    {/* <option key="0" value="">Тандаңыз</option> */}
                                    <option value="акчалай">акчалай</option>
                                    <option value="мбанк">мбанк</option>
                                    <option value="перечис.">перечис.</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="whatsapp">Whatsapp номери</label>
                            <input type="number" id="whatsapp" name="whatsapp" 
                                placeholder="0555111333"
                                value={whatsapp} // Bind the state to the input value
                                onChange={(e)=>{setWhatsapp(e.target.value);}} ></input>

                            <div style={{ marginTop: '20px' }} >
                                <label htmlFor="who">Ким заказ берди</label>
                                <input type="text" id="who" name="who" 
                                    value={who} // Bind the state to the input value
                                    onChange={(e)=>{setWho(e.target.value);}} // Handle changes
                                ></input>
                            </div>
                        </div>




                        <div className="form-group mb30">
                            <label htmlFor="papers">Кагаздар</label>
                            <select
                                id="papers"
                                value={selectedPapers.map((paper) => paper.id)}
                                onChange={handlePaperChange}
                                multiple
                                className="select-margin-paper"
                            >
                                {papers.map((paper) => (
                                    <option key={paper._id} value={paper._id}>
                                        {paper.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group mb30">
                            <div>
                                <h4 style={{ fontSize: "12px" }}>Тандалган кагаздар:</h4>
                                <ul id="selectedPapers">
                                    {selectedPapers.map((paper) => (
                                        <li key={paper.id} className="list-item-paper" style={{ fontSize: "14px",height:"40px" }}>
                                            {paper.title}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <h4 style={{ fontSize: "12px" }}>
                                Саны&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                X&nbsp;&nbsp;&nbsp;Баасы:</h4>
                            {selectedPapers.length > 0 && ( selectedPapers.map((paper) => {
                                const quantity = selectedPaperQuantities[paper.id] || 0;
                                const price = selectedPaperPrices[paper.id] || 0;
                                const total = quantity * price;
                                return (
                                <li key={paper.id} className="list-item-paper" style={{ fontSize: "10px",marginTop:"5px" }}>
                                    <input
                                        type="number"
                                        id={paper.id + paper.title}
                                        name="paperQuantity"
                                        value={selectedPaperQuantities[paper.id] || ""}
                                        className="paperQuantity"
                                        onChange={(e) => handleQuantityChange(paper.id, e.target.value)}
                                    />
                                    <span>X</span> 
                                    <input
                                        type="number"
                                        id={paper.id}
                                        name="paperPrice"
                                        value={selectedPaperPrices[paper.id] || ""}
                                        className="paperPrice"
                                        onChange={(e) => handlePriceChange(paper.id, e.target.value)}
                                    />
                                    <span>сом   =   <strong>{total} сом</strong></span> 
                                    
                                </li>);
                                })  
                            )}
                        </div>

                    </div>
                    <div className="form-grid-four">

                        <div className="form-group mb30">
                            <label htmlFor="paymentForDesign">Дизайнердин акысы</label>
                            <div className="row-container">
                                <input
                                    type="number"
                                    id="paymentForDesign"
                                    name="paymentForDesign"
                                    value={paymentForDesign}
                                    onChange={(e) => setPaymentForDesign(e.target.value)}
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="price">Жалпы баасы</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="price"
                                    disabled
                                    value={price}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="paid">Төлөндү</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="paid"
                                    disabled
                                    value={paid}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        <div className="form-group mb30">
                            <label htmlFor="debt">Калдыгы</label>
                            <div className="row-container">
                                <input
                                    type="text"
                                    id="debt"
                                    disabled
                                    value={debt}
                                    style={{ backgroundColor: 'lightyellow' }} 
                                />
                                <span>сом</span>
                            </div>
                        </div>
                        
                        <div className="button-container">
                            <button type="submit" className="button">
                                Заказды сактоо
                            </button>
                        </div>
                    </div>
                </form>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default CreateOfisOrder;

