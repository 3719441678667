// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
    display: flex;
    gap: 15px;
}

.filter-item {
    display: flex;
    flex-direction: column; /* Align label above input */
}
.mt-20{
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB,EAAE,4BAA4B;AACxD;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".filters {\n    display: flex;\n    gap: 15px;\n}\n\n.filter-item {\n    display: flex;\n    flex-direction: column; /* Align label above input */\n}\n.mt-20{\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
