// /src/components/order/Orders.jsx
import React, { useEffect, useState } from 'react';
import './../css/table.css';
import './../css/header.css';
import './../auth/auth.css';
import './../css/pagination.css';
import './../css/filter.css';
import { Link } from 'react-router-dom';
import { useDeleteOrderMutation } from '../../redux/order/orderApi';
import { useSelector } from 'react-redux';
import IndicatorSVG from '../custom/IndicatorSVG';

const Orders = () => {
    
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteOrder] = useDeleteOrderMutation();
    const [currentPage, setCurrentPage] = useState(1);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [currentStageFilter, setCurrentStageFilter] = useState('');
    const [showUrgentOnly, setShowUrgentOnly] = useState(false);
    const [whatsappFilter, setWhatsappFilter] = useState('');

    const ordersPerPage = 20;
    const { user } = useSelector((state) => state.auth);
    const hasManagerRole = user?.roles?.includes("ROLE_МЕНЕДЖЕР");
    const hasDesignerRole = user?.roles?.includes("ROLE_ДИЗАЙНЕР");
    const hasPrinterRole = user?.roles?.includes("ROLE_ПЕЧАТНИК");
    const hasPostprinterRole = user?.roles?.includes("ROLE_ПОСТПЕЧАТНИК");
    const hasInstallatorRole = user?.roles?.includes("ROLE_УСТАНОВЩИК");
    // Fetch orders from the API
    useEffect(() => {
        const fetchOrders = async () => {
            const roleStageMapping = {
                hasDesignerRole: "Старт",
                hasPrinterRole: "Дизайн",
                hasPostprinterRole: "Печать",
                hasInstallatorRole: "Постпечать",
            };

            try {
                let response;
                if (hasManagerRole) {
                    response = await fetch('https://api.ilim-ordo.kg/api/order?status=true');
                } else {
                    const stageName = Object.keys(roleStageMapping).find(
                        (role) => eval(role) // Dynamically check roles
                    );
                    if (stageName) {
                        response = await fetch(`https://api.ilim-ordo.kg/api/order?status=true&stageName=${roleStageMapping[stageName]}`);
                    }
                }

                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }

                const data = await response.json();
                console.log('data: ', data);
                setOrders(data);
                setFilteredOrders(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        console.log('useEffect triggered');
        fetchOrders();
    }, [hasManagerRole, hasDesignerRole, hasPrinterRole, hasPostprinterRole, hasInstallatorRole]);
    
    useEffect(() => {
        let filtered = orders;

        if (startDate && endDate) {
            filtered = filtered.filter(order => {
                const orderDate = new Date(order.createdAt).toISOString().split("T")[0];
                return orderDate >= startDate && orderDate <= endDate;
            });
        }

        if (currentStageFilter) {
            filtered = filtered.filter(order => order.currentStage === currentStageFilter);
        }

        if (showUrgentOnly) {
            filtered = filtered.filter(order => order.info.priority === 'Срочный');
        }
        if (whatsappFilter) {
            filtered = filtered.filter(order => 
                order.info.whatsapp && order.info.whatsapp.startsWith(whatsappFilter)
            );
        }
        setFilteredOrders(filtered);
        setCurrentPage(1); // Reset pagination when filtering
    }, [startDate, endDate, currentStageFilter, showUrgentOnly, whatsappFilter, orders]);

    if (loading) {
        return <IndicatorSVG style={{ width: 200, height: 128 }}/>; // Display loading state while fetching data
    }

    if (error) {
        return <div className="auth-container">Ката: {error}</div>;
    }
    const handleDelete = async (id) => {
        try {
            await deleteOrder(id).unwrap();
            //   console.log(`Order with id ${id} deleted successfully.`);
            window.location.reload();
        } catch (error) {
          console.error('Failed to delete the order:', error);
        }
      };
      const isLastStageCompleted = (order) => {
        if (order.stages && order.stages.length > 0) {
          return (
            order.stages[order.stages.length - 1].stageName === order.currentStage &&
            order.stages[order.stages.length - 1].completedAt
          );
        }
        return false; // Return false if stages array is empty or not present
      };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const totalPages = Math.ceil(filteredOrders.length / ordersPerPage);
    const indexOfLastOrder = currentPage * ordersPerPage;
    const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
    const currentOrders = filteredOrders.slice(indexOfFirstOrder, indexOfLastOrder);
    const renderPageNumbers = () => {
        const pageLimit = 5; // Number of visible numbered buttons
        let pages = [];
    
        if (totalPages <= pageLimit) {
            pages = Array.from({ length: totalPages }, (_, index) => index + 1);
        } else {
            if (currentPage <= 3) {
                pages = [1, 2, 3, 4, 5, 'dots-right', totalPages];
            } else if (currentPage >= totalPages - 2) {
                pages = [1, 'dots-left', totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
            } else {
                pages = [1, 'dots-left', currentPage - 1, currentPage, currentPage + 1, 'dots-right', totalPages];
            }
        }
    
        return pages.map((page) => 
            page === 'dots-left' || page === 'dots-right' ? (
                <span key={page} className="dots">...</span>
            ) : (
                <button
                    key={page}
                    className={currentPage === page ? 'activePage' : 'page'}
                    onClick={() => setCurrentPage(page)}
                >
                    {page}
                </button>
            )
        );
    };
    return (
        <div>
            <div className="header">
                <h2>Заказдар</h2>
                {hasManagerRole ? (
                    <>

<div className="filters">
                        <div className="filter-item">
                            <label>Баштоо күнү:</label>
                            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </div>

                        <div className="filter-item">
                            <label>Бүтүү күнү:</label>
                            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </div>

                        <div className="filter-item">
                            <label>Статус:</label>
                            <select value={currentStageFilter} onChange={(e) => setCurrentStageFilter(e.target.value)}>
                                <option value="">Бардыгы</option>
                                <option value="Дизайн">Дизайн</option>
                                <option value="Печать">Печать</option>
                                <option value="Постпечать">Постпечать</option>
                                <option value="Установка">Установка</option>
                                <option value="Финиш">Финиш</option>
                            </select>
                        </div>

                        <div className="filter-item">
                            <label>WhatsApp:</label>
                            <input 
                                type="text" 
                                placeholder="Телефон номер" 
                                value={whatsappFilter} 
                                onChange={(e) => setWhatsappFilter(e.target.value)} 
                            />
                        </div>

                        <div className="filter-item mt-20">
                            <label>
                                <input 
                                    type="checkbox" 
                                    checked={showUrgentOnly} 
                                    onChange={(e) => setShowUrgentOnly(e.target.checked)} 
                                />
                                Срочный
                            </label>
                        </div>

                        <button onClick={() => {
                            setStartDate('');
                            setEndDate('');
                            setCurrentStageFilter('');
                            setShowUrgentOnly(false);
                            setWhatsappFilter('');
                            setFilteredOrders(orders); // Reset to full list
                            setCurrentPage(1); // Reset pagination
                        }}>
                            Тазалоо
                        </button>
                    </div>

                    <Link to={'/customer'} className="create-btn blue-color">
                        Клиенттер
                    </Link>

                    <Link to={'/material'} className="create-btn blue-color">
                        Материалдар
                    </Link>

                    <Link to={'/user'} className="create-btn blue-color">
                        Колдонуучулар
                    </Link>

                    <Link to={'/createOrder'} className="create-btn">
                        Жаңы заказ 
                    </Link>
                    
                </>
                ) : (
                    <></>
                )}
            </div>
            {filteredOrders.length === 0 ? (
                <p>Заказдар жүктөлө элек.</p>
            ) : (
                <>
                <span>({filteredOrders.length}) - {currentPage} / {totalPages}</span>
                <table className="auth-form">
                    <thead>
                        <tr>
                            <th>Номери</th>
                            <th></th>
                            {hasManagerRole ? (
                            <th>Төлөмдөр</th>
                            ) : ''}
                            <th>Клиент</th>
                            <th>Стадия</th>
                            <th>Материалдар</th>
                            <th>Размер</th>
                            <th></th>
                            <th></th>
                            <th>Ким бекитти</th>
                            <th>Датасы</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentOrders.map((order,index) => (
                            <tr key={order._id || index}>
                                <td>
                                    {order.currentStage === 'Финиш' ? (
                                        order.orderNo
                                    ) : (
                                        <Link to={'/designer/' + order._id} className="submit-button">
                                            {order.orderNo}
                                        </Link>
                                    )}
                                </td>
                                <td style={{ color: order.info.priority === 'Срочный' ? 'red' : 'inherit',maxWidth:'300px' }}>
                                    Аталышы: {order.title}<br></br>
                                    Маалымат: {order.details}
                                </td>
                                {hasManagerRole ? (
                                <td>
                                    Баасы: {order.info.price} сом<br></br>
                                    {order.info.payments
                                        ? order.info.payments.map((payment, index) => (
                                              <div key={payment.id || index}>
                                                  {payment.amount} сом ({payment.method})
                                              </div>
                                          ))
                                        : 'Төлөм жок'}
                                    <strong>Карызы:</strong> {Number(order.info.price - (order.info.payments.reduce((sum, payment) => sum + payment.amount, 0))).toFixed(0)}  сом
                                </td>)
                                : '' }
                                <td>{order.customer}<br></br>
                                    <a
                                        href={`https://wa.me/${order.info.whatsapp}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="whatsapp-link"
                                    >
                                        {order.info.whatsapp}
                                    </a>
                                </td>
                                <td >
                                    {order.currentStage}<br></br>
                                    {order.currentStage !== 'Финиш' && (
                                        <div style={{ marginLeft: '20px', fontStyle: 'italic', fontSize: '12px' }}>
                                            {isLastStageCompleted(order) ? 'Аткарылды' : 'Бүтө элек'}
                                        </div>
                                    )}
                                    {/* <div style={{marginLeft:'20px',fontStyle:'italic',fontSize:'12px'}}>{isLastStageCompleted(order) ? 'Аткарылды' : !isLastStageCompleted(order) ? 'Бүтө элек' : 'Баштала элек'}</div> */}
                                    <ul>
                                        {order.stages.slice().reverse().map((stage) => (
                                        <li key={stage._id}>
                                            {stage.stageName} &nbsp;
                                            (&nbsp;
                                                {stage.assignedUsers.slice().reverse().map(user => user.username).join(', ')}
                                            &nbsp;)
                                        </li>
                                        ))}
                                    </ul>
                                </td>
                                <td>
                                    {order.info.materials.map((material,index) => (                                        
                                            <div key={material.id || index}>
                                                {material.title} 
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>{order.info.dimensions.height} см x {order.info.dimensions.width} см = {Number(order.info.dimensions.size).toFixed(3)} м2</td>
                                {hasManagerRole ? (
                                    <>
                                    <td>
                                        Саны: {order.info.quantity ?? 1} даана<br></br>
                                        Ставка: {order.info.rate} сом<br></br>
                                        Баасы: {order.info.price} сом
                                    </td>
                                    <td>
                                    {order.info.paymentForPrinter > 0 && (
                                        <>
                                            Печатник: {order.info.paymentForPrinter} сом<br></br>
                                        </>
                                    )}
                                    {order.info.paymentForDesign > 0 && (
                                        <>
                                            Диз. акысы: {order.info.paymentForDesign} сом<br></br>
                                        </>
                                    )}
                                    {order.info.frames > 0 && (
                                        <>
                                            Рейка: {order.info.frames} сом <br></br>
                                        </>
                                    )}
                                    {order.info.leuvers > 0 && (
                                        <>
                                            Люверси: {order.info.leuvers} сом <br></br>
                                        </>
                                    )}    
                                    {order.info.paymentForInstallation > 0 && (
                                        <>
                                            Установка: {order.info.paymentForInstallation} сом
                                        </>
                                    )}                                           
                                    </td>
                                    </>
                                ) : (
                                    <></>
                                )}
                                <td>{order.createdBy?.username || 'Белгисиз'}</td>
                                {/* <td>{new Date(order.createdAt).toLocaleDateString()}</td> */}
                                <td>{formatDate(order.createdAt)}</td>
                                {hasManagerRole ? (
                                <td >
                                    <Link to={'/update/' + order._id} className="submit-button">                                    
                                        Өзгөртүү
                                    </Link>
                                    <br></br>
                                    <br></br>
                                    <button onClick={() => handleDelete(order._id)}>Өчүрүү</button>
                                </td>
                                ) : (
                                    <></>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="pagination">
                    <button className='page' onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>
                        Биринчи
                    </button>
                        <button className='page' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Мурдагы
                        </button>
                        {renderPageNumbers()}
                        <button className='page' onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Кийинки
                        </button>
                        <button className='page' onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages}>
                            Акыркы
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Orders;
