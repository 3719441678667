// /src/components/order/OrderList.jsx
import React, { useEffect, useState } from 'react';
import './../css/table.css';
import './../css/header.css';
import './../auth/auth.css';
import { Link } from 'react-router-dom';

const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch orders from the API
    useEffect(() => {
        const fetchOrders = async () => {
            try {
                const response = await fetch('https://api.ilim-ordo.kg/api/order?status=true'); // Replace with your API endpoint
                if (!response.ok) {
                    throw new Error('Failed to fetch orders');
                }
                const data = await response.json();
                setOrders(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrders();
    }, []);

    if (loading) {
        return <div className="auth-container">Заказдар жүктөлүп жатат...</div>;
    }

    if (error) {
        return <div className="auth-container">Ката: {error}</div>;
    }

    return (
        <div>
            <div className="header">
                <h2>Заказдар</h2>
            </div>

            {orders.length === 0 ? (
                <p>Заказдар жүктөлө элек.</p>
            ) : (
                <table className="auth-form">
                    <thead>
                        <tr>
                            <th>Номери</th>
                            <th></th>
                            <th>Клиент</th>
                            <th>Стадия</th>
                            <th>Материалдар</th>
                            <th>Размер</th>
                            <th>Ким бекитти</th>
                            <th>Датасы</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((order,index) => (
                            <tr key={order._id || index}>
                                <td>
                                    <Link to={'/designer/' + order._id} className="submit-button">                                    
                                        {order.orderNo}
                                    </Link>
                                </td>
                                <td>
                                    Аталышы: {order.title}<br></br>
                                    ({order.info.priority})<br></br>
                                    Маалымат: {order.details}
                                </td>
                                <td>{order.customer}<br></br>
                                    <a
                                        href={`https://wa.me/${order.info.whatsapp}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="whatsapp-link"
                                    >
                                        {order.info.whatsapp}
                                    </a>
                                </td>
                                <td>{order.currentStage}</td>
                                <td>
                                    {order.info.materials.map((material,index) => (                                        
                                            <div key={material.id || index}>
                                                {material.title} 
                                            </div>
                                        ))
                                    }
                                </td>
                                <td>{order.info.dimensions.height} см x {order.info.dimensions.width} см = {order.info.dimensions.size} м2</td>
                              
                                <td>{order.createdBy?.username || 'Белгисиз'}</td>
                                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                                {/* <td >
                                    <Link to={'/update/' + order._id} className="submit-button">                                    
                                        Өзгөртүү
                                    </Link>
                                    <br></br>
                                    <br></br>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default OrderList;
