// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .header {
    display: flex;
    align-items: center; /* Align items vertically in the center */
    justify-content: space-between; /* Add space between h1 and a */
    margin-bottom: 16px; /* Optional: Add spacing below the header */
  }
  
  h1 {
    margin: 0; /* Remove default margin for better alignment */
  }
  
  .create-btn {
    text-decoration: none; /* Remove underline from the link */
    background-color: #0bb22d; /* Optional: Add a button-like background */
    color: white; /* Optional: Button text color */
    padding: 8px 12px; /* Optional: Add padding to make it look like a button */
    border-radius: 4px; /* Optional: Rounded corners for the button */
    font-size: 16px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/header.css"],"names":[],"mappings":"EAAE;IACE,aAAa;IACb,mBAAmB,EAAE,yCAAyC;IAC9D,8BAA8B,EAAE,+BAA+B;IAC/D,mBAAmB,EAAE,2CAA2C;EAClE;;EAEA;IACE,SAAS,EAAE,+CAA+C;EAC5D;;EAEA;IACE,qBAAqB,EAAE,mCAAmC;IAC1D,yBAAyB,EAAE,2CAA2C;IACtE,YAAY,EAAE,gCAAgC;IAC9C,iBAAiB,EAAE,wDAAwD;IAC3E,kBAAkB,EAAE,6CAA6C;IACjE,eAAe;EACjB","sourcesContent":["  .header {\n    display: flex;\n    align-items: center; /* Align items vertically in the center */\n    justify-content: space-between; /* Add space between h1 and a */\n    margin-bottom: 16px; /* Optional: Add spacing below the header */\n  }\n  \n  h1 {\n    margin: 0; /* Remove default margin for better alignment */\n  }\n  \n  .create-btn {\n    text-decoration: none; /* Remove underline from the link */\n    background-color: #0bb22d; /* Optional: Add a button-like background */\n    color: white; /* Optional: Button text color */\n    padding: 8px 12px; /* Optional: Add padding to make it look like a button */\n    border-radius: 4px; /* Optional: Rounded corners for the button */\n    font-size: 16px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
