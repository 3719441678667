import { configureStore } from '@reduxjs/toolkit';
import { authApi } from './auth/authApi';
import { orderApi } from './order/orderApi';
import { materialApi } from './list/material/materialApi';
import { roleApi } from './list/role/roleApi';
import { userApi } from './list/user/userApi';
import { customerApi } from './list/customer/customerApi';
import authReducer from './auth/authSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer, // Add auth slice
    [orderApi.reducerPath]: orderApi.reducer,
    [materialApi.reducerPath]: materialApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [roleApi.reducerPath]: roleApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      userApi.middleware,
      roleApi.middleware,
      authApi.middleware,
      orderApi.middleware,
      materialApi.middleware,
      customerApi.middleware),
});
