// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2{margin-bottom: 0;}
h4{color: green;margin-bottom: 0;}
ul{padding-left: 0;margin-top: 0;}
.select-height-200{height: 200px;}`, "",{"version":3,"sources":["webpack://./src/style.css"],"names":[],"mappings":"AAAA,GAAG,gBAAgB,CAAC;AACpB,GAAG,YAAY,CAAC,gBAAgB,CAAC;AACjC,GAAG,eAAe,CAAC,aAAa,CAAC;AACjC,mBAAmB,aAAa,CAAC","sourcesContent":["h2{margin-bottom: 0;}\nh4{color: green;margin-bottom: 0;}\nul{padding-left: 0;margin-top: 0;}\n.select-height-200{height: 200px;}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
