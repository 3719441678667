import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MonthFilter from '../custom/Monthfilter';

const DesignerSalary = () => {
    const [selectedMonth, setSelectedMonth] = useState(new Date());
    const [designersData, setDesignersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const handleMonthChange = (monthValue) => {
        const [year, month] = monthValue.split('-');
        const newDate = new Date(year, month - 1, 1); // Convert to Date object
        setSelectedMonth(newDate);
      };

  useEffect(() => {
    const fetchDesignerData = async () => {
      setLoading(true);
      setError(null);

      try {
        const getMonthStartEnd = (date) => {
          const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
          const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          return { startOfMonth, endOfMonth };
        };

        const { startOfMonth, endOfMonth } = getMonthStartEnd(selectedMonth);

        const response = await fetch('https://api.ilim-ordo.kg/api/order');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const orders = await response.json();

        // Filter orders by the selected month
        const filteredOrders = orders.filter(order => {
          const orderDate = new Date(order.createdAt);
          return orderDate >= startOfMonth && orderDate <= endOfMonth;
        });

        const designersMap = new Map();

        filteredOrders.forEach(order => {
          order.stages.forEach(stage => {
            if (stage.assignedUsers && stage.assignedUsers.length > 0) {
              stage.assignedUsers.forEach(user => {  // user is already the populated user object
                const userIdStr = user._id.toString(); // Use user._id.toString()

                if (!designersMap.has(userIdStr)) {
                  designersMap.set(userIdStr, {
                    userId: userIdStr,
                    username: user.username, // Get username directly from user
                    ordersOffice: 0,
                    ordersPoligraph: 0,
                    paymentForDesignOffice: 0,
                    paymentForDesignPoligraph: 0,
                    totalSize: 0,
                    totalPrice: 0,
                    allOrders: []
                  });
                }
                designersMap.get(userIdStr).allOrders.push(order);
              });
            }
          });
        });

        // Convert map to array for easier processing
        const designerDataArray = Array.from(designersMap.values());

        // Data Aggregation
        const updatedDesignerData = designerDataArray.map(designer => {
          let ordersOffice = 0;
          let ordersPoligraph = 0;
          let paymentForDesignOffice = 0;
          let paymentForDesignPoligraph = 0;
          let totalSize = 0;
          let totalPrice = 0;

          designer.allOrders.forEach(order => {
            if (order.office) {
              ordersOffice++;
              paymentForDesignOffice += order.info.paymentForDesign || 0;
            } else {
              ordersPoligraph++;
              paymentForDesignPoligraph += order.info.paymentForDesign || 0;
            }
            totalSize += Number(order.info.dimensions.size) || 0;
            totalPrice += Number(order.info.price) || 0;
          });
          console.log('totalPrice: ',totalPrice);
          return {
            ...designer,
            ordersOffice: ordersOffice,
            ordersPoligraph: ordersPoligraph,
            paymentForDesignOffice: paymentForDesignOffice,
            paymentForDesignPoligraph: paymentForDesignPoligraph,
            totalSize: totalSize,
            totalPrice: totalPrice
          };
        });
        setDesignersData(updatedDesignerData);
      } catch (err) {
        setError(err);
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchDesignerData();
  }, [selectedMonth]);

  const calculateValues = (designer) => {
    const divisionResult = designer.totalPrice / 3;
    console.log('divisionResult: ',divisionResult);
    const sizeMultiplication = designer.totalSize * 50;
    const totalSum = designer.paymentForDesignOffice + designer.paymentForDesignPoligraph + sizeMultiplication;

    return {
      divisionResult,
      sizeMultiplication,
      totalSum,
    };
  };

  if (loading) {
    return <p>Loading designer data...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
        <div style={{textAlign:'center'}}>
            <h2 style={{display: 'inline-block', marginRight: '100px'}}>Дизайнеры Зарплаты</h2>
            {/* <label style={{display: 'inline-block'}}>Выберите месяц:</label> */}
            <span style={{display: 'inline-block'}}>
            <MonthFilter
                value={`${selectedMonth.getFullYear()}-${String(selectedMonth.getMonth() + 1).padStart(2, '0')}`}
                onChange={handleMonthChange}
                />
            </span>
            {/* <DatePicker
            selected={selectedMonth}
            onChange={(date) => setSelectedMonth(date)}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            /> */}
        </div>

      {designersData.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Имя пользователя</th>
              <th>Заказы (Полиграфия)</th>
              <th>Заказы (Офис)</th>
              <th>Оплата за дизайн (Полиграфия)</th>
              <th>Оплата за дизайн (Офис)</th>
              <th>Оплата за дизайн (Офис) / 3</th>
              <th>Размер</th>
              <th>Бонус (Размер * 50)</th>
              <th>Итого</th>
            </tr>
          </thead>
          <tbody>
            {designersData.map((designer) => {
              const { divisionResult, sizeMultiplication, totalSum } = calculateValues(designer);

              return (
                <tr key={designer.userId}>
                  <td>{designer.username}</td>
                  <td>{designer.ordersPoligraph}</td>
                  <td>{designer.ordersOffice}</td>
                  <td>{Number(designer.paymentForDesignPoligraph).toFixed(2)} сом</td>
                  <td>{Number(designer.paymentForDesignOffice).toFixed(2)} сом</td>
                  <td>{Number(divisionResult).toFixed(2)} сом</td>
                  <td>{Number(designer.totalSize).toFixed(3)} m2</td>
                  <td>{Number(sizeMultiplication).toFixed(2)} сом</td>
                  <td>{totalSum} сом</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>Нет данных за выбранный месяц.</p>
      )}
    </div>
  );
};

export default DesignerSalary;