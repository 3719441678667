// /redux/order/orderApi.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const orderApi = createApi({
  reducerPath: 'orderApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.ilim-ordo.kg/' }), // Your API base URL
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => 'api/order',
    }),
    getOrderById: builder.query({
      query: (id) => `api/order/${id}`, // Fetch order by ID
    }),
    getAllOrdersByStatus: builder.query({
      query: () => 'api/order?status=true',
    }),
    addMaterialToOrder: builder.mutation({
      query: ({ orderId, materialId }) => ({
        url: `api/orders/${orderId}/materials`,
        method: 'POST',
        body: { materialId },
      }),
    }),
    addPaymentToOrder: builder.mutation({
      query: ({ orderId, amount, method }) => ({
        url: `api/orders/${orderId}/payments`,
        method: 'POST',
        body: { amount, method },
      }),
    }),
    deleteStageFromOrder: builder.mutation({
      query: ({ orderId, stageId }) => ({
        url: `api/orders/${orderId}/stages/${stageId}`,
        method: 'DELETE',
      }),
    }),
    deletePaymentFromOrder: builder.mutation({
      query: ({ orderId, paymentId }) => ({
        url: `api/orders/${orderId}/payments/${paymentId}`,
        method: 'DELETE',
      }),
    }),
    deleteMaterialFromOrder: builder.mutation({
      query: ({ orderId, materialId }) => ({
        url: `api/orders/${orderId}/materials/${materialId}`,
        method: 'DELETE',
      }),
    }),
    updateOrder: builder.mutation({
      query: ({ id, ...updatedFields }) => ({
        url: `api/order/${id}`,
        method: 'PUT',
        body: updatedFields, // Dynamically passed fields
      }),
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `api/order/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const { 
  useGetAllOrdersQuery, 
  useGetOrderByIdQuery, 
  useGetAllOrdersByStatusQuery,
  useAddMaterialToOrderMutation,
  useAddPaymentToOrderMutation,
  useDeleteStageFromOrderMutation,
  useDeletePaymentFromOrderMutation,
  useDeleteMaterialFromOrderMutation,
  useUpdateOrderMutation,
  useDeleteOrderMutation,
} = orderApi;
