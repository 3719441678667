import React, { useMemo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './../css/header.css';

const MonthlyOrderTotals = () => {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch('https://api.ilim-ordo.kg/api/order');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setOrders(data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setOrders([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const monthlyData = useMemo(() => {
    const totals = {};

    orders.forEach(order => {
      const date = new Date(order.createdAt);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const key = `${year}-${month.toString().padStart(2, '0')}`;

      if (!totals[key]) {
        totals[key] = {
          count: 0,
          totalPrice: 0,
          officeTotal: 0,
          poligraphTotal: 0,
          акчалай: 0,
          мбанк: 0,
          'перечис.': 0,
          paymentForDesignTotal: 0,
          карыз: 0 
        };
      }

      totals[key].count += 1;
      totals[key].totalPrice += order.info?.price || 0;
        if (order.office) {
            totals[key].officeTotal += 1;
        } else {
            totals[key].poligraphTotal += 1;
        }
        order.info?.payments?.forEach(payment => {
            switch(payment.method) {
                case 'акчалай':
                    totals[key].акчалай += payment.amount;
                    break;
                case 'мбанк':
                    totals[key].мбанк += payment.amount;
                    break;
                case 'перечис.':
                    totals[key]['перечис.'] += payment.amount;
                    break;
                default:
                    break;
            }
        });

        const paymentsTotal = order.info?.payments?.reduce((sum, payment) => sum + payment.amount, 0) || 0;
        // console.log('paymentsTotal: ',paymentsTotal);
        totals[key].карыз += (order.info?.price || 0) - paymentsTotal;
      totals[key].paymentForDesignTotal += order.info?.paymentForDesign || 0;
    });

    return totals;
  }, [orders]);

  const sortedMonths = useMemo(() => {
    return Object.keys(monthlyData).sort((a, b) => {
      const [yearA, monthA] = a.split('-').map(Number);
      const [yearB, monthB] = b.split('-').map(Number);
      return yearB - yearA || monthB - monthA;
    });
  }, [monthlyData]);

  const formatMonthKey = (key) => {
    const [year, month] = key.split('-');
    const date = new Date(year, month - 1);

    const monthName = date.toLocaleDateString('ky-KG', { month: 'long' });
    const yearValue = date.toLocaleDateString('ky-KG', { year: 'numeric' });

    return `${monthName}, ${yearValue}`;
  };

  if (isLoading) {
    return <div className="p-4">Loading orders...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">Error: {error}</div>;
  }

  return (
    <div>
                          <div className="header">
                              
                              <Link to={'/salaryList'} className="create-btn blue-color">
                                  Колдонуучулар 2
                              </Link>

                              <Link to={'/perechis'} className="create-btn blue-color">
                                  Перечисления
                              </Link>

                              <Link to={'/salaryDesigner'} className="create-btn blue-color">
                                  Дизайнерлер
                              </Link>

                          </div>
    
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Айлык Статистика</h2>
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 border text-left">Ай</th>
              <th className="p-2 border text-left">З | О | П</th>

              <th className="p-2 border text-left">Жалпы</th>
              <th className="p-2 border text-left">акчалай</th>
                <th className="p-2 border text-left">мбанк</th>
                <th className="p-2 border text-left">перечис.</th>
              <th className="p-2 border text-left">Карыз</th>
              <th className="p-2 border text-left">Дизайнерге</th>
            </tr>
          </thead>
          <tbody>
            {sortedMonths.map(monthKey => (
              <tr key={monthKey} className="hover:bg-gray-50">
                <td className="p-2 border">{formatMonthKey(monthKey)}</td>
                <td className="p-2 border">{monthlyData[monthKey].count} | {monthlyData[monthKey].officeTotal} | {monthlyData[monthKey].poligraphTotal}</td>
                <td className="p-2 border">
                  {monthlyData[monthKey].totalPrice.toLocaleString()} сом
                </td>
                <td className="p-2 border">{monthlyData[monthKey].акчалай.toLocaleString()} сом</td>
                <td className="p-2 border">{monthlyData[monthKey].мбанк.toLocaleString()} сом</td>
                <td className="p-2 border">{monthlyData[monthKey]['перечис.'].toLocaleString()} сом</td>
                <td className="p-2 border" style={{'color':'red'}}>{monthlyData[monthKey].карыз.toLocaleString()} сом</td>
                <td className="p-2 border">
                  {monthlyData[monthKey].paymentForDesignTotal.toLocaleString()} сом
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    </div>
  );
};

export default MonthlyOrderTotals;