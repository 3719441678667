import React, { useState, useEffect } from 'react';

function MonthFilter({ value, onChange }) {
  const monthNames = [
    'янв', 'фев', 'мар', 'апр', 'май', 'июн',
    'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'
  ];

  const [pendingMonth, setPendingMonth] = useState(value);

  useEffect(() => {
    setPendingMonth(value);
  }, [value]);

  const formatDisplay = (dateString) => {
    if (!dateString) return '';
    const [year, month] = dateString.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    return `${monthNames[monthIndex]} - ${year}`;
  };

  const getNextMonth = (dateString) => {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 1);
    return formatDate(date);
  };

  const getPreviousMonth = (dateString) => {
    const date = new Date(dateString);
    date.setMonth(date.getMonth() - 1);
    return formatDate(date);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  const handleIncrement = () => {
    const nextMonth = getNextMonth(pendingMonth);
    setPendingMonth(nextMonth);
  };

  const handleDecrement = () => {
    const prevMonth = getPreviousMonth(pendingMonth);
    setPendingMonth(prevMonth);
  };

  const handleFilter = () => {
    onChange(pendingMonth);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <button onClick={handleDecrement}>Мурдагы</button>
      <div style={{
        minWidth: '100px',
        textAlign: 'center',
        padding: '4px 8px',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}>
        {formatDisplay(pendingMonth)}
      </div>
      <button onClick={handleIncrement}>Кийинки</button>
      <button 
        onClick={handleFilter}
        style={{
          backgroundColor: '#4CAF50',
          color: 'white',
          padding: '4px 12px',
          borderRadius: '4px',
          marginLeft: '8px'
        }}
      >
        Фильтр
      </button>
    </div>
  );
}

export default MonthFilter;