import React from 'react';
import { useGetAllOrdersQuery } from '../redux/order/orderApi';

const formatDate = (date) => {
  if (!date) return 'Бүтө элек';
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, '0');
  const minutes = String(d.getMinutes()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const stageColors = {
  'Дизайн': '#ffcccc',       // Light red for Design
  'Печать': '#ccffcc',       // Light green for Print
  'Подготовка': '#ccccff',   // Light blue for PostProcessing
  'Установка': '#ffcc99',    // Light orange for Installation
};

const StagedOrderList = () => {
  const { data: orders, error, isLoading } = useGetAllOrdersQuery();

  if (isLoading) return <p>Жүктөлүп жатат...</p>;
  if (error) return <p>Ката чыкты</p>;

  // Sorting orders by currentStage
  const stages = ['Дизайн', 'Печать', 'Подготовка', 'Установка'];

  const sortedOrders = stages.map(stage => ({
    stage,
    orders: orders.filter(order => order.currentStage === stage)
  }));

  return (
    <div>
      <h1>Буюртманын этаптары</h1>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px' }}>
        {sortedOrders.map(({ stage, orders }) => (
          <div key={stage} style={{ border: '1px solid #ccc', padding: '10px', backgroundColor: stageColors[stage] || '#ffffff' }}>
            <h2>{stage}</h2>
            <div>
              {orders.length > 0 ? (
                orders.map(order => (
                  <div key={order._id} style={{ border: '1px solid #eee', padding: '10px', margin: '10px 0' }}>
                    <p><strong>Номери:</strong> {order.orderNumber}</p>
                    <p><strong>Буюртма:</strong> {order.orderDetails}</p>
                    <div>
                      {order.stages.map(stage => (
                        order.currentStage === stage.stageName ? (
                        <div key={stage.stageName}>
                          <p>Башталышы: {formatDate(stage.startedAt)}</p>
                          <p>Аягы: {formatDate(stage.completedAt)}</p>
                          <p><strong>Жооптуулар:</strong> {
                            stage.assignedUsers.map(user => user.username).join(', ')
                          }</p>
                        </div>
                        ) : ''
                      ))}
                    </div>
                  </div>
                ))
              ) : (
                <p>Бул этапта буюртма жок.</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StagedOrderList;