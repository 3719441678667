import React from 'react';
import './order.css';

function Printer() {
  return (
    <div className="order-summary">
      <div className="order-header">
        <div>Заказчик</div>
        <div>телефон клиента</div>
        <div>тип материала</div>
      </div>
      <div className="order-details">
        <div className="size-table">
          <div>размеры</div>
          <div>m2</div>
          <div>описания заказа</div>
        </div>
        <div className="order-info">
          <div>Ставка</div>
          <div>Способ оплаты: 1. наличными 2 если перечисление</div>
          <div>Сумма</div>
          <div className="highlighted-red">Дизайн 500</div>
        </div>
      </div>
      <div className="status-green">Аткарылды</div>
    </div>
  );
}

export default Printer;
