import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const Salary = () => {
    const [orders,setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [searchParams] = useSearchParams();
    const stageName = searchParams.get("stageName");
    const userId = searchParams.get("userId");
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [materials, setMaterials] = useState([]);
    const [officeFilter, setOfficeFilter] = useState(false);
    const [poligraphFilter, setPoligraphFilter] = useState(false);

    // Fetch materials on component mount
    useEffect(() => {
        const fetchMaterials = async () => {
            try {
                const response = await fetch('https://api.ilim-ordo.kg/api/materials');
                if (response.ok) {
                    const materialsData = await response.json();
                    setMaterials(materialsData);
                }
            } catch (error) {
                console.error('Error fetching materials:', error);
            }
        };
        fetchMaterials();
    }, []);

    useEffect(() => {
    const fetchOrdersAndUser = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://api.ilim-ordo.kg/api/orders?userId=${userId}&stageName=${stageName}`
        );
        if (!response.ok) {
            // Check for 404 status, but it's *not* an error in our case - it is business as usual.
            if (response.status === 404) {
                // Backend said there were no orders, so let's handle it.
                setOrders([]); // Set to an empty array, to display "No orders" message
            } else {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        }else{
            const currentOrdersData = await response.json();
            setOrders(currentOrdersData);
        }
        const userResponse = await fetch(
            `https://api.ilim-ordo.kg/api/users/${userId}`
          );
  
          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUser(userData);
          } else {
            setUser(null);
          }
      } catch (err) {
        setError("Failed to fetch orders");
      } finally {
        setLoading(false);
      }
    };

    if (userId && stageName) {
        fetchOrdersAndUser();
    }
    }, [userId, stageName]);

    if (loading) {
        return <div>Loading orders...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }
    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
    };
    const filteredOrders = orders.filter(order => {
        const orderDate = new Date(order.createdAt).toISOString().split('T')[0];
        const dateFilter = startDate ? orderDate >= startDate : true;
        const endFilter = endDate ? orderDate <= endDate : true;
        const materialFilter = selectedMaterial 
            ? order.info.materials.some(m => m._id === selectedMaterial)
            : true;
        
        // return dateFilter && endFilter && materialFilter;
        const officeCondition = officeFilter ? order.office : 
        poligraphFilter ? !order.office : true;

        return dateFilter && endFilter && materialFilter && officeCondition;
    });

    const totalSum = filteredOrders.reduce((sum, order) => {
        switch(stageName) {
        case 'Дизайн':
            return sum + (order.info.paymentForDesign || 0);
        case 'Печать':
            return sum + (order.info.paymentForPrinter || 0); // Changed from paymentForPrint
        case 'Постпечать':
            return sum + (order.info.paymentForPostprinter || 0); // Changed from paymentForPostprint
        case 'Установка':
            return sum + (order.info.paymentForInstallation || 0);
        default:
            return sum;
        }
    }, 0);
    const totalSumSize = stageName === 'Печать' 
    ? filteredOrders.reduce((sum, order) => sum + (Number(order.info.dimensions?.size) || 0), 0)
    : 0;
  return (
    <div>

        <h2 style={{'textAlign':'left'}}>Аткарылган заказдар / {user?.username} / {stageName}</h2>
        <div style={{ display: 'flex', justifyContent: 'left', gap: '5px', margin: '20px 0',height:'30px' }}>
            <div className="filter-item">
                <label>Баштоо күнү:</label>
            </div>
            <div className="filter-item">
            <input 
                type="date" 
                value={startDate} 
                onChange={(e) => setStartDate(e.target.value)} 
            />
            </div>
            <div className="filter-item">
                <label>Бүтүү күнү:</label>
            </div>
            <div className="filter-item">
            <input 
                type="date" 
                value={endDate} 
                onChange={(e) => setEndDate(e.target.value)} 
            />
            </div>
            <div className="filter-item">
                <label>
                    <input 
                        type="checkbox" 
                        checked={poligraphFilter} 
                        onChange={(e) => {
                            setPoligraphFilter(e.target.checked);
                            if (e.target.checked) setOfficeFilter(false);
                        }} 
                    />
                    Полиграф
                </label>
            </div>
            <div className="filter-item">
                <label>
                    <input 
                        type="checkbox" 
                        checked={officeFilter} 
                        onChange={(e) => {
                            setOfficeFilter(e.target.checked);
                            if (e.target.checked) setPoligraphFilter(false);
                        }} 
                    />
                    Офис
                </label>
            </div>
            <div className='filter-item'>
                Жалпы баасы: {filteredOrders.reduce((sum, order) => sum + (order.info.price || 0), 0)} сом
            </div>
            <div className='filter-item'>
                Жалпы сумма: {totalSum} сом
            </div>


            {stageName === 'Печать' && (
                <>
                <div className="filter-item">
                    <label>Материал:</label>
                </div>
                <div className="filter-item">
                    <select 
                        value={selectedMaterial} 
                        onChange={(e) => setSelectedMaterial(e.target.value)}
                    >
                        <option value="">Баары</option>
                        {materials.map(material => (
                            <option key={material._id} value={material._id}>
                                {material.title}
                            </option>
                        ))}
                    </select>
                </div>
                </>
            )}
            {stageName === 'Печать' && (
                <div className='filter-item'>
                    {totalSumSize.toFixed(3)} м²
                </div>
            )}
        </div>
        <div style={{'textAlign':'center'}}>
            <div style={{fontWeight:'bold','display':'flex','margin':'0 auto', 'paddingTop':'10px','paddingBottom':'10px','borderBottomStyle':'dashed','borderBottomWidth':'1px','borderBottomColor':'gray'}}>
                <div>#</div>
                <div style={{'width':'200px'}}>Аталышы - Клиент</div>
                <div style={{'width':'160px'}}>Төлөмдөр</div>
                <div style={{'width':'120px'}}>Ватсап</div>
                <div style={{'width':'170px'}}>Материалдар Кагаздар</div>
                <div style={{'width':'160px'}}>м2</div>
                <div style={{'width':'100px',}}>Акчасы</div>
                <div style={{'width':'100px','textAlign':'left'}}>Датасы</div>
            </div>
        </div>
      {filteredOrders.length > 0 ? (
        <div style={{'textAlign':'center'}}>
          {filteredOrders.map((order) => {
                const hasPerechisPayment = order.info.payments.some(payment => payment.method === 'перечис.');

                return (<div style={{'display':'flex','margin':'0 auto', 'paddingTop':'10px','paddingBottom':'10px','borderBottomStyle':'dashed','borderBottomWidth':'1px','borderBottomColor':'gray'}} key={order._id}>
                <div>{order.orderNo}</div>
                <div style={{'width':'200px'}}>{order.title} <br></br> {order.customer} </div>
                <div style={{'width':'160px'}}>
                    Баасы: {order.info.price} сом<br></br>
                    {order.info.payments
                        ? order.info.payments.map((payment, index) => (
                                <div key={payment.id || index}>
                                    {payment.amount} сом ({payment.method})
                                </div>
                            ))
                        : 'Төлөм жок'}
                    {!hasPerechisPayment && (
                        <>
                            <strong>Карызы:</strong>{' '}
                            {Number(
                                order.info.price -
                                    order.info.payments.reduce(
                                        (sum, payment) => sum + payment.amount,
                                        0
                                    )
                            ).toFixed(0)}{' '}
                            сом
                        </>
                    )}
              </div>
              <div style={{'width':'120px'}}>
                {order.info.whatsapp}
              </div>
              <div style={{'width':'170px'}}>
                {order.info?.materials.map((material,index) => (                                        
                        <div key={material.id || index}>
                            {material.title} 
                        </div>
                    ))
                }
                {order.info?.papers.map((item,index) => (                                        
                        <div key={item.paper.id || index}>
                            {item.paper.title} 
                        </div>
                    ))
                }
              </div>
              <div style={{'width':'160px'}}>
                {order.info.dimensions.height} см x {order.info.dimensions.width} см = {Number(order.info.dimensions.size).toFixed(3)} м2
              </div>
              <div style={{ width: '100px'}}>
                {stageName === 'Дизайн' && `${order.info.paymentForDesign} сом`}
                {stageName === 'Печать' && `${order.info.paymentForPrinter} сом`}
                {stageName === 'Постпечать' && `${order.info.paymentForPostprinter} сом`}
                {stageName === 'Установка' && `${order.info.paymentForInstallation} сом`}
              </div>
              <div style={{'width':'100px','textAlign':'left'}}>
                {formatDate(order.createdAt)} 
              </div>
            </div>
          )})}
        </div>
      ) : (
        <p style={{'textAlign':'center'}}>Аткарылган заказдар жок.</p>
      )}
    </div>
  );
};

export default Salary;