import './css/manager.css';

const Customer = () => {
return (
    <div class="table">    
        <div class="header">Заказчик</div>
    
        <div class="span-2 label">телефон клиента</div>
        <div class="span-3"></div>
        <div class="span-2 label">тип материала</div>
        <div class="span-3"></div>

        <div class="label">размеры</div>
        <div>м2</div>
        <div class="span-3 label">описания заказа</div>
        <div class="span-2"></div>
        <div></div>
        <div class="span-3"></div>

        <div class="label">Ставка</div>
        <div class="span-4"></div>
        <div class="label">Способ оплаты</div>
        <div>1. наличными</div>
        <div class="span-3">2 если перечисление</div>

        <div class="label">Сумма</div>
        <div class="span-4"></div>
        <div class="label">Дизайн</div>
        <div class="span-4"></div>

        <div class="red-text">Посчитать</div>
        <div class="yellow-box center">Печать Рейка и</div>
        <div class="yellow-box center">500</div>
        <div class="span-2 green-box center">Аткарылды</div>
    </div>);
};

export default Customer;