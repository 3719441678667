// src/components/list/SalaryList.jsx
import React from "react";
import { useNavigate } from 'react-router-dom';
import {
  useGetAllUsersQuery,
} from "../../redux/list/user/userApi";
import '../auth/auth.css';

const SalaryList = () => {
  const { data: users = [], refetch: refetchUsers } = useGetAllUsersQuery();
  const navigate = useNavigate();

  const rolesTitle = {
    Дизайнер: "Дизайнер",
    Печатник: "Печатник",
    Постпечатник: "Постпечатник",
    Установщик: "Установщик",
  };
  const rolesTask = {
    Дизайнер: "Дизайн",
    Печатник: "Печать",
    Постпечатник: "Постпечать",
    Установщик: "Установка",
  };
  return (
    <div className="auth-container">
      <h2>Колдонуучулар</h2>
      <ul>
        {users.map((user) => (
          <li key={user._id} className="form-group">
            <div className="button-group">
              <span>{user.username} ({user.email}) - Ролдор:{" "}</span>
              {/* <span>{user.roles.map((role) => role.name)}</span> */}
              <span>
                {user.roles.map(
                    (role) =>
                    rolesTitle[role.name] && (
                        <button key={role.name} 
                        onClick={() => navigate(`/salary?userId=${user._id}&stageName=${rolesTask[role.name]}`)}>
                            {rolesTitle[role.name]}
                        </button>
                    )
                )}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SalaryList;



