// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Styling the table */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 14px;
    /* font-family: Arial, sans-serif; */
    font-family: "Roboto", serif;
    text-align: left;
}

/* Styling table headers */
thead th {
    background-color: #f5f5f5;
    color: grey;
    padding: 12px 15px;
    border: 1px solid #dddddd;
}

/* Styling table rows */
tbody tr {
    border-bottom: 1px solid #dddddd;
}

/* Alternating row colors */
tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

/* Hover effect for rows */
tbody tr:hover {
    background-color: #f1f1f1;
}

/* Styling table cells */
td {
    padding: 12px 15px;
    border: 1px solid #dddddd;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow if text is too long */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
}

/* Text alignment for specific columns */
td:nth-child(1),
td:nth-child(8) {
    text-align: center; /* Center align Order Number and Date columns */
}

/* Add a little spacing around the table */
table {
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1000px; /* Limit table width */
}
`, "",{"version":3,"sources":["webpack://./src/components/css/table.css"],"names":[],"mappings":";AACA,sBAAsB;AACtB;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,eAAe;IACf,oCAAoC;IACpC,4BAA4B;IAC5B,gBAAgB;AACpB;;AAEA,0BAA0B;AAC1B;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA,uBAAuB;AACvB;IACI,gCAAgC;AACpC;;AAEA,2BAA2B;AAC3B;IACI,yBAAyB;AAC7B;;AAEA,0BAA0B;AAC1B;IACI,yBAAyB;AAC7B;;AAEA,wBAAwB;AACxB;IACI,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB,EAAE,+BAA+B;IACpD,gBAAgB,EAAE,sCAAsC;IACxD,uBAAuB,EAAE,4CAA4C;AACzE;;AAEA,wCAAwC;AACxC;;IAEI,kBAAkB,EAAE,+CAA+C;AACvE;;AAEA,0CAA0C;AAC1C;IACI,iBAAiB;IACjB,wCAAwC;IACxC,iBAAiB,EAAE,sBAAsB;AAC7C","sourcesContent":["\n/* Styling the table */\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 20px 0;\n    font-size: 14px;\n    /* font-family: Arial, sans-serif; */\n    font-family: \"Roboto\", serif;\n    text-align: left;\n}\n\n/* Styling table headers */\nthead th {\n    background-color: #f5f5f5;\n    color: grey;\n    padding: 12px 15px;\n    border: 1px solid #dddddd;\n}\n\n/* Styling table rows */\ntbody tr {\n    border-bottom: 1px solid #dddddd;\n}\n\n/* Alternating row colors */\ntbody tr:nth-of-type(even) {\n    background-color: #f3f3f3;\n}\n\n/* Hover effect for rows */\ntbody tr:hover {\n    background-color: #f1f1f1;\n}\n\n/* Styling table cells */\ntd {\n    padding: 12px 15px;\n    border: 1px solid #dddddd;\n    white-space: nowrap; /* Prevent text from wrapping */\n    overflow: hidden; /* Hide overflow if text is too long */\n    text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */\n}\n\n/* Text alignment for specific columns */\ntd:nth-child(1),\ntd:nth-child(8) {\n    text-align: center; /* Center align Order Number and Date columns */\n}\n\n/* Add a little spacing around the table */\ntable {\n    margin: 20px auto;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    max-width: 1000px; /* Limit table width */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
