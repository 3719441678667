import { configureStore } from '@reduxjs/toolkit';
import { orderApi } from './order/orderApi';
import { materialApi } from './material/materialApi';
import { customerApi } from './customer/customerApi';

export const store = configureStore({
  reducer: {
    [orderApi.reducerPath]: orderApi.reducer,
    [materialApi.reducerPath]: materialApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(orderApi.middleware,materialApi.middleware,customerApi.middleware),
});
