// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination{
    text-align: center;
    margin-bottom: 100px;
}
.activePage{
    background-color: gray;
    cursor: none;
    padding: 10px;
}
.page{
    cursor: pointer;
    padding: 10px;
    margin: 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/pagination.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;AACA;IACI,eAAe;IACf,aAAa;IACb,aAAa;AACjB","sourcesContent":[".pagination{\n    text-align: center;\n    margin-bottom: 100px;\n}\n.activePage{\n    background-color: gray;\n    cursor: none;\n    padding: 10px;\n}\n.page{\n    cursor: pointer;\n    padding: 10px;\n    margin: 0 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
