import {useState,useEffect} from 'react';
import './../../css/manager.css';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetOrderByIdQuery } from '../../../redux/order/orderApi';
import { useOrderHelpers } from '../../../hooks/useOrderHelpers';
import { useSelector } from 'react-redux';

const Designer = () => {
    const { _id } = useParams(); 
    const { user } = useSelector((state) => state.auth);
    
    const navigate = useNavigate();
    const { data: order, error, isLoading } = useGetOrderByIdQuery(_id);
    const [paymentForDesign,setPaymentForDesign] = useState(0);
    const [currentStage,setCurrentStage] = useState('');
    
    useEffect(() => {
        if (order?.info?.paymentForDesign) {
            setPaymentForDesign(order.info.paymentForDesign);
        }
    }, [order?.info?.paymentForDesign]);
    
    useEffect(() => {
        if (order?.currentStage) {
            const roles = {
                "Старт": "Дизайн",
                "Дизайн": "Печать",
                "Печать": "Постпечать",
                "Постпечать": "Установка",
                "Установка": "Финиш",
                "Финиш": "Старт"
            };
            console.log('order.currentStage: ', order.currentStage);
            console.log('roles[order.currentStage]: ', roles[order.currentStage]);
            setCurrentStage(roles[order.currentStage]);
        }
    }, [order?.currentStage]);    

    const { handleUpdateOrderProperty, handleUpdateOrderCurrentStageProperty } = useOrderHelpers();
    const hasDesignerRole = user?.roles?.includes("ROLE_ДИЗАЙНЕР");
    // Function to determine if the button should be enabled or disabled
    const isButtonEnabled = () => {
        const stage = order.stages.find(
        (stage) => stage.stageName === order.currentStage
        );
        return stage && stage.completedAt;
    };
    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            await handleUpdateOrderProperty(_id, 'info.paymentForDesign', parseFloat(paymentForDesign));
        }
    };
    const handleStarted = async (e)=>{
        console.log('user: ',user)
        console.log('temp currentStage: ',currentStage)
        console.log('order.currentStage: ',order.currentStage)
        await handleUpdateOrderCurrentStageProperty(order._id,'currentStage',currentStage,user.id,order)
        window.location.reload();
        // navigate('/orders', { replace: true });
    };
    const handleCompleted = async (e)=>{
        console.log('user: ',user)
        console.log('temp currentStage: ',currentStage)
        console.log('order.currentStage: ',order.currentStage)
        await handleUpdateOrderCurrentStageProperty(order._id,'currentStage',order.currentStage,user.id,order)
        // navigate('/orders', { replace: true });
    };
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;
    return (
        <div className="table">        
        <>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1 label">клиент</div>
            <div className="span-1 label value">{order.customer}</div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1 label">аталышы</div>
            <div className="span-1 label value">{order.title}</div>
            <div className="span-3"></div>
        
            <div className="span-1"></div>
            <div className="span-1 label">телефон</div>
            <div className="span-1 label value">
                <a
                    href={`https://wa.me/${order.info.whatsapp}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whatsapp-link"
                >
                    {order.info.whatsapp}
                </a>
            </div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1 label">материал</div>
            <div className="span-1 label value">
                <span style={{ display: 'flex', flexDirection: 'column' }}>
                    {order.info.materials.map((material) => (
                        <span key={material._id}>
                            {material.title}
                        </span>
                    ))}
                </span>
            </div>
            <div className="span-3"></div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1 label">размер</div>
            <div className="span-1"></div>
            <div className="span-1 label">м2</div>
            <div className="span-2 label">Маалымат</div>

            <div className="span-1"></div>
            <div className="span-1 label value">{order.info.dimensions.height} cm</div>
            <div className="span-1 label value">{order.info.dimensions.width} cm</div>
            <div className="span-1 label value">{order.info.dimensions.size}</div>
            <div className="span-2 label value">{order.details}</div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>

            <div className="span-1 label">Стадия</div>
            <div className="span-1 label value">{order.currentStage}</div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            </>

            {hasDesignerRole ? (
                <>
                    <div className="span-1 label">Дизайн</div>
                    <div className="span-1 label green-value">
                        <div className="payment-container">
                            <input name="paymentForDesign" 
                            value={paymentForDesign} 
                            onChange={(e)=>setPaymentForDesign(e.target.value)} 
                            onKeyPress={handleKeyPress} // Add key press handler
                            placeholder="500"></input>
                            <div>сом</div>
                        </div>
                    </div>
                    <div className="span-1"></div>
                    <div className="span-2 green-box">
                        <button onClick={handleStarted} 
                        className={`whatsapp-link ${isButtonEnabled() ? '' : 'disabled'}`}
                        >
                            Кабыл алдым
                        </button>
                    </div>
                    <div className="span-1"></div>
                </>
            ) : (
                <></>
            )}

            <div className="span-1 label">Постпечать</div>
            <div className="span-1 label value">{order.info.frames + order.info.leuvers + order.info.installation} сом</div>
            <div className="span-1"></div>
            <div className="span-2 green-box">
                <button onClick={handleCompleted} 
                className={`whatsapp-link ${isButtonEnabled() ? 'disabled' : ''}`}>
                    Аткарылды
                </button>
            </div>
            <div className="span-1"></div>

            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
            <div className="span-1"></div>
        </div>);
};

export default Designer;