// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
    display: flex;
    gap: 15px;
    align-items: flex-end;
    justify-content: flex-end;
}

.filter-item {
    display: flex;
    flex-direction: column; /* Align label above input */
}
.mt-20{
    margin-top: 20px;
}

.autocomplete-suggestions {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    width: 100%;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .autocomplete-suggestion {
    padding: 8px;
    cursor: pointer;
  }
  
  .autocomplete-suggestion:hover {
    background-color: #f5f5f5;
  }
  
  .filter-item input[type="text"] {
    padding-right: 25px; /* Space for clear button */
  }`, "",{"version":3,"sources":["webpack://./src/components/css/filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,qBAAqB;IACrB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB,EAAE,4BAA4B;AACxD;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,sBAAsB;IACtB,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB,EAAE,2BAA2B;EAClD","sourcesContent":[".filters {\n    display: flex;\n    gap: 15px;\n    align-items: flex-end;\n    justify-content: flex-end;\n}\n\n.filter-item {\n    display: flex;\n    flex-direction: column; /* Align label above input */\n}\n.mt-20{\n    margin-top: 20px;\n}\n\n.autocomplete-suggestions {\n    position: absolute;\n    background: white;\n    border: 1px solid #ddd;\n    width: 100%;\n    z-index: 1000;\n    max-height: 200px;\n    overflow-y: auto;\n  }\n  \n  .autocomplete-suggestion {\n    padding: 8px;\n    cursor: pointer;\n  }\n  \n  .autocomplete-suggestion:hover {\n    background-color: #f5f5f5;\n  }\n  \n  .filter-item input[type=\"text\"] {\n    padding-right: 25px; /* Space for clear button */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
