// src/components/list/UserList.jsx
import React, { useState, useEffect } from "react";
import {
  useGetAllUsersQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} from "../../redux/list/user/userApi";
import { useGetAllRolesQuery } from "../../redux/list/role/roleApi"; // Import role API hook
import '../auth/auth.css';

const UserList = () => {
  const { data: users = [], refetch: refetchUsers } = useGetAllUsersQuery();
  const { data: roles = [] } = useGetAllRolesQuery(); // Fetch roles for the dropdown
  const [createUser] = useCreateUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    roles: [],
  });
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    if (!editId) {
      setFormData({ username: "", email: "", password: "", roles: [] });
    }
  }, [editId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        await updateUser({ id: editId, ...formData });
        setEditId(null);
      } else {
        await createUser(formData);
      }
      setFormData({ username: "", email: "", password: "", roles: [] });
      refetchUsers();
    } catch (error) {
      console.error("Error creating/updating user:", error);
    }
  };

  const handleEdit = (user) => {
    setFormData({
      username: user.username,
      email: user.email,
      password: "", // Leave password blank for editing
      roles: user.roles.map((role) => role._id),
    });
    setEditId(user._id);
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      refetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const handleRoleChange = (roleId) => {
    setFormData((prevData) => ({
      ...prevData,
      roles: prevData.roles.includes(roleId)
        ? prevData.roles.filter((id) => id !== roleId)
        : [...prevData.roles, roleId],
    }));
  };

  return (
    <div className="auth-container">
      <h2>Колдонуучулар</h2>
      <ul>
        {users.map((user) => (
          <li key={user._id} className="form-group">
            <div className="button-group">
              <span>{user.username} ({user.email}) - Ролдор:{" "}
              {user.roles.map((role) => role.name).join(", ")}</span>
              <button onClick={() => handleEdit(user)}>Өзгөртүү</button>
              <button onClick={() => handleDelete(user._id)}>Өчүрүү</button>
            </div>
          </li>
        ))}
      </ul>

      <h2>{editId ? "Өзгөртүү" : "Кошуу"}</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Никнейм</label>
          <input
            className="form-input"
            type="text"
            placeholder="Никнейм"
            value={formData.username}
            onChange={(e) =>
              setFormData((prevData) => ({ ...prevData, username: e.target.value }))
            }
            required
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            className="form-input"
            type="email"
            placeholder="Email"
            value={formData.email}
            onChange={(e) =>
              setFormData((prevData) => ({ ...prevData, email: e.target.value }))
            }
            required
          />
        </div>
        <div className="form-group">
          <label>Пароль</label>
          <input
            className="form-input"
            type="password"
            placeholder="Password"
            value={formData.password}
            onChange={(e) =>
              setFormData((prevData) => ({ ...prevData, password: e.target.value }))
            }
            required={!editId} // Password required only for new users
          />
        </div>
        <div>
          <label>Ролдор:</label>
          <ul>
            {roles.map((role) => (
              <li key={role._id}>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.roles.includes(role._id)}
                    onChange={() => handleRoleChange(role._id)}
                  />
                  {role.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <button type="submit" className="submit-button">{editId ? "Өзгөртүү" : "Кошуу"}</button>
        {editId && <button onClick={() => setEditId(null)}>Баш тартуу</button>}
      </form>
    </div>
  );
};

export default UserList;



