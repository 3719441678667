import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const SpecificOrders = () => {
    // const { userId } = useSelector((state) => state.auth._id);
    const { user } = useSelector((state) => state.auth);
    // const userId = '674b5b6d61122a0c81927cbf';
  const [currentOrders, setCurrentOrders] = useState([]);
  const [finishedOrders, setFinishedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
//   const hasDesignerRole = user?.roles?.includes("ROLE_ДИЗАЙНЕР");
//   const hasPrinterRole = user?.roles?.includes("ROLE_ПЕЧАТНИК");
//   const hasPostprinterRole = user?.roles?.includes("ROLE_ПОСТПЕЧАТНИК");
//   const hasInstallatorRole = user?.roles?.includes("ROLE_УСТАНОВЩИК");

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch Current Orders (with createdAt)
        const currentOrdersResponse = await fetch(
          `https://api.ilim-ordo.kg/api/orders/assigned-user/${user.id}/without-createdAt`
        );
        if (!currentOrdersResponse.ok) {
            // Check for 404 status, but it's *not* an error in our case - it is business as usual.
            if (currentOrdersResponse.status === 404) {
                // Backend said there were no orders, so let's handle it.
                setCurrentOrders([]); // Set to an empty array, to display "No orders" message
            } else {
                throw new Error(`HTTP error! status: ${currentOrdersResponse.status}`);
            }
        }else{
            const currentOrdersData = await currentOrdersResponse.json();
            setCurrentOrders(currentOrdersData);
        }
        const finishedOrdersResponse = await fetch(
            `https://api.ilim-ordo.kg/api/orders/assigned-user/${user.id}/with-createdAt`
          );
        // Fetch Finished Orders (without createdAt)
        if (!finishedOrdersResponse.ok) {
            // Check for 404 status, but it's *not* an error in our case - it is business as usual.
            if (finishedOrdersResponse.status === 404) {
                // Backend said there were no orders, so let's handle it.
                setFinishedOrders([]); // Set to an empty array, to display "No orders" message
            } else {
                throw new Error(`HTTP error! status: ${finishedOrdersResponse.status}`); // Real error
            }
        } else {
            const finishedOrdersData = await finishedOrdersResponse.json();
            setFinishedOrders(finishedOrdersData);
        }

      } catch (err) {
        console.error("Error fetching orders:", err);
        setError(err.message || "An error occurred while fetching orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [user]); // Dependency array: re-fetch orders when userId changes

  if (loading) {
    return <div>Loading orders...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
    };
  return (
    <div>
      <h2>Учурдагы заказдар</h2>
      {currentOrders.length > 0 ? (
        <div style={{'textAlign':'center'}}>
          {currentOrders.map((order) => {
                const hasPerechisPayment = order.info.payments.some(payment => payment.method === 'перечис.');
                return (<div style={{'display':'flex','margin':'0 auto', 'paddingTop':'10px','paddingBottom':'10px','borderBottomStyle':'dashed','borderBottomWidth':'1px','borderBottomColor':'gray'}} key={order._id}>
                    <div style={{'width':'100px'}}>{order.orderNo}</div>
                    <div style={{'width':'300px'}}>{order.title} - {order.customer} </div>
                    <div style={{'width':'200px'}}>
                        Баасы: {order.info.price} сом<br></br>
                        {order.info.payments
                            ? order.info.payments.map((payment, index) => (
                                    <div key={payment.id || index}>
                                        {payment.amount} сом ({payment.method})
                                    </div>
                                ))
                            : 'Төлөм жок'}
                        {!hasPerechisPayment && (
                            <>
                                <strong>Карызы:</strong>{' '}
                                <span style={{'color':'red'}}>
                                {Number(
                                    order.info.price -
                                        order.info.payments.reduce(
                                            (sum, payment) => sum + payment.amount,
                                            0
                                        )
                                ).toFixed(0)}{' '}
                                сом</span>
                            </>
                        )}
                  </div>
                  <div style={{'width':'200px'}}>
                    {order.info.whatsapp}
                  </div>
                  <div style={{'width':'200px'}}>
                    {order.info?.materials.map((material,index) => (                                        
                            <div key={material.id || index}>
                                {material.title} 
                            </div>
                        ))
                    }
                    {order.info?.papers.map((item,index) => (                                        
                            <div key={item.paper.id || index}>
                                {item.paper.title} 
                            </div>
                        ))
                    }
                  </div>
                  <div style={{'width':'200px'}}>
                    {order.info.dimensions.height} см x {order.info.dimensions.width} см = {Number(order.info.dimensions.size).toFixed(3)} м2
                  </div>
                  <div style={{'width':'200px','textAlign':'right'}}>
                    {order.info.paymentForDesign} сом
                  </div>
                  <div style={{'width':'100px'}}>
                    {formatDate(order.createdAt)} 
                  </div>
            </div>
          )})}
        </div>
      ) : (
        <p style={{'textAlign':'center'}}>Иш үстүндөгү заказдар жок.</p>
      )}

      <h2>Аткарылган заказдар</h2>
      {finishedOrders.length > 0 ? (
        <div style={{'textAlign':'center'}}>
          {finishedOrders.map((order) => {
                const hasPerechisPayment = order.info.payments.some(payment => payment.method === 'перечис.');

                return (<div style={{'display':'flex','margin':'0 auto', 'paddingTop':'10px','paddingBottom':'10px','borderBottomStyle':'dashed','borderBottomWidth':'1px','borderBottomColor':'gray'}} key={order._id}>
                <div style={{'width':'100px'}}>{order.orderNo}</div>
                <div style={{'width':'300px'}}>{order.title} - {order.customer} </div>
                <div style={{'width':'200px'}}>
                    Баасы: {order.info.price} сом<br></br>
                    {order.info.payments
                        ? order.info.payments.map((payment, index) => (
                                <div key={payment.id || index}>
                                    {payment.amount} сом ({payment.method})
                                </div>
                            ))
                        : 'Төлөм жок'}
                    {!hasPerechisPayment && (
                        <>
                            <strong>Карызы:</strong>{' '}
                            <span style={{'color':'red'}}>
                            {Number(
                                order.info.price -
                                    order.info.payments.reduce(
                                        (sum, payment) => sum + payment.amount,
                                        0
                                    )
                            ).toFixed(0)}{' '}
                            сом
                            </span>
                        </>
                    )}
              </div>
              <div style={{'width':'200px'}}>
                {order.info.whatsapp}
              </div>
              <div style={{'width':'200px'}}>
                {order.info?.materials.map((material,index) => (                                        
                        <div key={material.id || index}>
                            {material.title} 
                        </div>
                    ))
                }
                {order.info?.papers.map((item,index) => (                                        
                        <div key={item.paper.id || index}>
                            {item.paper.title} 
                        </div>
                    ))
                }
              </div>
              <div style={{'width':'200px'}}>
                {order.info.dimensions.height} см x {order.info.dimensions.width} см = {Number(order.info.dimensions.size).toFixed(3)} м2
              </div>
              <div style={{'width':'200px'}}>
                {order.info.paymentForDesign} сом
              </div>
              <div style={{'width':'100px'}}>
                {formatDate(order.createdAt)} 
              </div>
            </div>
          )})}
        </div>
      ) : (
        <p style={{'textAlign':'center'}}>Аткарылган заказдар жок.</p>
      )}
    </div>
  );
};

export default SpecificOrders;