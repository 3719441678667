import React, { useEffect, useState } from 'react';
import { 
  useGetAllOrdersByStatusQuery, 
  useAddMaterialToOrderMutation,
  useAddPaymentToOrderMutation, 
  useDeletePaymentFromOrderMutation,
  useDeleteMaterialFromOrderMutation,
  useUpdateOrderMutation
} from '../redux/order/orderApi';
import { useGetAllMaterialsQuery } from '../redux/material/materialApi';
import { useGetAllCustomersQuery } from '../redux/customer/customerApi';
import './css/OrderList.css';

const OrderList = () => {
    const { data: orders, error, isLoading } = useGetAllOrdersByStatusQuery();
    const { data: materials = [] } = useGetAllMaterialsQuery();
    const { data: customers = [] } = useGetAllCustomersQuery();
    const [newPayment, setNewPayment] = useState({ amount: '', method: 'cash' });
    const [addMaterialToOrder] = useAddMaterialToOrderMutation();
    const [addPaymentToOrder] = useAddPaymentToOrderMutation(); 
    const [deletePaymentFromOrder] = useDeletePaymentFromOrderMutation();
    const [deleteMaterialFromOrder] = useDeleteMaterialFromOrderMutation();
    const [updateOrder] = useUpdateOrderMutation();
    const [selectedMaterial, setSelectedMaterial] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');

    const [isLoadinggg, setIsLoadinggg] = useState(false);
    const [whatsapp, setWhatsapp] = useState(0);
    const [paymentForDesign,setPaymentForDesign] = useState(0);
    const [leuvers,setLeuvers] = useState(0);
    const [frames,setFrames] = useState(0);
    const [price,setPrice] = useState(0);
    const [orderDetails,setOrderDetails] = useState('');
    const [priority, setPriority] = useState('');
    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);
    const [rate, setRate] = useState(0);
    const [installation, setInstallation] = useState(0);
    const [customer, setCustomer] = useState('');
    const [users, setUsers] = useState([]);
    useEffect(() => {
      const fetchUsers = async () => {
        try {
          const response = await fetch('https://api.ilim-ordo.kg/api/user');
          if (response.ok) {
            const usersData = await response.json();
            setUsers(usersData);
          } else {
            console.error('Failed to fetch users');
          }
        } catch (err) {
          console.error('Error fetching users:', err);
        }
      };
  
      fetchUsers();
      if(orders){
        setPriority(orders[0].info.priority);
        setHeight(orders[0].info.size.height);
        setWidth(orders[0].info.size.width);
      }
    }, [orders]);

    const [newStage, setNewStage] = useState({
      stageName: '',
      assignedUsers: [],
      startedAt: '',
      completedAt: '',
    });
    if (isLoading) return <p>Жүктөлүп жатат...</p>;
    if (error) return <p>Ката чыкты</p>;

    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setNewPayment({ ...newPayment, [name]: value });
    };
    const handleAddPayment = async (orderId) => {
      setIsLoadinggg(true);
      try {
          await addPaymentToOrder({ orderId, ...newPayment });
          setNewPayment({ amount: '', method: 'cash' }); 
          window.location.reload();
      } catch (error) {
          console.error("Error adding payment:", error);
      }
    };

    const handlePaymentDelete = async (orderId, paymentId) => {
      setIsLoadinggg(true);
        try {
            await deletePaymentFromOrder({ orderId, paymentId });
            window.location.reload();
        } catch (error) {
            console.error("Error deleting payment:", error);
        }
    };

    const handleMaterialChange = (e) => {
      setSelectedMaterial(e.target.value);
    };

    const handleAddMaterial = async (orderId) => {
        setIsLoadinggg(true);
        if (selectedMaterial) {
            await addMaterialToOrder({ orderId, materialId: selectedMaterial });
            setSelectedMaterial(''); // Reset the selection
            window.location.reload();
        }
    };

    const handleMaterialDelete = async (orderId, materialId) => {
      setIsLoadinggg(true);
      try {
          await deleteMaterialFromOrder({ orderId, materialId });
          window.location.reload();
      } catch (error) {
          console.error("Error deleting Material:", error);
      }
    };

    // const handleCustomerChange = (e) => {
    //   setSelectedCustomer(e.target.value);
    // };

    const handleAddCustomer =  (orderId) => {
        setIsLoadinggg(true);
        if (selectedCustomer) {
            handleUpdateOrderProperty(orderId,'customer',selectedCustomer)
            setSelectedCustomer(''); // Reset the selection
        }else{
          handleUpdateOrderProperty(orderId,'customer',customer)
          setCustomer('');
        }
        // window.location.reload();
    };

    // Handler to update a single property
    const handleUpdateOrderProperty = async (orderId, property, value) => {
      setIsLoadinggg(true);
      try {
        await updateOrder({ id: orderId, [property]: value });
        // window.location.reload();
      } catch (error) {
        console.error("Error deleting Material:", error);
      }
    };

    const handleStageChange = (e) => {
      const { name, value } = e.target;
      setNewStage({ ...newStage, [name]: value });
    };
  
    const handleAssignedUsersChange = (e) => {
      const selectedOptions = Array.from(e.target.selectedOptions);
      const selectedUserIds = selectedOptions.map((option) => option.value);
      setNewStage({ ...newStage, assignedUsers: selectedUserIds });
    };
  
    const handleAddStage = async (orderId) => {
      setIsLoadinggg(true);
      try {
        await updateOrder({
          id: orderId,
          stages: [{ ...newStage }],
        });
        window.location.reload();
      } catch (error) {
        console.error('Error adding stage:', error);
      } finally {
        setIsLoadinggg(false);
      }
    };

    return (
        <div>
          <h2>Заказдар</h2>
          {orders.map((order) => (
            <div key={order._id} className="order-container">
              <div className="order-header">
                <strong>#</strong> {order.orderNumber}
                <strong>Азыркы этап:</strong> {order.currentStage}
                <strong >Кабыл алган:</strong> {order.createdBy.username}
                <strong>Датасы:</strong> {new Date(order.createdAt).toLocaleDateString()}
              </div>
              <div className="order-details">
                <div className="section-container">
                  <h2>Клиент</h2> 
                  <div className="order-info">{order.customer}</div>
                  {/* Dropdown to Add Customer */}
                  <div className="vertical-end">
                      <h4>Клиент кошуу</h4>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <select 
                          value={selectedCustomer} 
                          onChange={(e)=>setSelectedCustomer(e.target.value)}                          
                        >
                            <option value="">Клиент тандаңыз</option>
                            {customers.map(customer => (
                                <option key={customer._id} value={customer.title}>
                                    {customer.title}
                                </option>
                            ))}
                        </select>
                        <input
                            type="text"
                            name="customer"
                            placeholder="Заказчик"
                            value={customer}
                            onChange={(e)=>setCustomer(e.target.value)}
                            required                          
                        />
                        <button onClick={() => handleAddCustomer(order._id)} disabled={isLoadinggg}>Кош</button>
                      </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Төлөмдөр</h2> 
                  <ul className="order-info">
                      {order.info.payments.map(payment => (
                          <li key={payment._id} className="list-item">
                              <span>{`${payment.amount} сом`}</span>
                              <span>{`(${payment.method === 'cash' ? 'акчалай' : 'перечис.'})`}</span>
                              <button                                    
                                  onClick={() => handlePaymentDelete(order._id, payment._id)}
                                  disabled={isLoadinggg}
                              >
                                  Өчүрүү
                              </button>
                          </li>
                      ))}
                  </ul>
                  {/* Form to Add New Payment */}
                  <div className="vertical-end">
                      <h4>Төлөм кошуу</h4>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        <input
                            type="number"
                            name="amount"
                            placeholder="Төлөм суммасы"
                            value={newPayment.amount}
                            onChange={handlePaymentChange}
                            required                          
                        />
                        <select
                            name="method"
                            value={newPayment.method}
                            onChange={handlePaymentChange}
                            required                          
                        >
                            <option value="cash">акчалай</option>
                            <option value="emoney">перечис.</option>
                        </select>
                        <button onClick={() => handleAddPayment(order._id)} disabled={isLoadinggg}>Кош</button>
                      </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Материалдар</h2> 
                  <ul>
                      {order.info.materials.map(material => (
                          <li key={material._id} className="list-item">
                              <span>{material.title}</span>
                              <button 
                                  className="list-item"
                                  onClick={() => handleMaterialDelete(order._id, material._id)}
                                  disabled={isLoadinggg}
                              >
                                  Өчүрүү
                              </button>
                          </li>
                      ))}
                  </ul>
                  {/* Dropdown to Add Material */}
                  <div className="vertical-end">
                      <h4>Материал кошуу</h4>
                      <select 
                        value={selectedMaterial} 
                        onChange={handleMaterialChange}  
                        className="select-margin"
                      >
                          <option value="">Материал тандаңыз</option>
                          {materials.map(material => (
                              <option key={material._id} value={material._id}>
                                  {material.title}
                              </option>
                          ))}
                      </select>
                      <button onClick={() => handleAddMaterial(order._id)} disabled={isLoadinggg}>Кош</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Whatsapp</h2> 
                  <div className="order-info">{order.info.whatsapp}</div>
                  <div className="vertical-end">
                      <h4>Номерди өзгөртүү</h4>
                      <input
                          type="number"
                          name="whatsapp"
                          placeholder="Whatsapp"
                          value={whatsapp}
                          onChange={(e)=>setWhatsapp(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.whatsapp',whatsapp)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Дизайнерге</h2> 
                  <div className="order-info">{order.info.paymentForDesign} сом</div>
                  <div className="vertical-end">
                      <h4>Дизайнердин акысын өзгөртүү</h4>
                      <input
                          type="number"
                          name="paymentForDesign"
                          placeholder="Дизайнердин акысы"
                          value={paymentForDesign}
                          onChange={(e)=>setPaymentForDesign(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.paymentForDesign',paymentForDesign)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Люверси</h2> 
                  <div className="order-info">{order.info.leuvers} сом</div>
                  <div className="vertical-end">
                      <h4>Люверсинин баасын өзгөртүү</h4>
                      <input
                          type="number"
                          name="leuvers"
                          placeholder="leuvers"
                          value={leuvers}
                          onChange={(e)=>setLeuvers(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.leuvers',leuvers)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Рейка</h2> 
                  <div className="order-info">{order.info.frames} сом</div>
                  <div className="vertical-end">
                      <h4>Рейканын баасын өзгөртүү</h4>
                      <input
                          type="number"
                          name="frames"
                          placeholder="frames"
                          value={frames}
                          onChange={(e)=>setFrames(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.frames',frames)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Баасы</h2> 
                  <div className="order-info">{order.info.price} сом</div>
                  <div className="vertical-end">
                      <h4>Заказдын баасын өзгөртүү</h4>
                      <input
                          type="number"
                          name="price"
                          placeholder="price"
                          value={price}
                          onChange={(e)=>setPrice(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'info.price',price)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Маалымат</h2> 
                  <div className="order-info">{order.orderDetails}</div>
                  <div className="vertical-end">
                      <h4>Заказ тууралуу маалыматты өзгөртүү</h4>
                      <input
                          type="text"
                          name="orderDetails"
                          placeholder="Заказ тууралуу маалымат"
                          value={orderDetails}
                          onChange={(e)=>setOrderDetails(e.target.value)}
                          required                          
                      />
                      <button onClick={() => handleUpdateOrderProperty(order._id,'orderDetails',orderDetails)} disabled={isLoadinggg}>Өзгөрт</button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Приоритет</h2>
                  <div className="order-info">{order.info.priority}</div>
                  <div className="vertical-end">
                    <h4>Приоритетти өзгөртүү</h4>
                    <select
                      name="priority"
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                      required
                    >
                      <option value="Обычный">Обычный</option>
                      <option value="Срочный">Срочный</option>
                    </select>
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.priority', priority)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Өлчөмү</h2>
                  <div className="order-info">
                    Бийиктиги: {order.info.size.height * 0.01} м, Туурасы: {order.info.size.width * 0.01} м, 
                     &nbsp;
                    {(order.info.size.height * order.info.size.width) * 0.0001} м2
                  </div>
                  <div className="vertical-end">
                    <h4>Өлчөмүн өзгөртүү</h4>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                      <div>
                        <label htmlFor="height">Бийиктиги (cм):</label>
                        <input
                          type="number"
                          id="height"
                          name="height"
                          placeholder="Бийиктиги (cм)"
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                          step="1"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="width">Туурасы (cм):</label>
                        <input
                          type="number"
                          id="width"
                          name="width"
                          placeholder="Туурасы (cм)"
                          value={width}
                          onChange={(e) => setWidth(e.target.value)}
                          step="1"
                          required
                        />
                      </div>
                      <button 
                        onClick={() =>
                          handleUpdateOrderProperty(order._id, 'info.size', { height, width })
                        } 
                        disabled={isLoadinggg} 
                        style={{ alignSelf: 'flex-end' }}>
                        Өзгөрт
                      </button>
                    </div>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Ставка</h2>
                  <div className="order-info">{order.info.rate} сом</div>
                  <div className="vertical-end">
                    <h4>Ставканы өзгөртүү</h4>
                    <input
                      type="number"
                      name="rate"
                      placeholder="Ставка"
                      value={rate}
                      onChange={(e) => setRate(e.target.value)}
                      required
                    />
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.rate', rate)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                <div className="section-container">
                  <h2>Установка</h2>
                  <div className="order-info">{order.info.installation} сом</div>
                  <div className="vertical-end">
                    <h4>Установканын баасын өзгөртүү</h4>
                    <input
                      type="number"
                      name="installation"
                      placeholder="Установка"
                      value={installation}
                      onChange={(e) => setInstallation(e.target.value)}
                      required
                    />
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.installation', installation)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div>
                {/* <div className="section-container">
                  <h2>Печатник</h2>
                  <div className="order-info">{order.info.printer} сом</div>
                  <div className="vertical-end">
                    <h4>Печатниктин акысын өзгөртүү</h4>
                    <input
                      type="number"
                      name="printer"
                      placeholder="Печатник"
                      value={printer}
                      onChange={(e) => setPrinter(e.target.value)}
                      required
                    />
                    <button
                      onClick={() => handleUpdateOrderProperty(order._id, 'info.printer', printer)}
                      disabled={isLoadinggg}
                    >
                      Өзгөрт
                    </button>
                  </div>
                </div> */}
              </div>
    
              <h3>Этаптары</h3>
              {order.stages.map((stage, index) => (
                <div key={index} className="stages-container">
                  <div className="stage-detail">
                    <strong>Этап:</strong> {stage.stageName}
                  </div>
                  <div className="stage-detail">
                    <strong>Аткаруучулар:</strong> {stage.assignedUsers.map(user => user.username).join(', ')}
                  </div>
                  <div className="stage-detail">
                    <strong>Башталды:</strong> {stage.startedAt ? new Date(stage.startedAt).toLocaleDateString() : 'Баштала элек'}
                  </div>
                  <div className="stage-detail">
                    <strong>Аяктады:</strong> {stage.completedAt ? new Date(stage.completedAt).toLocaleDateString() : 'Бүтө элек'}
                  </div>
                  {/* <button onClick={() => handleUpdateOrderProperty(order._id,'orderDetails',orderDetails)} disabled={isLoadinggg}>Өзгөрт</button>
                  <button onClick={() => handleDeleteStageIdInOrder(order._id,stage._id)} disabled={isLoadinggg}>Өчүр</button> */}
                </div>
              ))}
                          {/* Form to add a new stage */}





              <div className="stage-form">
                <h4>Жаңы этап кошуу</h4>
                <div className="form-row">
                  <div className="form-group">
                    <label>Этаптын аталышы:</label>
                    <select name="stageName" value={newStage.stageName} onChange={handleStageChange}>
                      <option value="">Этап тандаңыз</option>
                      <option value="Дизайн">Дизайн</option>
                      <option value="Печать">Печать</option>
                      <option value="Подготовка">Подготовка</option>
                      <option value="Установка">Установка</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Аткаруучулар:</label>
                    <select
                      name="assignedUsers"
                      multiple
                      value={newStage.assignedUsers}
                      onChange={handleAssignedUsersChange}
                    >
                      {/* Replace this with a mapped list of users */}
                      {users.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>Башталды:</label>
                    <input
                      type="date"
                      name="startedAt"
                      value={newStage.startedAt}
                      onChange={handleStageChange}
                    />
                  </div>

                  <div className="form-group">
                    <label>Аяктады:</label>
                    <input
                      type="date"
                      name="completedAt"
                      value={newStage.completedAt}
                      onChange={handleStageChange}
                    />
                  </div>

                  <div className="form-group">
                    <button
                      onClick={() => handleAddStage(order._id)}
                      disabled={isLoadinggg || !newStage.stageName}
                      className="add-stage-button"
                    >
                      {isLoadinggg ? 'Кошуп жатат...' : 'Этап кошуу'}
                    </button>
                  </div>
                </div>
              </div>





            </div>
          ))}
        </div>
      );
};

export default OrderList;




/*
<div className="stage-form">
<h4>Жаңы этап кошуу</h4>
<label>Этаптын аталышы:
  <select name="stageName" value={newStage.stageName} onChange={handleStageChange}>
    <option value="">Этап тандаңыз</option>
    <option value="Дизайн">Дизайн</option>
    <option value="Печать">Печать</option>
    <option value="Подготовка">Подготовка</option>
    <option value="Установка">Установка</option>
  </select>
</label>

<label>Аткаруучулар:
  <select
    name="assignedUsers"
    multiple
    value={newStage.assignedUsers}
    onChange={handleAssignedUsersChange}
  >
  
    {users.map((user) => (
      <option key={user._id} value={user._id}>
        {user.username}
      </option>
    ))}
  </select>
</label>

<label>Башталды:
  <input
    type="date"
    name="startedAt"
    value={newStage.startedAt}
    onChange={handleStageChange}
  />
</label>

<label>Аяктады:
  <input
    type="date"
    name="completedAt"
    value={newStage.completedAt}
    onChange={handleStageChange}
  />
</label>

<button
  onClick={() => handleAddStage(order._id)}
  disabled={isLoadinggg || !newStage.stageName}
>
  {isLoadinggg ? 'Кошуп жатат...' : 'Этап кошуу'}
</button>
</div> */