// src/components/CustomerList.js
import React, { useState } from 'react';
import {
  useGetAllCustomersQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
} from '../redux/customer/customerApi';

const CustomerList = () => {
  const { data: customers = [], refetch } = useGetAllCustomersQuery();
  const [createCustomer] = useCreateCustomerMutation();
  const [updateCustomer] = useUpdateCustomerMutation();
  const [deleteCustomer] = useDeleteCustomerMutation();

  const [title, setTitle] = useState('');
  const [editId, setEditId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editId) {
      await updateCustomer({ id: editId, title });
      setEditId(null);
    } else {
      await createCustomer(title);
    }
    setTitle('');
    refetch(); // Refresh the list after any change
  };

  const handleEdit = (customer) => {
    setTitle(customer.title);
    setEditId(customer._id);
  };

  const handleDelete = async (id) => {
    await deleteCustomer(id);
    refetch(); // Refresh the list after deletion
  };

  return (
    <div>
      <h2>Клиенттер</h2>
      <ul>
        {customers.map((customer) => (
          <li key={customer._id}>
            {customer.title}
            <button onClick={() => handleEdit(customer)}>Өзгөртүү</button>
            <button onClick={() => handleDelete(customer._id)}>Өчүрүү</button>
          </li>
        ))}
      </ul>

      <h2>{editId ? 'Клиентти өзгөрт' : 'Клиентти кош'}</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Клиент"         required
        />
        <button type="submit">{editId ? 'Өзгөртүү' : 'Кошуу'}</button>
      </form>
    </div>
  );
};

export default CustomerList;
