// SignUp.js
import React, { useState } from 'react';
import { useSignupMutation } from '../../redux/auth/authApi';
import { useDispatch } from 'react-redux';
import { signupSuccess } from '../../redux/auth/authSlice';
import { useNavigate, Link } from 'react-router-dom';
import './auth.css';

const SignUp = () => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [signup, { isLoading }] = useSignupMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize the navigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newUser = { email, username, password };
      const userData = await signup(newUser).unwrap();
      console.log('userData in signup', userData)
      dispatch(signupSuccess(userData)); // Dispatch the signupSuccess action after signup
      navigate('/signin');
    } catch (err) {
      console.error('Failed to signup: ', err);
    }
  };

  return (
      <>
        <div className="auth-container">
          <h2>Sign Up</h2>
          <form className="auth-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-input"
                placeholder="Enter your email"
              />
            </div>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-input"
                placeholder="Choose a username"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-input"
                placeholder="Create a password"
              />
            </div>
            <button type="submit" className="submit-button" disabled={isLoading}>
              {isLoading ? 'Signing up...' : 'Sign Up'}
            </button>
          </form>
          <p className="signin-link">
            Already have an account? <Link to="/signin">Sign In</Link>
          </p>
        </div>
      </>
  );
};

export default SignUp;